// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./Hero_Academia_MateCuMatei.jpg";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hero-title-container {\n    width: 100%;\n    height: 300px;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + "); \n    background-size: cover;\n    background-position: center;\n    text-align: center;\n    padding-top: 100px;\n  }\n  \n  .hero-title-container h1, .hero-title-container h4 {\n    color: #fff;\n  }", "",{"version":3,"sources":["webpack://src/components/layout/Hero/Hero.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,yDAAsD;IACtD,sBAAsB;IACtB,2BAA2B;IAC3B,kBAAkB;IAClB,kBAAkB;EACpB;;EAEA;IACE,WAAW;EACb","sourcesContent":[".hero-title-container {\n    width: 100%;\n    height: 300px;\n    background-image: url(\"Hero_Academia_MateCuMatei.jpg\"); \n    background-size: cover;\n    background-position: center;\n    text-align: center;\n    padding-top: 100px;\n  }\n  \n  .hero-title-container h1, .hero-title-container h4 {\n    color: #fff;\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
