// import React from "react"
import './DisplayJsonToOrderedList.css'

// DisplayJsonToOrderedList is used to list the points in components like AboutUs, Legal
const DisplayJsonToOrderedList = ({sections, sectionTitleSize}) => {

    function isFirstCharacterNumber(str) {
        // Convert the first character to a number and check if it's NaN
        return !isNaN(parseInt(str.charAt(0)));
    }

    function isFirstCharacterLetterFollowedByDot(str) {
        return /^[A-Za-z]\./.test(str);
    }

    function setLeftIndentation(str){
        if(isFirstCharacterNumber(str)){ return '1em'}
        else if( isFirstCharacterLetterFollowedByDot(str) ){ return '2em'}
        else {return '0'}
    }

    return(

                <div className="content-container">
                    {sections.map((section, index) => (
                    <div key={index}>
                        <h2 style={{fontSize: sectionTitleSize}}>{section.title}</h2>
                        <ul>
                        {section.points.map((point, idx) => (
                            <li key={idx} style = { {paddingLeft: setLeftIndentation(point)}}>{point}</li>
                        ))}
                        </ul>
                    </div>
                    ))}
                </div>
                   

    )
}

export default DisplayJsonToOrderedList