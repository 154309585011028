import {Component} from 'react'
import { Form, Button, Container } from 'react-bootstrap'
import CoursesService from '../../../services/courses.services'
import CouponService from './../../../services/coupons.services'


class CouponNewForm extends Component{
    constructor(){
        super()
        this.state = {
            author: null,
            authoredCourses:[],
            courses:[],
            code: null,
            percentageDiscount: null,
            startDate: null,
            endDate: null
        }
        this.coursesService = new CoursesService()
        this.couponsService = new CouponService()
    }

componentDidMount(){    
    const percentageDiscount = 30
    const startDate = new Date()
    const endDate = new Date(startDate)
    endDate.setMonth( startDate.getMonth() + 1 )

    const formattedStartDate = startDate.toISOString().slice(0, 16)
    const formattedEndDate = endDate.toISOString().slice(0, 16)
    this.setState({
        author: this.props.loggedUser._id,
        authoredCourses: this.props.loggedUser.authoredCourses,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        percentageDiscount
    })
}
    

handleInputChange = e => {
    const { name, value } = e.target
    this.setState({
        [name]: value
    })
}

handleCoursesChange = (e) => {
    const selectedOptions = e.target.selectedOptions;
    const courses = Array.from(selectedOptions).map((option) => option.value);
    console.log( `The selected courses: ${courses}`)
    this.setState({
        courses: courses
    })
};

handleFormSubmit = e => {
    e.preventDefault()
    // console.log(courseInput)

    this.couponsService
        .saveCoupon(this.state)
        .then(() => {
            this.setState({
                author: null,
                authoredCourses:[],
                courses:[],
                code: null,
                percentageDiscount: null,
                startDate: null,
                endDate: null
            })
            this.props.handleAlert(['Cuponul a fost creat.'], 10000, 'info', true)
            this.props.history.push('/courses')
            // history.push('/')
        })
        .catch( err => {   
            if(err.response){
                this.props.handleAlert(err.response?.data.message ? 
                    err.response?.data.message : [err.response?.statusText], 10000, 'warning', true)
            }   else{
                this.props.handleAlert(['The server is down'], 10000, 'danger', true)
            }  
            })                
}

calculateSelectHeight = () => {
    const optionHeight = 1.5; // Adjust this value based on your styling
    const numOptions = this.state?.authoredCourses ? this.state.authoredCourses.length : 1;
    return `${numOptions * optionHeight}em`;
  };

render(){

    return(
        this.state?.authoredCourses 
        ?
        <Container style={{paddingTop: '1em'}}>

            <Form onSubmit={this.handleFormSubmit}>

                <Form.Group controlId="Courses">
                    <Form.Label>Cursuri ( apasă Ctrl pentru selecție multiplă )</Form.Label>
                    <Form.Control as="select" multiple={true} defaultValue={[]}
                        onChange={this.handleCoursesChange} name="courses" custom="true"
                        style={{ height: this.calculateSelectHeight() }}
                    >
                        {/* <option value="">-- Select the course --</option> */}
                        {Object.values(this.state?.authoredCourses).map((course) => (
                            <option key={course._id} value={course._id} 
                            >{course.name}</option>
                        ))}
                    </Form.Control>
                </Form.Group>

                <Form.Group controlId="code">
                    <Form.Label>Cod</Form.Label>
                    <Form.Control type="text" value={this.state.code} onChange={this.handleInputChange} name="code" />
                </Form.Group>

                <Form.Group controlId="percentageDiscount">
                    <Form.Label>Procent</Form.Label>
                    <Form.Control as="select" value={this.state.percentageDiscount} onChange={this.handleInputChange} 
                        name="percentageDiscount">
                        {/* {[...Array(8).keys()].map( index =>  */}
                        {[0,1, 2, 3, 4, 5, 6, 7].map( index => 
                            <option key={index} value = {30 + index * 10}>
                                {30 + index * 10}%
                            </option>
                        )}
                    </Form.Control>
                </Form.Group>

<Form.Group controlId="startDate">
<Form.Label>Start date</Form.Label>
<Form.Control type="datetime-local" value={this.state.startDate} onChange={this.handleInputChange} name="startDate" />
</Form.Group>

<Form.Group controlId="endDate">
<Form.Label>End date</Form.Label>
<Form.Control type="datetime-local" value={this.state.endDate} onChange={this.handleInputChange} name="endDate" />
</Form.Group>



                {/* <Form.Group controlId="desc">
                    <Form.Label>Description</Form.Label>
                    <Form.Control type="text" value={courseInput.description} onChange={handleInputChange} name="description" />
                </Form.Group> */}

                <Button style={{ marginTop: '20px', width: '100%' }} variant="dark" type="submit">Creează cupon</Button>

            </Form>

        </Container>
        :
        <h1>Dacă pagina nu este redată corect, reîncarcă pagina.</h1>
    )
}
}


export default CouponNewForm