
const validator = require('validator');
const blacklistedChars = /[<>:"/\\|*]/g
// const blacklistedChars = /[<>:"/\\|?*]/g
// const blacklistedChars = /[<>:"\/\\|?*\x00-\x1F]/g

export const isValidEmail = (email) => {
  // The string containing the HTML special characters to be blacklisted
  // const blacklistedChars = '<>&"\'';

  // The options with the blacklisted characters
  const options = {
    blacklisted_chars: blacklistedChars,
    domain_specific_validation: true // enable additional validation enforced by certain email services as Gmail
  };

  return validator.isEmail(email, options);
};

// The validator.isStrongPassword method has the defaultOptions: { minLength: 8, minLowercase: 1, minUppercase: 1, minNumbers: 1, minSymbols: 1, returnScore: false, ... }
export const isStrongPassword = pwd => validator.isStrongPassword(pwd)

// The validator.escape function is used to escape the HTML entities
export const escapeHtml = text => validator.escape(text)

// Sanitize a string by removing the blacklisted characters
export const sanitizeString = unsanitizedString => {
    // const windowsBlacklist = /[<>:"\/\\|?*\x00-\x1F]/g;
    const sanitizedString = unsanitizedString.replace(blacklistedChars, '');
    return sanitizedString
}

// Sanitize a string by removing the blacklisted characters - not used
export const sanitizeAndTrimString = unsanitizedString => {
    // const windowsBlacklist = /[<>:"\/\\|?*\x00-\x1F]/g;
    unsanitizedString = unsanitizedString.trim()
    const sanitizedString = unsanitizedString.replace(blacklistedChars, '');
    return sanitizedString
}



export const trimSpaces = (string) => string?.trim().split(' ')[0]

export const capitalizeString = (str) => str.charAt(0).toUpperCase() + str.slice(1)

export const startDateFormatted = (dmyDate) => {
  if (!dmyDate) return 'dd/mm/yyyy'; // Handle the case when startDate is not set
  const date = new Date(dmyDate);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

// get the customized error message according to the way the error message is created:
  // 1. custom error message is an array and is stored in err.response.data.message
  // 2. middleware error message is a string and is stored in err.response.data --> DISABLED
  // 3. default error message is a string and is stored in err.message
export const getCustomizedErrorMessagesArray = err => 
  err.response?.data.message
    ? err.response.data.message
    // : typeof err.response?.data === 'string'
    //     ? [err.response.data]
        : [err.message];

export const translateDbValues = (str) => {
  const translations = {
    'matematica': 'Matematică',
    'matematica_1': 'Matematică',
    'matematica_2': 'Matematică',
    'matematica_3': 'Matematică',
    'matematica_4': 'Matematică',
    'romana': 'Limba şi literatura română',
    'romana_1': 'Limba şi literatura română',
    'romana_2': 'Limba şi literatura română',
    'informatica': 'Informatică',
    'informatica_1_c': 'Informatică C',
    'informatica_1_pascal': 'Informatică Pascal',
    'informatica_2_c': 'Informatică C',
    'informatica_2_pascal': 'Informatică Pascal',
    'fizica': 'Fizică',
    'fizica_1': 'Fizică',
    'fizica_2': 'Fizică',
    'istorie': 'Istorie',
    'logica': 'Logică, argumentare și comunicare',
    // 'biologie': 'Anatomie și biologie',
    'chimie_anorganica': 'Chimie anorganică',
    'chimie_organica': 'Chimie organică',
    'anatomie': 'Anatomie şi fiziologie umană, genetică şi ecologie umană',
    'biologie': 'Biologie vegetală şi animală',
    // 'anatomie': 'Anatomie',
    // 'biologie': 'Biologie',

    'romana_predare_maghiara': 'Limba şi literatura română, predare în maghiară',
    

    'mateinfo': 'Mate-Info',
    'stiinte': 'Științe',

    'bacalaureat': 'Bacalaureat',
    'evaluarenationala': 'Evaluare Națională',

    'SesiuneSpeciala': 'Sesiune Specială',
    'SesiuneIunie': 'Sesiune Iunie',
    'SesiuneAugust': 'Sesiune August',
    'speciala': 'Sesiune Specială',
    'iunie': 'Sesiune Iunie',
    'august': 'Sesiune August',

    'teoretica': 'teoretică',
    'tehnologica': 'tehologică',
    'vocationala': 'vocațională',

    'real': 'real',
    'umanist': 'umanist',
    'servicii': 'servicii',
    'resurse_naturale_si_protectia_mediului': 'resurse naturale şi protecţia mediului',
    'tehnic': 'tehnic',
    'artistic': 'Artistic',
    'pedagogic': 'Pedagogic',
    'educatie_fizica_si_sport': 'Educaţie fizică şi sport',
    'militar': 'Militar',
    'teologic': 'Teologic',

    'matematica_informatica': 'matematică-informatică', 
    'stiinte_ale_naturii': 'știinţe ale naturii ',

    'filologie': 'filologie', 
    'stiinte_sociale': 'știinţe sociale',

    'tehnician_in_turism': 'Tehnician în turism',
    'tehnician_in_administratie': 'Tehnician în administraţie',
    'tehnician_in_activitati_economice': 'Tehnician în activităţi economice',
    'tehnician_in_activitati_de_posta': 'Tehnician în activităţi de poştă',
    'tehnician_in_achizitii_contractari': 'Tehnician în achiziţii şi contractări',
    'tehnician_in_hotelarie': 'Tehnician în hotelărie',
    'tehnician_in_activitati_comert': 'Tehnician în activităţi de comerţ',
    'tehnician_in_gastronomie': 'Tehnician în gastronomie',
    'organizator_banqueting': 'Organizator banqueting',
    'coafor_stilist': 'Coafor stilist',

    'tehnician_ecolog_si_protecia_calitii_mediului': 'Tehnician ecolog şi protecţia calităţii mediului',
    'tehnician_hidro_meteorolog': 'Tehnician hidro-meteorolog',
    'tehnician_chimist_de_laborator': 'Tehnician chimist de laborator',
    'tehnician_analize_produse_alimentare': 'Tehnician analize produse alimentare',
    'tehnician_veterinar_pentru_animale_de_companie': 'Tehnician veterinar pentru animale de companie',
    'tehnician_in_prelucrarea_lemnului': 'Tehnician în prelucrarea lemnului',
    'tehnician_in_agricultura': 'Tehnician în agricultură',
    'tehnician_agro_montan': 'Tehnician agro-montan',
    'tehnician_veterinar': 'Tehnician veterinar',
    'tehnician_in_silvicultura_si_exploatari_forestiere': 'Tehnician în silvicultură şi exploatări forestiere',
    'tehnician_in_industria_alimentara': 'Tehnician în industria alimentară',
    'tehnician_in_agroturism': 'Tehnician în agroturism',
    'tehnician_agronom': 'Tehnician agronom',
    'tehnician_horticultor': 'Tehnician horticultor',
    'tehnician_zootehnist': 'Tehnician zootehnist',
    'tehnician_in_agricultura_ecologica': 'Tehnician în agricultură ecologică',
    'tehnician_in_prelucrarea_produselor_de_origine_animala': 'Tehnician în prelucrarea produselor de origine animală',
    'tehnician_in_morarit_panificatie_si_produse_fainoase': 'Tehnician în morărit, panificaţie şi produse făinoase',
    'tehnician_in_industria_alimentara_fermentativa_si_in_prelucrarea_legumelor_fructelor': 'Tehnician în industria alimentară fermentativă şi în prelucrarea legumelor şi fructelor',
    'tehnician_in_industria_alimentara_extractiva': 'Tehnician în industria alimentară extractivă',
    'tehnician_pentru_animale_companie': 'Tehnician pentru animale de companie',
    'tehnician_proiectant_produse_finite_din_lemn': 'Tehnician proiectant produse finite din lemn',
    'tehnician_designer_mobila_si_amenajari_interioare': 'Tehnician designer mobilă şi amenajări interioare',

    'tehnician_de_telecomunicatii': 'Tehnician de telecomunicaţii',
    'tehnician_electronist': 'Tehnician electronist',
    'tehnician_electrotehnist': 'Tehnician electrotehnist',
    'tehnician_operator_tehnica_de_calcul': 'Tehnician operator tehnică de calcul',
    'tehnician_in_automatizari': 'Tehnician în automatizări',
    'tehnician_in_instalatii_electrice': 'Tehnician în instalaţii electrice',
    'tehnician_electromecanic': 'Tehnician electromecanic',
    'tehnician_operator_telematica': 'Tehnician operator telematică',
    'tehnician_operatori_roboti_industriali': 'Tehnician operatori roboţi industriali',
    'tehnician_mecatronist': 'Tehnician mecatronist',
    'tehnician_proiectant_cad': 'Tehnician proiectant CAD',
    'tehnician_mecanic_intretinere_si_reparatii': 'Tehnician mecanic pentru întreţinere şi reparaţii',
    'tehnician_prelucrari_mecanice': 'Tehnician prelucrări mecanice',
    'tehnician_transporturi': 'Tehnician transporturi',
    'tehnician_prelucrari_la_cald': 'Tehnician prelucrări la cald',
    'tehnician_metrolog': 'Tehnician metrolog',
    'tehnician_aviatie': 'Tehnician aviaţie',
    'tehnician_instalatii_de_bord_avion': 'Tehnician instalaţii de bord (avion)',
    'tehnician_desenator_pentru_constructii_si_instalatii': 'Tehnician desenator pentru construcţii şi instalaţii',
    'tehnician_in_industria_materialelor_de_constructii': 'Tehnician în industria materialelor de construcţii',
    'tehnician_in_industria_sticlei_si_ceramicii': 'Tehnician în industria sticlei şi ceramicii',
    'tehnician_instalator_pentru_constructii': 'Tehnician instalator pentru construcţii',
    'tehnician_productie_film_si_televiziune': 'Tehnician producţie film şi televiziune',
    'tehnician_in_constructii_si_lucrari_publice': 'Tehnician în construcţii şi lucrări publice',
    'tehnician_audio_video': 'Tehnician audio-video',
    'tehnician_operator_procesare_text_imagine': 'Tehnician operator procesare text imagine',
    'tehnician_poligraf': 'Tehnician poligraf',
    'tehnician_productie_poligrafica': 'Tehnician producţie poligrafică',
    'tehnician_in_chimie_industriala': 'Tehnician în chimie industrială',
    'tehnician_designer_vestimentar': 'Tehnician designer vestimentar',
    'tehnician_in_industria_textila': 'Tehnician în industria textilă',
    'tehnician_in_industria_pielariei': 'Tehnician în industria pielăriei',
    'tehnician_electrician_electronist_auto': 'Tehnician electrician electronist auto',
    'tehnician_energetician': 'Tehnician energetician',
    'tehnician_prelucrari_pe_masini_cu_comanda_numerica': 'Tehnician prelucrări pe maşini cu comandă numerică',
    'tehnician_constructii_navale': 'Tehnician construcţii navale',    
    
    'arhitectura_arte_ambientale_si_design': 'Arhitectură, arte ambientale şi design',
    'arte_plastice_si_decorative': 'Arte plastice şi decorative',
    'coregrafie': 'Coregrafie',
    'muzica': 'Muzică',
    'arta_actorului': 'Arta actorului',

    'invatator_educatoare': 'Învăţător - educatoare',
    'bibliotecar_documentarist': 'Bibliotecar-documentarist',
    'instructor_animator': 'Instructor-animator',
    'instructor_pentru_activitati_extrascolare': 'Instructor pentru activităţi extraşcolare',

    'liceu_cu_program_sportiv': 'Liceu cu program sportiv',
    'pedagog_scolar': 'Pedagog şcolar',

    'teologie_ortodoxa': 'Teologie Ortodoxă',
    'patrimoniu_cultural': 'Patrimoniu cultural',
    'teologie_romano_catolica': 'Teologie Romano – Catolică',
    'teologie_greco_catolica': 'Teologie Greco – Catolică',
    'teologie_baptista': 'Teologie Baptistă',
    'teologie_penticostala': 'Teologie Penticostală',
    'teologie_adventista': 'Teologie Adventistă',
    'teologie_unitariana': 'Teologie Unitariană',
    'teologie_reformata': 'Teologie Reformată',
    'teologie_musulmana': 'Teologie Musulmană'  
  };

  const translatedStr = translations[str.toLowerCase()] || translations[str] || str;
  
  return capitalizeString(translatedStr);
}

// this can be used to generate the translation array used in translateDbValues()
// TTIA: this isn't used anywhere
export const getKeysFromValues = (inputObj) => {
  const transformedObj = {};

  // for (const [key, value] of Object.entries(inputObj)) {
  for (const [key, value] of Object.entries(inputObj)) {
    const newKey = value
      .trim().replace(/ |-/g, '_').replace(/ă/g, 'a').replace(/î/g, 'i').replace(/â/g, 'a').replace(/ș/g, 's').replace(/ț/g, 't').toLowerCase();
    transformedObj[newKey] = value;
  }

  return transformedObj;
}

export 	const dateUnixToCustom = (isoDateString) => {

const date = isoDateString.split('T')[0];
const time = isoDateString.split('T')[1].split(':').splice(0, 2).join(':');
return { date, time }; 
  // dateFormat(1695234738) returns the object { date: '2023-09-20', time: '18:12' }
}
