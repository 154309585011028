import './PostCard.css';

import { FaRegThumbsUp } from "react-icons/fa";
import { Component } from "react";
import { Container, Col, Row, Image, Spinner, Badge, Button} from "react-bootstrap";
import { dateUnixToCustom } from '../../../utils/utilitaryFunctions';
import BlogService from "../../../services/blog.services";
import NewCommentForm from './NewCommentForm';
import CommentsList from './CommentsList';

class PostDetails extends Component{

    constructor(){
        super()
        this.state = {
            post: null,
            trigger: 0
        }
        this.blogService = new BlogService()
    }

    loadPostDetails = () => {
        const {post_id} = this.props.match.params
        this.blogService
            .getPost(post_id, true)
            .then( res => this.setState({post: res.data}))
            .catch( err => {  
                if(err.response?.data){
                    this.props.handleAlert(err.response.data.message, 10000, 'warning', true)
                // }else if(err.response){
                //     this.props.handleAlert([err.response.data], 10000, 'warning', true)
                }else{
                    this.props.handleAlert(['The server is down'], 10000, 'danger', true)
                }  
                })
    }

    componentDidMount = () => {
        this.loadPostDetails()
    }

    likePost = () => {
        const {post_id} = this.props.match.params
        const {loggedUser} = this.props
        this.blogService
            .likePost(post_id, loggedUser)
            .then( res => {
                if( res.thumbsUp !== this.state.post.thumbsUp ){
                    this.loadPostDetails()
                    this.props.fetchUser()
                }
            })
            .catch( err => {   
                if(err?.response){
                    console.log( err.response.data)
                    // this.props.handleAlert([err.response.data], 10000, 'warning', true)
                    this.props.handleAlert(err.response.data.message, 10000, 'warning', true)
                }   else{
                    this.props.handleAlert(['The server is down'], 10000, 'danger', true)
                }  
                })
    }

    //TTIA: this can be moved in CommentsList
    refreshCommentsList = () => {
        const incrementedTrigger = this.state.trigger
        this.setState({trigger: incrementedTrigger + 1})
    }
    
    // componentDidUpdate = (prevProps, prevState) => prevState.trigger !== this.state.trigger && this.loadPostDetails()

    render(){

        // if( !this.state.post){
        //     return <Spinner size={60}/>
        // }

        // const { _id, title, body, image, links, author, comment, thumbsUp, apreciatedBy, createdAt } = this.state.post
        // const {date, time} = dateUnixToCustom(createdAt)


        return(

            !this.state.post
            ?
            <Spinner size={60}/>
            :
            <Container>
                <Row style={{marginTop: '1em'}}>
                    <Col md={6} xl={5}>
                        <Image src={this.state.post.image} alt={this.state.post.title} fluid style={{ maxHeight: '100%', width: 'auto' }} />
                        {/* <img src={image} alt={title} fluid/> */}
                    </Col>
                    <Col md={6} xl={7}>
                        <article>
                            <h4>{this.state.post.title}</h4>

                            

                            <p>
                                <span>{this.state.post.author.name} {this.state.post.author.surname}</span> | 
                                <span> {dateUnixToCustom(this.state.post.createdAt).date}</span> | 
                                <span> {dateUnixToCustom(this.state.post.createdAt).time}</span> | 
                                <span> 
                                    <Button variant="success" style={{marginLeft:'1em'}} onClick={this.likePost}>
                                    <span>Apreciază postarea</span>  
                                            <FaRegThumbsUp style={{  margin: '0 0.5em' }} />
                                            <Badge bg="secondary">{this.state.post.thumbsUp}</Badge>
                                    </Button>
                                </span>
                            </p>
                            {
                                this.state.post.body.map( (phrase, key) => <p key={`${key}-${phrase}`}>{phrase}</p>)
                                // this.state.post.body.map( (phrase, key) => <p key={key}>{phrase}</p>)
                            }
                            
                            <Row>
                            {                                
                                this.state.post.links.map( (button, key) => 
                                <Col md={6}>
                                    <a key={key} href={button.url} target="_blank" rel="noopener noreferrer" className="btn btn-info btnBlock" style={{marginTop:'1em'}}>
                                        {button.description}
                                    </a>
                                </Col>

                                )
                            }
                            </Row>                           

                        </article>
                    </Col>
                </Row>

                <CommentsList postId={this.state.post._id}  loggedUser={this.props.loggedUser} 
                    handleAlert={this.props.handleAlert} trigger={this.state.trigger} fetchUser={this.props.fetchUser}
                    refreshCommentsList = {this.refreshCommentsList}/>
                    
                <NewCommentForm postId={this.state.post._id} maxNumberOfChars={500} loggedUser={this.props.loggedUser} 
                    handleAlert={this.props.handleAlert} refreshCommentsList={this.refreshCommentsList}/>

            </Container>
            
            
        )
    }
}

export default PostDetails