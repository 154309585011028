import { Component } from "react";
import QuestionsAndAnswersService from "../../../services/questionsAndAnswersService.services";
import Spinner from '../../shared/Spinner'

import { Row } from 'react-bootstrap'
import AnswerCard from "./AnswerCard";
import NewAnswerForm from "./NewAnswerForm";

class AnswersList extends Component{

    constructor(){
        super()
        this.state = {
            answers: undefined,
        }
        this.questionsAndAnswersService = new QuestionsAndAnswersService()
    }

    loadAnswers = () => {
        // const {post_id} = this.props.match.query
        const {questionId} = this.props
        this.questionsAndAnswersService
            .getAllAnswers(questionId)
            .then( res => this.setState({answers: res.data}))
            .catch( err => {   
                if(err.response){
                    this.props.handleAlert([err.response.data], 10000, 'warning', true)
                }   else{
                    this.props.handleAlert(['The server is down'], 10000, 'danger', true)
                }  
                })
    }

    componentDidMount = () => {
        this.loadAnswers()
    }

    refreshAnswers = () => {
        this.loadAnswers()
    }


    render(){

        const answersList = 
            !this.state.answers
            ?
            <Spinner size={200}/>
            :
                this.state.answers.length 
                ?
                <div style={{ margin: '0 2em' }}>
                
                    <h5>Răspunsuri:</h5>
                    <Row>
                        {this.state.answers?.map( elm => 
                            <AnswerCard  loggedUser={this.props.loggedUser} handleAlert={this.props.handleAlert} fetchUser={this.props.fetchUser}                           
                                refreshQuestionsList = {this.props.refreshQuestionsList}
                                refreshAnswersList={this.loadAnswers}
                                key = {elm._id} answer={{...elm}}/>)}
                    </Row>                
                </div>
                :
                null

        return(
            <>
                {answersList}
                <NewAnswerForm questionId={this.props.questionId} maxNumberOfChars={200} loggedUser={this.props.loggedUser}                            
                    refreshQuestionsList = {this.props.refreshQuestionsList}
                    refreshAnswersList={this.loadAnswers}
                    handleAlert={this.props.handleAlert} 
                    />
            </>
            
            
        )
    }
}

export default AnswersList