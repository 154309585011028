import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import { Link } from 'react-router-dom';

const CheckoutCanceled = () => {

  return (
    <Container style={{ marginTop: '3em' }}>
        <Row>
            <Col md={{ span: 6, offset: 3}}>
                <div className='checkout'>
                    <h1>Comanda nu a fost procesată.</h1>
                    <p>Plata a eșuat. Folosește o altă metodă de plată.</p>
                    <div>
                    <Link to="/courses"  style={{ marginTop: '20px', width: '100%' }} className='btn btn-dark'>
                        Înapoi la cursuri
                    </Link>
                    </div>
                </div>
            </Col>
        </Row>
    </Container>
  );
}

export default CheckoutCanceled;