import {Container} from 'react-bootstrap'
import CartItemCard from './CartItemCard'

const CartItemsList = ({cart, cartFromStorage, removeItem, applyDiscount}) => {

    // console.log(loggedUser.role)

    return(
        <Container>
            {
                // cart.cartItems.map((el, key) => <CartItemCard key={key} title={el.name} imageUrl={el.image} 
                cartFromStorage?.cartItems?.map((el, key) => <CartItemCard key={key} title={el.name} imageUrl={el.image} 
                    price={el.price} discountedPrice={el.discountedPrice} _id = {el._id}  
                    removeItem={removeItem} coupons={el.coupons} applyDiscount={applyDiscount}/>)
            }

        </Container>
    )
}

export default CartItemsList