import { Component } from 'react'
import { Container, Form, Button, Row, Col, Spinner } from 'react-bootstrap'
// import { Link } from 'react-router-dom'

import AuthService from '../../../services/auth.services'
import EmailService from '../../../services/email.services'
import jsxToHtml from '../../../utils/jsxToHtml'
import {isValidEmail, isStrongPassword} from './../../../utils/utilitaryFunctions'
// import {isValidEmail, isStrongPassword, sanitizeString} from './../../../utils/utilitaryFunctions'
import WelcomeEmailTemplate from '../EmailTemplates/WelcomeEmailTemplate'
import ReCAPTCHA from 'react-google-recaptcha'
import {removeRecaptchaScript, removeRecaptchaBadge} from '../../../utils/removeRecaptchaScriptAndBadge'

import './SignUp.css'

class Signup extends Component {

    constructor() {
        super()
        this.state = {
            email: '',
            pwd: '',
            pwd2: '',
            name: '',
            surname: '',
            username: '',
            legalAcknowledgment: false,
            reCaptchaValue: null,
            reCaptchaLoaded: false,

        }
        this.authService = new AuthService()
        this.emailService = new EmailService()
        
    }


    handleInputChange = e => {
        const { name, value, checked } = e.target

        if( name === 'legalAcknowledgment' ){
            this.setState({ [name]: checked })
        }else{
            this.setState({ [name]: value })
        }
    }

    // store the reCaptchaToken value in this.state.recaptchaValue
    setReCaptchaValue = value => {
        // console.log('#################### ', value)
        this.setState({ reCaptchaValue: value })
    }

    // set this.state.reCaptchaLoaded to true after the Recaptcha component is rendered
    onReCAPTCHALoaded = () => {
        this.setState({ reCaptchaLoaded: true });
    }

    handleFormSubmit = e => {

        e.preventDefault()

        const { email, pwd, pwd2, name, surname, username, legalAcknowledgment, reCaptchaValue } = this.state

        if(! (email && pwd && pwd2 && name && surname && username ) ){
            this.props.handleAlert([`Toate câmpurile sunt obligatorii.`], 10000, 'warning', true)
            return
        }else if(! (isValidEmail(email) ) ){
            this.props.handleAlert([`Emailul trebuie să aibă un format valid.`], 10000, 'warning', true)
            return
        }else if ( pwd !== pwd2 ) {
            this.props.handleAlert(['Trebuie să introduci aceeași parolă în ambele câmpuri.'], 10000, 'warning', true);
            return;
        }else if ( !isStrongPassword(pwd) ) {
            this.props.handleAlert(['Parola trebuie să conțină cel puțin 8 caractere, dintre care: ', 'o minusculă, o majusculă, o cifră și un caracter special'], 10000, 'warning', true);
            return;
        // }else if ( name !== sanitizeString(name) || surname !== sanitizeString(surname) || username !== sanitizeString(username) ) {
        //     this.props.handleAlert(['Eliminați caractererele speciale din pseudonim, prenume sau nume de familie'], 10000, 'warning', true);
        //     return;
        }else if (!legalAcknowledgment) {
            this.props.handleAlert(['Poți continua doar dacă ești de acord cu Termenii și condițiile și cu Politica de confidențialitate.'], 10000, 'warning', true);
            return;
        }else if (this.state.reCaptchaValue === null) {
            this.props.handleAlert(['Poți continua doar dacă nu ești robot.'], 10000, 'warning', true);
            return;
        }

        const welcomeEmailTemplate = <WelcomeEmailTemplate username={username} />
        const welcomeEmailHtmlTemplate = jsxToHtml( welcomeEmailTemplate )

        this.authService
            .signup(email, pwd, pwd2, name, surname, username, legalAcknowledgment, reCaptchaValue, 'v2Normal')
            .then((res) => {
                let name = username
                let message = welcomeEmailHtmlTemplate
                
                this.authService
                    .generateToken(email)
                    .then(res => {
                        const {token} = res.data
                        // UNCOMMENT START
                        this.emailService
                            .sendWelcomeEmail(name, email, token, message)
                            .then( () => {
                                if(!this.props.isModal){
                                    this.props.history.push('/logIn')
                                }
                                this.props.handleAlert(['Contul tău a fost creat.','Vei primi un email de intâmpinare.'], 10000, 'info', true)
                            })
                            .catch(err => {
                                // console.log(`************************************** the error thrown by emailService.sendWelcomeEmail is ${err} **************************************`)
                                this.props.handleAlert(['Emailul de întâmpinare nu a fost trimis.'], 10000, 'warning', true)
                                return
                            }) 
                    })
                    .catch( err => {
                        this.props.handleAlert(err.response?.data.message ? 
                            err.response?.data.message : [err.response?.statusText], 10000, 'warning', true)
                    })              
                    
                    
                    // UNCOMMENT END
                       
                    if(this.props.isModal){
                        this.props.setLocalCustomerEmail(email)
                        this.props.toggleShowSignUpModal()
                    }         
            }) 
            .catch(err => {
                console.log(err)
                this.props.handleAlert(err.response?.data.message ? err.response?.data.message : ['A avut loc o eroare.', 'Încearcă din nou.'], 10000, 'warning', true)
        })
    }

    componentWillUnmount() {
        // Remove reCAPTCHA script when component is unmounted
        removeRecaptchaScript();
        removeRecaptchaBadge()

        // Reset or clear any state related to the SignUp component
        this.setState({
            email: '',
            pwd: '',
            pwd2: '',
            name: '',
            surname: '',
            username: '',
            legalAcknowledgment: false,
            reCaptchaValue: null,
            reCaptchaLoaded: false,
        });

        // // Delete any reCAPTCHA-related cookies
        // const cookies = document.cookie.split("; ");
        // for (let i = 0; i < cookies.length; i++) {
        //     const cookie = cookies[i];
        //     const eqPos = cookie.indexOf("=");
        //     const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        //     if (name.startsWith("rc::")) {
        //         document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        //     }
        // }

        // Delete any reCAPTCHA-related cookies
        // document.cookie = "rc::c=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    render() {
        const {isModal} = this.props
        return (

            <Container>

                <Row>

                    {/* <Col md={{ span: 4, offset: 4 }}> */}
                    <Col md={{ span: isModal ? 12 : 4, offset: isModal ? 0 : 4 }}>

                        {!isModal && <h1>Cont Nou</h1>}

                        {!isModal && <hr></hr>}

                        <Form onSubmit={this.handleFormSubmit}>

                            <Form.Group controlId="email">
                                <Form.Label>Email*</Form.Label>
                                <Form.Control type="email" value={this.state.email} onChange={this.handleInputChange} name="email" />
                            </Form.Group>

                            <Form.Group controlId="pwd">
                                <Form.Label>Alege o parolă sigură*</Form.Label>
                                {/* <Form.Label>Password*</Form.Label> */}
                                <Form.Control type="password" value={this.state.pwd} onChange={this.handleInputChange} name="pwd" />
                            </Form.Group>

                            <Form.Group controlId="pwd2">
                                <Form.Label>Confirmă parola*</Form.Label>
                                {/* <Form.Label>Repeat Password*</Form.Label> */}
                                <Form.Control type="password" value={this.state.pwd2 || ''} onChange={this.handleInputChange} name="pwd2" />
                            </Form.Group>

                            <Form.Group controlId="username">
                                <Form.Label>Pseudonim*</Form.Label>
                                <Form.Control type="text" value={this.state.username} onChange={this.handleInputChange} name="username" />
                            </Form.Group>

                            <Form.Group controlId="name">
                                <Form.Label>Prenume</Form.Label>
                                <Form.Control type="text" value={this.state.name} onChange={this.handleInputChange} name="name" />
                            </Form.Group>

                            <Form.Group controlId="surname">
                                <Form.Label>Nume de familie</Form.Label>
                                <Form.Control type="text" value={this.state.surname} onChange={this.handleInputChange} name="surname" />
                            </Form.Group>

                            <Form.Group controlId="termsAndConditions" style={{paddingTop:"1em"}}>
                                <Form.Check 
                                    type="checkbox" 
                                    label={
                                        <span>
                                            Am citit 
                                            <a 
                                                href="/terms-of-use/" 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                className='legalAcknowledgmentLink'
                                            >
                                                Termenii si condițiile
                                            </a> 
                                            și 
                                            <a 
                                                href="/privacy-policy/" 
                                                target="_blank" 
                                                rel="noopener noreferrer"
                                                className='legalAcknowledgmentLink'
                                            >
                                                Politica de confidențialitate
                                            </a> 
                                            și sunt de acord cu acestea.
                                        </span>
                                    } 
                                    name="legalAcknowledgment" 
                                    onChange={this.handleInputChange} 
                                    checked={this.state.legalAcknowledgment} 
                                />
                            </Form.Group>

                            
                            <Form.Label style={{paddingTop:"1em"}}>Câmpurile marcate cu * sunt necesare pentru crearea contului.</Form.Label>
                            <Form.Label><strong style={{color:"red"}}>Important:</strong> <em>acest site de probă este temporar și conturile 
                                împreună cu toate datele personale furnizate pentru crearea lor vor fi șterse odată cu blocarea site-ului.</em>
                            </Form.Label>

                            <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY} 
                                onChange={ val => this.setReCaptchaValue( val )} 
                                asyncScriptOnLoad={this.onReCAPTCHALoaded} 
                                hl={'ro'}
                                size={'normal'}/>
                            
                            {
                                !this.state.reCaptchaLoaded 
                                &&
                                <Spinner animation="border" />
                            }

                            <Button style={{ marginTop: '20px', width: '100%' }} variant="dark" type="submit" 
                                disabled={this.state.reCaptchaValue === null}>Creează cont</Button>
                            
                            {
                                isModal
                                &&
                                
                                <Button style={{ marginTop: '20px', width: '100%' }}  variant="danger"  
                                    onClick={() => this.props.toggleShowSignUpModal()}
                                >
                                    Abandonează
                                </Button>
                            }

                        </Form>

                    </Col>
                </Row>

            </Container >

        )
    }
}


export default Signup