const subjectsTeoreticaReal1 = ['romana_2', 'matematica_1', 'informatica_1_c', 'informatica_1_pascal', 'fizica_1', 'chimie_anorganica', 'chimie_organica', 'biologie', 'anatomie']
const subjectsTeoreticaReal2 = ['romana_2', 'matematica_2', 'informatica_2_c', 'informatica_2_pascal', 'fizica_1', 'chimie_anorganica', 'chimie_organica', 'biologie', 'anatomie']
const subjectsTeoreticaUman = ['romana_1', 'geografie', 'logica', 'psihologie', 'economie','sociologie', 'filosofie'] //umanist
const subjectsTehnologica1 = ['romana_2', 'matematica_3', 'fizica_2', 'chimie_anorganica', 'chimie_organica', 'biologie', 'anatomie']
const subjectsTehnologica2 = ['romana_2', 'matematica_3', 'geografie', 'logica', 'psihologie', 'economie']
const subjectsVocationala1 = ['romana_1', 'matematica_4', 'geografie', 'logica', 'psihologie', 'economie', 'filosofie'] //pedagogic
const subjectsVocationala2 = ['romana_2', 'istorie', 'geografie', 'logica', 'psihologie', 'economie', 'filosofie']

export const subjectsByMajor = {
  teoretica: {
      real: {
        matematica_informatica: subjectsTeoreticaReal1, 
        stiinte_ale_naturii: subjectsTeoreticaReal2,
      }, // Replaced C with Major
      umanist: {
        filologie: subjectsTeoreticaUman,
        stiinte_sociale: subjectsTeoreticaUman,
    }
  },

  tehnologica: {
    servicii: {
      tehnician_in_turism: subjectsTehnologica2,
      tehnician_in_administratie: subjectsTehnologica2,
      tehnician_in_activitati_economice: subjectsTehnologica2,
      tehnician_in_activitati_de_posta: subjectsTehnologica2,
      tehnician_in_achizitii_contractari: subjectsTehnologica2,
      tehnician_in_hotelarie: subjectsTehnologica2,
      tehnician_in_activitati_comert: subjectsTehnologica2,
      tehnician_in_gastronomie: subjectsTehnologica2,
      organizator_banqueting: subjectsTehnologica2,
      coafor_stilist: subjectsTehnologica2,
    },
    resurse_naturale_si_protectia_mediului: {
      tehnician_ecolog_si_protecia_calitii_mediului: subjectsTehnologica1,
      tehnician_hidro_meteorolog: subjectsTehnologica1,
      tehnician_chimist_de_laborator: subjectsTehnologica1,
      tehnician_analize_produse_alimentare: subjectsTehnologica1,
      tehnician_veterinar_pentru_animale_de_companie: subjectsTehnologica1,
      tehnician_in_prelucrarea_lemnului: subjectsTehnologica1,
      tehnician_in_agricultura: subjectsTehnologica1,
      tehnician_agro_montan: subjectsTehnologica1,
      tehnician_veterinar: subjectsTehnologica1,
      tehnician_in_silvicultura_si_exploatari_forestiere: subjectsTehnologica1,
      tehnician_in_industria_alimentara: subjectsTehnologica1,
      tehnician_in_agroturism: subjectsTehnologica1,
      tehnician_agronom: subjectsTehnologica1,
      tehnician_horticultor: subjectsTehnologica1,
      tehnician_zootehnist: subjectsTehnologica1,
      tehnician_in_agricultura_ecologica: subjectsTehnologica1,
      tehnician_in_prelucrarea_produselor_de_origine_animala: subjectsTehnologica1,
      tehnician_in_morarit_panificatie_si_produse_fainoase: subjectsTehnologica1,
      tehnician_in_industria_alimentara_fermentativa_si_in_prelucrarea_legumelor_fructelor: subjectsTehnologica1,
      tehnician_in_industria_alimentara_extractiva: subjectsTehnologica1,
      tehnician_pentru_animale_companie: subjectsTehnologica1,
      tehnician_proiectant_produse_finite_din_lemn: subjectsTehnologica1,
      tehnician_designer_mobila_si_amenajari_interioare: subjectsTehnologica1,
    },
    tehnic: {
      tehnician_de_telecomunicatii: subjectsTehnologica1,
      tehnician_electronist: subjectsTehnologica1,
      tehnician_electrotehnist: subjectsTehnologica1,
      tehnician_operator_tehnica_de_calcul: subjectsTehnologica1,
      tehnician_in_automatizari: subjectsTehnologica1,
      tehnician_in_instalatii_electrice: subjectsTehnologica1,
      tehnician_electromecanic: subjectsTehnologica1,
      tehnician_operator_telematica: subjectsTehnologica1,
      tehnician_operatori_roboti_industriali: subjectsTehnologica1,
      tehnician_mecatronist: subjectsTehnologica1,
      tehnician_proiectant_cad: subjectsTehnologica1,
      tehnician_mecanic_intretinere_si_reparatii: subjectsTehnologica1,
      tehnician_prelucrari_mecanice: subjectsTehnologica1,
      tehnician_transporturi: subjectsTehnologica1,
      tehnician_prelucrari_la_cald: subjectsTehnologica1,
      tehnician_metrolog: subjectsTehnologica1,
      tehnician_aviatie: subjectsTehnologica1,
      tehnician_instalatii_de_bord_avion: subjectsTehnologica1,
      tehnician_desenator_pentru_constructii_si_instalatii: subjectsTehnologica1,
      tehnician_in_industria_materialelor_de_constructii: subjectsTehnologica1,
      tehnician_in_industria_sticlei_si_ceramicii: subjectsTehnologica1,
      tehnician_instalator_pentru_constructii: subjectsTehnologica1,
      tehnician_productie_film_si_televiziune: subjectsTehnologica1,
      tehnician_in_constructii_si_lucrari_publice: subjectsTehnologica1,
      tehnician_audio_video: subjectsTehnologica1,
      tehnician_operator_procesare_text_imagine: subjectsTehnologica1,
      tehnician_poligraf: subjectsTehnologica1,
      tehnician_productie_poligrafica: subjectsTehnologica1,
      tehnician_in_chimie_industriala: subjectsTehnologica1,
      tehnician_designer_vestimentar: subjectsTehnologica1,
      tehnician_in_industria_textila: subjectsTehnologica1,
      tehnician_in_industria_pielariei: subjectsTehnologica1,
      tehnician_electrician_electronist_auto: subjectsTehnologica1,
      tehnician_energetician: subjectsTehnologica1,
      tehnician_prelucrari_pe_masini_cu_comanda_numerica: subjectsTehnologica1,
      tehnician_constructii_navale: subjectsTehnologica1,
    }
  },

  vocationala: {
    artistic: {
      arhitectura_arte_ambientale_si_design: subjectsVocationala2,
      arte_plastice_si_decorative: subjectsVocationala2,
      coregrafie: subjectsVocationala2,
      muzica: subjectsVocationala2,
      arta_actorului: subjectsVocationala2,
    },
    pedagogic: {
      invatator_educatoare: subjectsVocationala1,
      bibliotecar_documentarist: subjectsVocationala2,
      instructor_animator: subjectsVocationala2,
      instructor_pentru_activitati_extrascolare: subjectsVocationala2
    },
    educatie_fizica_si_sport: {
      liceu_cu_program_sportiv: subjectsVocationala2,
      pedagog_scolar: subjectsVocationala2,
    },
    militar: {
      matematica_informatica: subjectsTeoreticaReal1,
    },
    teologic: {
      teologie_ortodoxa: subjectsVocationala2,
      patrimoniu_cultural: subjectsVocationala2,
      teologie_romano_catolica: subjectsVocationala2,
      teologie_greco_catolica: subjectsVocationala2,
      teologie_baptista: subjectsVocationala2,
      teologie_penticostala: subjectsVocationala2,
      teologie_adventista: subjectsVocationala2,
      teologie_unitariana: subjectsVocationala2,
      teologie_reformata: subjectsVocationala2,
      teologie_musulmana: subjectsVocationala2,
    }
  }
}

export const allSubjects = ['matematica', 'matematica_1', 'matematica_2', 'matematica_3', 'matematica_4', 'romana', 'romana_1', 'romana_2', 
  'informatica_1_c', 'informatica_1_pascal', 'informatica_2_c', 'informatica_2_pascal',
  'fizica_1', 'fizica_2', 'chimie_anorganica','chimie_organica', 'biologie', 'anatomie', 
  'geografie', 'logica', 'psihologie', 'economie','sociologie', 'filosofie', 'istorie', 'misc']
