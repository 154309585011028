import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
// import { withRouter } from 'react-router-dom';
import CustomCheckoutPaymentForm from './CustomCheckoutPaymentForm';

const stripePromise = loadStripe( process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

const CustomCheckout = ( {cart, cartFromStorage, handleAlert, loggedUser, removeAllItems} ) => {    

    return(
        <Elements stripe = {stripePromise} >
            <CustomCheckoutPaymentForm cart = {cart} cartFromStorage={cartFromStorage} handleAlert = {handleAlert} 
            loggedUser={loggedUser} 
            removeAllItems={removeAllItems} 
                stripePromise = {stripePromise}/>
        </Elements>

    )

}

export default CustomCheckout