import React from 'react';

const WelcomeEmailTemplate = ({ username }) => {
    // for failed attempts check the file 'WelcomeEmailTemplate_Comments.txt'
    const tableStyle = { maxWidth: '600px', margin: '0 auto', textAlign: 'center', fontFamily: 'Arial, sans-serif' }
    const greetingStyle = { color: '#333', fontSize: '1.5em', marginTop: '20px' }
    const paragraphStyle = { color: '#555', fontSize: '1em', marginTop: '1em', marginBottom: '0', paddingTop: '0.3em' }
    const logoStyle = { maxWidth: '100%', height: 'auto', width: '3.5em', marginRight: '10px' }
    const signatureStyle = { color: '#333', fontSize: '1.3em', marginBottom: '0', marginTop: '0' }


  return (
    <table width="100%" border="0" cellSpacing="0" cellPadding="0" style={tableStyle}>
      <tr>
        <td style={greetingStyle}>Salut, {username}!</td>
      </tr>
      <tr>
        <td style={paragraphStyle}>Contul tău a fost creat.</td>
      </tr>
      <tr>
        <td style={paragraphStyle}>Bine ai venit în comunitatea MateCuMatei!</td>
      </tr>
      <tr>
        <td style={paragraphStyle}>Suntem entuziasmați că ni te-ai alăturat, deoarece împreună învățăm mai ușor.</td>
      </tr>
      <tr>
        <td style={paragraphStyle}>Succes!</td>
      </tr>
      <tr>
      {/* nested table needed here: */}
        <td style={{ textAlign: 'center' }}>  
          <table style={{ display: 'inline-block' }}>
            <tr>
              <td>
                <img src="https://res.cloudinary.com/dvz9tjidq/image/upload/v1680680365/MateCuMatei/crmflhfjnkjwokwypst2.png" alt="MateCuMatei Logo" 
                    style={logoStyle} />
              </td>
              <td>
                <p style={signatureStyle}>Academia MateCuMatei</p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>



  

  );
}


export default WelcomeEmailTemplate;

