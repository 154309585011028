import {Container} from 'react-bootstrap'
import CartItemsList from './CartItemsList'
import CartTotal from './CartTotal'
// import classes from './CartPage.module.css';
// import { Elements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

const CartPage = ({ loggedUser, storeUser, cart, cartFromStorage, removeItem, removeAllItems, handleAlert, applyDiscount}) => {
    
    const stripePromise = loadStripe( process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
    // const stripe = useStripe()

    return(
        <Container className='mt-5'>
            {/* <Elements stripe={ stripePromise }>  */}
            {
                cart.itemsCount === 0
                ?
                <h1>Momentan nu ai niciun curs in coș.</h1>
                :
                <>
                <h1>Coșul tău conține următoarele cursuri:</h1>
                <CartItemsList cart={cart} cartFromStorage={cartFromStorage} removeItem={removeItem} applyDiscount={applyDiscount}/>
                <CartTotal 
                    loggedUser={loggedUser} storeUser={storeUser}
                    cart={cart} cartFromStorage={cartFromStorage}
                    cartItems = {cart.cartItems} itemCount={cart.itemsCount} total={cartFromStorage.total} 
                    removeAllItems={removeAllItems}
                    stripePromise ={ stripePromise }
                    handleAlert = {handleAlert}
                    />
                </>
            }
            {/* </Elements> */}
        </Container>
    )
}

export default CartPage