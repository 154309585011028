import {Container, Button} from 'react-bootstrap'
import CouponsList from './CouponsList'

import { Link } from 'react-router-dom'

const CouponsPage = ({loggedUser, handleAlert}) => {

    // console.log(loggedUser.role)

    return(
        <Container>
            <CouponsList loggedUser={loggedUser} handleAlert={handleAlert}/>
            {
                loggedUser?.role==='admin'
                ?
                <Link to={`/coupons/new`}>
                    <Button className="btnBlock">New Coupon</Button>
                </Link>
                :
                null
            }

            <p style={{marginBottom: '3rem'}}></p>
        </Container>
    )
}

export default CouponsPage