import axios from 'axios' 
 
 class BlogService{

    constructor(){
        this.app = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL + '/blog',
            // baseURL: 'https://matecumatei-server.herokuapp.com/api/courses',
            // baseURL: 'http://localhost:5000/api/courses',
            withCredentials: true
        })
    }

    getAllPosts = () => this.app.get('/')
    newPost = (post_info) => this.app.post('/new', post_info)
    // savePost = (post_info) => this.app.post('/new', post_info)
    likePost = (post_id, loggedUser) => this.app.post('/like', {post_id, loggedUser})
    getPost = (post_id, populated) => this.app.get(`/details/${post_id}/${populated}`)
    deletePost = (post_id) => this.app.delete(`/delete/${post_id}`)

    getAllComments = (post_id) => this.app.get(`/comments?postId=${post_id}`)
    newComment = (postId, message, loggedUserId, token) => this.app.post('/comments/new', {postId, message, loggedUserId, token})
    likeComment = (comment_id, loggedUser) => this.app.post('/comments/like', {comment_id, loggedUser})
    getComment = (comment_id, populated) => this.app.get(`/comments/details/${comment_id}/${populated}`)
    deleteComment = (comment_id) => this.app.delete(`/comments/delete/${comment_id}`)

    getAllReplies = (comment_id) => this.app.get(`/replies?commentId=${comment_id}`)
    newReply = (commentId, message, loggedUserId, token) => this.app.post('/replies/new', {commentId, message, loggedUserId, token})
    likeReply = (reply_id, loggedUser) => this.app.post('/replies/like', {reply_id, loggedUser})
    getReply = (reply_id, populated) => this.app.get(`/replies/details/${reply_id}/${populated}`)
    deleteReply = (reply_id) => this.app.delete(`/replies/delete/${reply_id}`)

 }

 export default BlogService