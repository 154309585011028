// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CurriculumLecturePage_container__1L6eZ {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  border-top: 1px solid rgb(104, 103, 103);\n  /* border-radius: 0.2em; */\n  padding: 0.5em 0;\n  margin: 0;\n  background-color: white;\n  width: 100%;\n}\n\n.CurriculumLecturePage_block__2kzC6 {\n  padding: 2px 0;\n  /* text-align: center; */\n}\n\n.CurriculumLecturePage_smallPicContainer__2Cx03{\n  width: 1em;\n  height: 1em;\n  padding: 0;\n  margin: 0;\n}\n\n.CurriculumLecturePage_smallPic__1-DMZ{\n  width: 100%;\n  height: auto;\n  /* padding: 0; */\n  /* margin: auto 0; */\n}\n", "",{"version":3,"sources":["webpack://src/components/pages/CourseDetails/CurriculumLecturePage.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,wCAAwC;EACxC,0BAA0B;EAC1B,gBAAgB;EAChB,SAAS;EACT,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,wBAAwB;AAC1B;;AAEA;EACE,UAAU;EACV,WAAW;EACX,UAAU;EACV,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,oBAAoB;AACtB","sourcesContent":[".container {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  border-top: 1px solid rgb(104, 103, 103);\n  /* border-radius: 0.2em; */\n  padding: 0.5em 0;\n  margin: 0;\n  background-color: white;\n  width: 100%;\n}\n\n.block {\n  padding: 2px 0;\n  /* text-align: center; */\n}\n\n.smallPicContainer{\n  width: 1em;\n  height: 1em;\n  padding: 0;\n  margin: 0;\n}\n\n.smallPic{\n  width: 100%;\n  height: auto;\n  /* padding: 0; */\n  /* margin: auto 0; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "CurriculumLecturePage_container__1L6eZ",
	"block": "CurriculumLecturePage_block__2kzC6",
	"smallPicContainer": "CurriculumLecturePage_smallPicContainer__2Cx03",
	"smallPic": "CurriculumLecturePage_smallPic__1-DMZ"
};
export default ___CSS_LOADER_EXPORT___;
