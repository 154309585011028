import { Component } from 'react'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
// import isValidEmail from '../../../utils/isValidEmail'
import {sanitizeString} from '../../../utils/utilitaryFunctions'
// import { Link } from 'react-router-dom'

import AuthService from '../../../services/auth.services'
import EmailService from '../../../services/email.services'
import QuestionsAndAnswersService from '../../../services/questionsAndAnswersService.services'

class NewAnswerForm extends Component {

    constructor() {
        super()
        this.state = {
            questionId: null,
            message: '',
            charCount: 0,
            rows: 0
        }
        this.authService = new AuthService()
        this.emailService = new EmailService()
        this.questionsAndAnswersService = new QuestionsAndAnswersService()
    }


    loadState = () => {

        const {questionId, maxNumberOfChars} = this.props
        const rows = Math.floor( maxNumberOfChars / 50)

        this.setState({questionId, maxNumberOfChars, rows})

    }

    componentDidMount = () => {
        this.loadState()
    }

    resetState = () => {
        this.setState({
            message: '',
            charCount: 0,
        })
    }

    handleInputChange = e => {
        const { name, value } = e.target
        if(name==='message'){
            this.setState({ [name]: value, charCount: value.length })
        } else{
            this.setState({ [name]: value })
        }
    }


    handleFormSubmit = e => {

        e.preventDefault()

        const { message} = this.state
        
        if(! ( this.props.loggedUser ) ){
            this.props.handleAlert([`Trebuie să te autentifici pentru a răspunde la întrebări.`], 10000, 'warning', true)
            return
        }else if(! ( message ) ){
            this.props.handleAlert([`Mesajul nu conține niciun caracter.`], 10000, 'warning', true)
            return
        }else if ( message !== sanitizeString(message) ) {
            this.props.handleAlert(['Eliminați caractererele speciale din mesaj.', 
                'Exemple de caractere speciale: <, >, :, ", /, \\, |, *.'], 10000, 'warning', true);
            return;
        }

        // de verificat token-ul
        this.authService
            .generateToken(this.props?.loggedUser?.email)
            .then(res => {
                const {token} = res.data
                this.questionsAndAnswersService
                    .newAnswer( this.state.questionId, this.state.message, this.props.loggedUser._id, token )
                    .then( res => {
                        this.resetState()
                        this.props.refreshQuestionsList()
                        this.props.refreshAnswersList()
                    })
                    .catch(err => {                               
                        console.log(err)
                        this.props.handleAlert(err.response?.data.message ? 
                                err.response?.data.message : 
                                ['Mesajul nu a fost trimis din cauza unei erori de rețea.'], 10000, 'warning', true)
                        // TTIA: redirect here.
                    })
                })
            .catch( err => {
                this.props.handleAlert(err.response?.data.message ? 
                    err.response?.data.message : [err.response?.statusText], 10000, 'warning', true)
            })  

    }



    render() {
        return (

            <Container>

                <Row>

                    <Col md={{ span: 10, offset: 1 }}>

                        {/* <h5>Inițiază o nouă conversație</h5> */}

                        <hr></hr>

                        <Form onSubmit={this.handleFormSubmit}>

                            <Form.Group controlId="message">
                                <Form.Label><h5>Adaugă un răspuns:</h5></Form.Label>
                                <Form.Control as="textarea" rows={this.state.rows}  value={this.state.message} 
                                    placeholder="Scrie aici răspunsul tău." onChange={this.handleInputChange} name="message" />
                                <small
                                    style={{color: this.state.charCount > this.state.maxNumberOfChars ? 'red' : '#6c757d'}}>
                                        Caractere: {this.state.charCount}/{this.state.maxNumberOfChars}
                                </small>
                            </Form.Group>

                            <Button style={{ marginTop: '20px', width: '100%' }} variant="dark" type="submit" disabled={this.state.charCount > 400}>
                                Trimite
                            </Button>

                        </Form>

                    </Col>
                </Row>

            </Container >

        )
    }
}


export default NewAnswerForm