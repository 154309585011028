export const setLocalCustomerEmail = (email) => {
  localStorage.setItem('customer_email', email);
  }
  
export const removeLocalCustomerEmail = () => {
  localStorage.removeItem('customer_email');
}

export const isSetConnectSid = () => {
  return document.cookie.split(';').some((item) => item.trim().startsWith('connect.sid='));
};

export const trimSpaces = (string) => string?.trim().split(' ')[0]