import './CouponCard.css';

import { Col, Badge, ListGroup, ListGroupItem } from "react-bootstrap";
import { dateUnixToCustom } from "../../../utils/utilitaryFunctions";
// import classes from './CouponPage.css'


const CouponCard = ({coupon, loggedUser, addToCart, removeItem}) => {

    const { code, author, courses, startDate, endDate, percentageDiscount } = coupon

    // Calculate coupon status and days left
    const currentDate = new Date();
    const endDateTime = new Date(endDate);
    const daysLeft = Math.ceil((endDateTime - currentDate) / (1000 * 60 * 60 * 24));
    const status = daysLeft < 0 ? 'Expired' : 'Active';


    return(
        <Col md={6} xl={4}>
            <article className={`card ${status === 'Expired' ? 'bg-danger' : 'bg-success'}`}>
                <div className="card-body text-white">
                    <h5 className="card-title">Discount: <Badge bg="light" text="dark">{percentageDiscount}%</Badge></h5>
                    <p className="card-text">Cod Cupon: {code}</p>
                    <p className={`card-text ${status === 'Expired' ? 'bg-danger' : 'bg-success'}`}>Status: {status}</p>
                    <p className="card-text">Cursuri cuprinse în promoție:</p>
                    <ListGroup>
                        {courses.map(course => <ListGroupItem key={course._id}>{course.name}</ListGroupItem>)}
                    </ListGroup>
                    <p className="card-text">Cupon creat de: {author.username} pe data de {dateUnixToCustom(startDate).date} la ora {dateUnixToCustom(startDate).time}</p>
                    {
                        status === 'Active' 
                        ? 
                        <>
                            <p className="card-text">Zile rămase: {daysLeft}</p> 
                            <p className="card-text">Expiră la data de: {dateUnixToCustom(endDate).date}, ora {dateUnixToCustom(endDate).time}</p>
                        </>
                        : 
                        <>
                            <p className="card-text">Expirat de: {-daysLeft} zile</p>
                            <p className="card-text">A expirat la data de: {dateUnixToCustom(endDate).date}, ora {dateUnixToCustom(endDate).time}</p>
                        </>
                    }
                </div>
            </article>
        </Col>
    )

}

export default CouponCard