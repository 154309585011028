import { Component } from "react";
import BlogService from "../../../services/blog.services";
import Spinner from '../../shared/Spinner'

import { Row } from 'react-bootstrap'
import CommentCard from "./CommentCard";

class CommentsList extends Component{

    constructor(){
        super()
        this.state = {
            comments: undefined,

        }
        this.blogService = new BlogService()
    }

    loadComments = () => {
        // const {post_id} = this.props.match.query
        const {postId} = this.props
        this.blogService
            .getAllComments(postId)
            .then( res => this.setState({comments: res.data}))
            .catch( err => {   
                if(err.response){
                    this.props.handleAlert([err.response.data], 10000, 'warning', true)
                }   else{
                    this.props.handleAlert(['The server is down'], 10000, 'danger', true)
                }  
                })
    }

    componentDidMount = () => {
        this.loadComments()
    }

    componentDidUpdate = (prevProps, prevState) => prevProps.trigger !== this.props.trigger && this.loadComments()


    render(){

        const commentsList = !this.state.comments
            ?
            <Spinner size={200}/>
            :
            <>
            
            <hr></hr>
            <h4>Conversații:</h4>
            <Row>
                {this.state.comments?.map( elm => 
                    <CommentCard  loggedUser={this.props.loggedUser} handleAlert={this.props.handleAlert} fetchUser={this.props.fetchUser}
                    // refreshCommentsInList={this.refreshCommentsInList}
                    refreshCommentsList = {this.props.refreshCommentsList}
                        key = {elm._id} comment={{...elm}}/>)}
            </Row>
            
            </>

        return(
            <>
                {commentsList}
            </>
            
            
        )
    }
}

export default CommentsList