import { withRouter } from 'react-router-dom';
import { useState } from 'react'
import { Form, Button, Container, ListGroup, Row, Col } from 'react-bootstrap'
// import { Form, Button, Container, ListGroup, Row, Col, InputGroup } from 'react-bootstrap'
import ExamsService from '../../../services/exams.services'
// import UploadsService from '../../../services/uploads.service'
import CountySelect from './CountySelect'
// import DropDownArrow from './down-arrow-svgrepo-com.svg'
// import classes from './CourseForm.module.css'

import {allSubjects} from './subjects'

const ExamEditForm = (props) =>  {

    const [examInput, setExamInput] = useState({
        _id: props.exam._id || '',
        name: props.exam?.name || '',
        year: props.exam?.year || 0,
        examNumber: props.exam?.examNumber || 0,
        typeOfExam: props.exam?.typeOfExam || '',
        subject: props.exam?.subject || '',
        subtypeOfExam: props.exam?.subtypeOfExam || '',
        county: props.exam?.county || '',
        description: props.exam?.description || '',
        exercisesUrl: props.exam?.exercisesUrl || '',
        itemsUrl: props.exam?.itemsUrl || [],
        markingSchemeUrl: props.exam?.markingSchemeUrl || '',
        answerKeyUrl: props.exam?.answerKeyUrl || [],
        official: props.exam?.official || '',
        tags: props.exam?.tags || [],
        temporaryTag: props.exam?.temporaryTag || [],
        rating: props.exam?.rating || '',
        skillLevel: props.exam?.skillLevel || '',
        language: props.exam?.language || '',
        // author: props.author?._id,
        addTagEnabled: true,
    })

    const examsService = new ExamsService()
    // const uploadsService = new UploadsService()

    const handleInputChange = e => {
        const { name, value } = e.target

        // If the input name is part of itemsUrl
        if (name.startsWith('itemsUrl')) {
            // Get the subfield name (e.g., ro, ma, ge, etc.)
            const subFieldName = name.split('.')[1];
            
            // Update the itemsUrl field with the new value for the subfield
            setExamInput(prevExamInput => ({
                ...prevExamInput,
                itemsUrl: {
                    ...prevExamInput.itemsUrl,
                    [subFieldName]: value
                }
            }));
        } else if (name.startsWith('answerKeyUrl')) {
            // Get the subfield name (e.g., ro, ma, ge, etc.)
            const subFieldName = name.split('.')[1];
            
            // Update the answerKeyUrl field with the new value for the subfield
            setExamInput(prevExamInput => ({
                ...prevExamInput,
                answerKeyUrl: {
                    ...prevExamInput.answerKeyUrl,
                    [subFieldName]: value
                }
            }));
        } else{
        // If the input name is not part of itemsUrl, update normally
            setExamInput((prevExamInput) => {
                return { ...prevExamInput, [name]: value }
            } )
        }
    }

    const handleCheckboxChange = e => {
        const { name, checked } = e.target

        setExamInput((prevCourseInput) => {
            return { ...prevCourseInput, [name]: checked }
        } )
    }

    const addTag = (e) => {
        e.preventDefault()
        if( examInput.temporaryTag.trim() !== ''){
            const tempTags = [...examInput.tags, examInput.temporaryTag]
            setExamInput((prevCourseInput) => {
                return { ...prevCourseInput, tags: tempTags, temporaryTag: ''  }
            } )     
        }

    }


    const handleFormSubmit = e => {
        e.preventDefault()
        // console.log(examInput)

        examsService
            .editExam(examInput)
            .then(() => {
                // this.props.closeModal()
                // this.props.refreshCoasters()
                setExamInput({
                    _id: '',
                    name: '',
                    year: 0,
                    examNumber: 0,
                    typeOfExam: '',
                    subject: '',
                    subtypeOfExam: '',
                    county: '',
                    description: '',
                    exercisesUrl: '',
                    itemsUrl: [],
                    markingSchemeUrl: '',
                    answerKeyUrl: [],
                    official: '',
                    tags: [],
                    temporaryTag: [],
                    rating: '',
                    skillLevel: '',
                    language: '',
                    author: '',
                    addTagEnabled: true,
                })
                props.toggleEditExamForm()
                props.history.push('/exams/bacalaureat')
            })
            .catch(err => console.log(err))
    }

    // const handleFileUpload = e => {

    //     // this.setState({ loading: true })

    //     const uploadData = new FormData()
    //     uploadData.append('imageData', e.target.files[0])

    //     uploadsService
    //         .uploadImage(uploadData)
    //         .then(response => {
    //             setExamInput((prevCourseInput) => {
    //             return { ...prevCourseInput, image: response.data.cloudinary_url }
    //         } )
    //             // this.setState({
    //             //     loading: false,
    //             //     coaster: { ...this.state.coaster, imageUrl: response.data.cloudinary_url }
    //             // })
    //         })
    //         .catch(err => console.log(err))
    // }
      

        return (
            <Container>

                <Form onSubmit={handleFormSubmit}>

                    <Form.Group controlId="name">
                        <Form.Label>Nume examen</Form.Label>
                        <Form.Control type="text" value={examInput.name} onChange={handleInputChange} name="name" />
                    </Form.Group>

                    <Form.Group controlId="year">
                        <Form.Label>An</Form.Label>
                        <Form.Control type="number" value={examInput.year} onChange={handleInputChange} name="year" />
                    </Form.Group>

                    <Form.Group controlId="examNumber">
                        <Form.Label>Număr de ordine</Form.Label>
                        <Form.Control type="number" value={examInput.examNumber} onChange={handleInputChange} name="examNumber" />
                    </Form.Group>

                    <Form.Group controlId="desc">
                        <Form.Label>Descriere</Form.Label>
                        <Form.Control type="text" value={examInput.description} onChange={handleInputChange} name="description" />
                    </Form.Group>

                    <Form.Group controlId="typeOfExam">
                        <Form.Label>Tip de examen</Form.Label>
                        <Form.Control as="select" type="text" value={examInput.typeOfExam} onChange={handleInputChange} name="typeOfExam" custom="true">
                            <option value="">-- Selectează tipul cursului --</option>
                            <option value="bacalaureat">Bacalaureat</option>
                            <option value="evaluarenationala">Evaluare Națională</option>
                        </Form.Control>                     
                    </Form.Group>

                    <Form.Group controlId="subtypeOfExam">
                        <Form.Label>Tip de subiecte</Form.Label>
                        <Form.Control as="select" type="text" value={examInput.subtypeOfExam} onChange={handleInputChange} name="subtypeOfExam" custom="true">
                            <option value="">-- Selectează specializarea --</option>
                            <option value="model">Model</option>
                            <option value="simulare">Simulare</option>
                            <option value="speciala">Sesiune Specială</option>
                            <option value="speciala_rezerva">Rezerva Specială</option>
                            <option value="iunie">Sesiune Iunie</option>
                            <option value="iunie_rezerva">Rezerva Iunie</option>
                            <option value="august">Sesiune August</option>
                            <option value="august_rezerva">Rezerva August</option>
                        </Form.Control>                     
                    </Form.Group>

                    {/* <Form.Group controlId="major">
                        <Form.Label>Specializare</Form.Label>
                        <Form.Control as="select" type="text" value={examInput.major} onChange={handleInputChange} name="major" custom="true">
                            <option value="">-- Selectează specializarea --</option>
                            <option value="mateinfo">Mate-Info</option>
                            <option value="stiinte">Științe</option>
                            <option value="tehnologic">tehnologic</option>
                            <option value="pedagogic">pedagogic</option>
                        </Form.Control>                     
                    </Form.Group> */}

{/* <Form.Group controlId="subject">
    <Form.Label>Materia</Form.Label>
    <Form.Control as="select" type="text" value={examInput.subject} onChange={handleInputChange} name="subject" custom="true">
        <option value="">-- Selectează materia --</option>
        <option value="matematica">Matematică</option>
        <option value="romana">Română</option>
        <option value="biologie">Biologie</option>
        <option value="fizica">Fizică</option>
        <option value="informatica">Informatică</option>
        <option value="istorie">Istorie</option>
    </Form.Control>                     
</Form.Group> */}

<Form.Group controlId="subject">
    <Form.Label>Materia</Form.Label>
    <Form.Control as="select" type="text" value={examInput.subject} onChange={handleInputChange} name="subject" custom="true">
        <option value="">-- Selectează materia --</option>
        {allSubjects.map(subject => (
            <option key={subject} value={subject}>
                {subject}
            </option>
        ))}
    </Form.Control>                     
</Form.Group>

                    <Form.Group controlId="county">
                        <Form.Label>Județ</Form.Label>
                        <CountySelect 
                            value={examInput.county} 
                            onChange={handleInputChange} 
                        />
                    </Form.Group>

                    <Form.Group controlId="exercisesUrl">
                        <Form.Label>Subiecte</Form.Label>
                        <Form.Control type="text" value={examInput.exercisesUrl} onChange={handleInputChange} name="exercisesUrl" />
                    </Form.Group>


                {/* Object.entries(examInput.itemsUrl) is used to iterate over each key-value pair in the itemsUrl object. */}
                {/* Each pair is destructured into languageCode (the key) and value (the corresponding value). */}
                    {Object.entries(examInput.itemsUrl).map(([languageCode, value]) => (
                        <Form.Group key={`itemsUrl-${languageCode}`} controlId={`itemsUrl.${languageCode}`}>
                            <Form.Label>{languageCode.toUpperCase()}</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={value ? value : ''} 
                                onChange={handleInputChange} 
                                name={`itemsUrl.${languageCode}`} 
                            />
                        </Form.Group>
                    ))}

                    <Form.Group controlId="markingSchemeUrl">
                        <Form.Label>Barem</Form.Label>
                        <Form.Control type="text" value={examInput.markingSchemeUrl} onChange={handleInputChange} name="markingSchemeUrl" />
                    </Form.Group>

                    {Object.entries(examInput.answerKeyUrl).map(([languageCode, value]) => (
                        <Form.Group key={`answerKeyUrl-${languageCode}`} controlId={`answerKeyUrl.${languageCode}`}>
                            <Form.Label>{languageCode.toUpperCase()}</Form.Label>
                            <Form.Control 
                                type="text" 
                                value={value ? value : ''} 
                                onChange={handleInputChange} 
                                name={`answerKeyUrl.${languageCode}`} 
                            />
                        </Form.Group>
                    ))}

                    <Form.Group controlId="videoSolutionUrl">
                        <Form.Label>Video</Form.Label>
                        <Form.Control type="text" value={examInput.videoSolutionUrl} onChange={handleInputChange} name="videoSolutionUrl" />
                    </Form.Group>

                    <Form.Group controlId="official">
                        <Form.Check 
                            type="checkbox" 
                            label="Oficial" 
                            checked={examInput.official} 
                            onChange={handleCheckboxChange} 
                            name="official" 
                            // inline
                        />
                    </Form.Group>


                    {/* <Form.Group controlId="NewCourseFormType">
                        <Form.Label>Type</Form.Label>
                        <div className="input-group">
                            <Form.Control as="select" type="text" value={examInput.typeOfCourse} onChange={handleInputChange} name="typeOfCourse" custom="true">
                                <option value="">-- Select the course type --</option>
                                <option value="video">Video</option>
                                <option value="written">Written document - Pdf</option>
                                <option value="other">Other format</option>
                            </Form.Control>
                            <div className="input-group-append">
                                <div className="input-group-text" style={{height:'100'}}>
                                    <div className={classes.smallPicContainer}>
                                        <img className={classes.smallPic} src={DropDownArrow} alt="DropDownArrow"/>
                                    </div>
                                </div>
                            </div>
                        </div>                        
                    </Form.Group> */}

                    <Form.Group controlId="skillLevel">
                        <Form.Label>Skill Level</Form.Label>
                        <Form.Control as="select" type="text" value={examInput.skillLevel} onChange={handleInputChange} name="skillLevel" custom="true">
                            <option value="">-- Select the skill level --</option>
                            <option value="beginner">Începător</option>
                            <option value="intermediate">Mediu</option>
                            <option value="expert">Avansat</option>
                        </Form.Control>                     
                    </Form.Group>

                    <Form.Group controlId="language">
                        <Form.Label>Language</Form.Label>
                        <Form.Control as="select" type="text" value={examInput.language} onChange={handleInputChange} name="language" custom="true">
                            <option value="">-- Select the language --</option>
                            <option value="romanian">Română</option>
                            <option value="english">Engleză</option>
                            <option value="spanish">Spaniolă</option>
                        </Form.Control>                     
                    </Form.Group>

                    <Row style={{ alignItems: 'bottom' }}>
                        <Col xs={6} md={4}>
                            <Form.Group controlId="temporaryTag">
                                <Form.Label>Tags</Form.Label>
                                <Form.Control type="text" value={examInput.temporaryTag} onChange={handleInputChange} name="temporaryTag" />
                            </Form.Group>
                        </Col>
                        <Col xs={6} md={2}>
                            <Button style={{ marginTop: '2em', width: '100%' }} variant="dark" onClick={addTag} disabled = {!examInput.addTagEnabled}>
                                Add tag
                            </Button>
                        </Col>
                        <Col xs={12} md={6} style={{ marginTop: '1.8em'}}>
                            <ListGroup horizontal>
                                {(examInput.tags ?? []).map( (tag, index) => <ListGroup.Item key={index}>{tag}</ListGroup.Item>)}
                            </ListGroup>
                        </Col>
                    </Row>

                    {/* <Form.Group controlId="lng">
                        <Form.Label>Imagen (URL)</Form.Label>
                        <Form.Control type="text" value={examInput.image} onChange={handleInputChange} name="image" />
                    </Form.Group> */}

                    {/* <Form.Group controlId="lng">
                        <Form.Label>Image (file) </Form.Label>
                        <Form.Control type="file" onChange={handleFileUpload} />
                    </Form.Group> */}

                    <Button style={{ marginTop: '20px', width: '70%' }} variant="dark" type="submit">Edit Exam Document</Button>
                    <Button style={{ marginTop: '20px', width: '30%' }} variant="info" onClick={props.toggleEditExamForm}>Cancel</Button>

                </Form>

            </Container>
        )

}

export default withRouter(ExamEditForm)