import React from 'react';
import {Container, Row, Col} from 'react-bootstrap'
import { Link } from 'react-router-dom';
// import { removeLocalCustomerEmail } from './../../../utils/localStorageUtils';

const CheckoutSuccess = ({ fetchUser}) => {   

    return (
        <Container style={{ marginTop: '3em' }}>
            <Row>
                <Col md={{ span: 6, offset: 3}}>
                    <div className='checkout'>
                    <h1>Bine ai venit la curs!</h1>
                    <p>Momentan procesăm comanda și în curând vei primi un email de confirmare.</p>
                    <div>
                        <Link to="/courses" onClick={ () => fetchUser() } style={{ marginTop: '20px', width: '100%' }} className='btn btn-dark'>
                            Înapoi la cursuri
                        </Link>
                    </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default CheckoutSuccess;