import LectureCard from "./LectureCard";

const LecturesList = ({lectures, freeCourse, lecturesCount, loggedUser, hideNewLectureForm, renderList, currentVideo, setCurrentVideo, setCurrentLecture, refreshSectionsList}) => {
const sortedLectures = lectures.slice().sort((a, b) => a.lectureNumber - b.lectureNumber);

    return(
        // <h1>LecturesList</h1>
        <>
        {sortedLectures.map(lecture => <LectureCard lecture = {lecture}  loggedUser={loggedUser}  
            hideNewLectureForm={hideNewLectureForm} renderList={renderList} key={lecture._id}
            currentVideo={currentVideo} setCurrentVideo={setCurrentVideo}
            setCurrentLecture={setCurrentLecture}
            refreshSectionsList={refreshSectionsList}
            freeCourse ={freeCourse} lecturesCount={lecturesCount}/>)}
        
        </>
    )

}

export default LecturesList