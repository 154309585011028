import axios from 'axios' 
 
 class UsersService{

    constructor(){
        this.app = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL + '/users',
            // baseURL: 'https://matecumatei-server.herokuapp.com/api/users',
            // baseURL: 'http://localhost:5000/api/users',
            withCredentials: true
        })
    }

    // getAllUsers = () => this.app.get('/')
    getUser = userId => this.app.get(`/id/${userId}`)
    getUserByEmail = email => this.app.get(`/email/${email}`)
    // saveUser = (user_info) => this.app.post('/new', user_info)
    setUsersSpecialization = ( userId, stream, profile, major ) => this.app.put(`set-specialization`, {userId, stream, profile, major})
    editUser = (user, changePassword) => this.app.put(`/edit/${user._id}`, {user, changePassword})
    resetPassword = (user, token) => this.app.put(`/resetPassword/${user._id}`, {user, token})

    freeEnroll = ( receipt_email, courses_ids, courseIdCouponPairs ) => this.app.post('/enroll-free', {...{receipt_email, courses_ids, courseIdCouponPairs}})

 }

 export default UsersService