import axios from 'axios'

class CartService {

    constructor() {
        this.app = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL + '/cart',
            withCredentials: true
        })
    }

    
    getCartTotal = ( cart ) => this.app.post('/get-cart-total', { cart })
}

export default CartService