import {Container} from 'react-bootstrap'
import PostsList from "./PostsList"

const BlogPage = ({loggedUser, handleAlert}) => {

    // console.log(loggedUser.role)

    return(
        <Container>
            <PostsList loggedUser={loggedUser} handleAlert={handleAlert}/>
            <p style={{marginBottom: '3rem'}}></p>
        </Container>
    )
}

export default BlogPage