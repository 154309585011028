import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import Routes from './Routes';
import Navigation from './layout/Navigation/Navigation';
import Footer from './layout/Footer/Footer';
import AuthService from '../services/auth.services';
import Alert from './shared/Alert';
import MaintenanceAlert from './pages/Alerts/MaintenanceAlert';

import CookieGDRP from './pages/CookiesSettings/CookieInfoGDRP';

// import{isSetConnectSid} from './../utils/localStorageUtils'

class App extends Component {
  constructor() {
    super();
    // const cartFromStorage = { 
    //   cartItems: cartListFromStorage, 
    //   itemsCount: cartListFromStorage.length, 
    //   total: cartListFromStorage.reduce((acc, curr) => acc + curr.price, 0), 
    //   };    
    // const cartFromStorage = localStorage.getItem('cart')
    //   ? JSON.parse(localStorage.getItem('cart'))
    //   : { cartItems: [], itemsCount: 0, total: 0, couponCode: undefined };
    this.state = { 
      loggedUser: undefined,
      currentVideoUrl: 'https://player.vimeo.com/video/674014779?h=1f787b76e6&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      currentLecture: '',
      isMobileLandscape: false,
      isMobile: false,

      toast:{
        showAlert: false,
        alertText: [],
        displayTime: 0,
        color: 'warning'
      },
      // cart: cartFromStorage,
      cart:{
        cartItems:[],
        itemsCount: 0,
        total: 0,
        // couponCode: undefined,
      },
      // showGDRP: true,
      // showCookiesSettings: true,
      showGDRP: true,
      showMaintenanceAlert: true,
      isCourseDetailsDisplayed: false,
    };
    this.authService = new AuthService();
  }


  setIsCourseDetailsDisplayed = (isCourseDetailsDisplayed) => {
    this.setState({
      isCourseDetailsDisplayed: isCourseDetailsDisplayed
    });
  }

  storeUser = (loggedUser) => {
    this.setState({ loggedUser, showGDRP: loggedUser ? false : true });
  };

  fetchUser = () => {

    this.authService
      .isLoggedIn()
      .then((theLoggedUser) => {
        // TTIA This is a workaround to hide an unexpected error: 
        // at logout --> storeUser(undefined) but when componentDidMount kicks in, the isLoggedIn returns the previous user 
        // because req.session.destroyed did not succeed in removing the session from the db
        // if(!isSetConnectSid()){
        //   this.storeUser(undefined)
        //   return
        // }

        this.storeUser(theLoggedUser.data)
      })
      // .then( () => {
      //   const cartFromStorage = localStorage.getItem('cart') ? 
      //     JSON.parse(localStorage.getItem('cart')) : [];
      //   this.addItemsFromLocalStorageToCart(cartFromStorage)
      // })
      .catch(() => this.storeUser(undefined));
  };

  // all courses in the shopping list should persist when reloading the page
  // therefore are stored in the browser, via the localStorage, as an alternative to a db
  storeCartItemsToLocalStorage = (cart) => {
    // const cart = cartItems.length > 0 ? cartItems : [];
    localStorage.setItem('cart', JSON.stringify(cart));
  }

  addToCart = (course) => {
    const tempCartItems = [...this.state.cart.cartItems, course]
    const updatedCart = {
      ...this.state.cart,
      cartItems: tempCartItems,
      itemsCount: tempCartItems.length,
      total: tempCartItems.reduce((acc, curr) => acc + (( typeof curr?.discountedPrice === 'number' ) ? curr.discountedPrice : curr.price), 0),
      // total: tempCartItems.reduce((acc, curr) => acc + curr.price, 0),
    };
    this.storeCartItemsToLocalStorage(updatedCart)
    this.setState({ cart: updatedCart });
  }

  addItemsFromLocalStorageToCart = (tempCart) => {
    const updatedCart = {
      ...this.state.cart,
      cartItems: tempCart?.cartItems || [],
      itemsCount: tempCart?.cartItems?.length || 0,
      total: tempCart?.cartItems?.reduce((acc, curr) => acc + (( typeof curr?.discountedPrice === 'number' ) ? curr.discountedPrice : curr.price), 0) || 0,
      // total: tempCart?.cartItems?.reduce((acc, curr) => acc + curr.price, 0) || 0,
    };
    // TO_BE_TESTED tempCartItems come from localStorage, so there is no need to store them again, hence the nextline has been removed
    this.storeCartItemsToLocalStorage(updatedCart) 
    this.setState({ cart: updatedCart });
  }

  applyDiscount = (itemId, percentageDiscount, couponName) => {
    const cartFromStorage = localStorage.getItem('cart') ? 
      JSON.parse(localStorage.getItem('cart')) : {cartItems:[]}
    // const tempCartItems = [...this.state.cart.cartItems].map( el => {
      const tempCartItems = [...cartFromStorage.cartItems].map( el => {
      if(el._id === itemId){
        el.discountedPrice = el.price * (100 - percentageDiscount) / 100
        el.couponName = couponName
      }
      return el
    })
    const updatedCart = {
      ...this.state.cart,
      cartItems: tempCartItems,
      itemsCount: tempCartItems.length,
      // total: tempCartItems.reduce((acc, curr) => acc + ( curr.discountedPrice || curr.price ), 0),
      total: tempCartItems.reduce((acc, curr) => acc + (( typeof curr?.discountedPrice === 'number' ) ? curr.discountedPrice : curr.price), 0)

    };
    this.storeCartItemsToLocalStorage(updatedCart)
    this.setState({ cart: updatedCart });
  }

  removeItem = (id) => {
    const cartFromStorage = localStorage.getItem('cart') ? 
      JSON.parse(localStorage.getItem('cart')) : {cartItems:[]}
    const tempCartItems = [...cartFromStorage.cartItems].filter( el => el._id !== id)
    const updatedCart = {
      ...this.state.cart,
      cartItems: tempCartItems,
      itemsCount: tempCartItems.length,
      total: tempCartItems.reduce((acc, curr) => acc + ( 
        (curr.discountedPrice !== undefined && curr.discountedPrice !== null) ? curr.discountedPrice : curr.price
        ), 0),
      // total: tempCartItems.reduce((acc, curr) => acc + ( curr.discountedPrice || curr.price ), 0),
    };
    this.storeCartItemsToLocalStorage(updatedCart)
    this.setState({ cart: updatedCart });
  }

  removeAllItems = () => {
    const updatedCart = {
      ...this.state.cart,
      cartItems: [],
      itemsCount: 0,
      total: 0,
      // couponCode: undefined,
    };
    this.setState({ cart: updatedCart });
    localStorage.removeItem('cart')
    // this.storeCartItemsToLocalStorage([])
    this.fetchUser() // TTIA: probably used after purchasing a new course --> the user's CoursesList must be updated on client side
    // this.props.history.push('/courses');
  }

  // Handle Cookies
  // showCookiesSettings = () => {
  //   this.setState({ showCookiesSettings: true });
  // }  
  // hideCookiesSettings = () => {
  //   this.setState({ showCookiesSettings: false });
  // }
  hideGDRP = () => {
    this.setState({ showGDRP: false });
  }

  toggleMaintenanceAlert = () => {
    this.setState({ showMaintenanceAlert: !this.state.showMaintenanceAlert });
  }

  
  setCurrentVideoUrl = (currentVideoUrl) => {
    this.setState({ currentVideoUrl: currentVideoUrl });
  }
  
  setCurrentLecture = (currentLectureId) => {
    this.setState({ currentLecture: currentLectureId });
  }

  getDisplayCharacteristics = () => {
    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const isLandscape = window.matchMedia("(orientation: landscape)").matches;
    const isMobileLandscape = isMobile && isLandscape
    return {isMobile, isLandscape, isMobileLandscape};
  }
  
  handleDisplayOrientation = () => {
    const {isMobileLandscape} = this.getDisplayCharacteristics()
    this.setState({isMobileLandscape : !isMobileLandscape}) 
    console.log("Orientation change detected. isMobileLandscape:", !isMobileLandscape);
  }

  handleAlert = (alertText, displayTime = 3000, color='warning', showAlert = true) => {

    let formattedAlertText;
    // If alertText is a string, convert it to an array with a single element
    if (typeof alertText === 'string') {
      formattedAlertText = [alertText];
    }
    // If alertText is an array of strings, use it as is
    else if (Array.isArray(alertText) && alertText.every((item) => typeof item === 'string')) {
      formattedAlertText = alertText;
    }
    // If alertText is not a string or array of strings, use the default message
    else {
      formattedAlertText = ["A avut loc o eroare.", "Te rugăm să reîncerci mai târziu."];
    }

    this.setState({toast: {...this.state.toast, alertText: formattedAlertText, displayTime, color, showAlert }});
  };

  componentDidMount() {
    const {isMobile, isMobileLandscape} = this.getDisplayCharacteristics()
    // TTIA: if isMobile===true, we could set this.state.isMobileLandscape=false, given that at first the mobile experience starts verticaly
    this.setState({isMobileLandscape : isMobileLandscape, isMobile : isMobile}) 

    window.addEventListener('orientationchange', this.handleDisplayOrientation);

    this.fetchUser();

    const cartFromStorage = localStorage.getItem('cart') ? 
      JSON.parse(localStorage.getItem('cart')) : {cartItems:[]};
    this.addItemsFromLocalStorageToCart(cartFromStorage)

    // window.scrollTo(0, 0)
  }  

  // TTIA might cause problems in Mozilla
  componentWillUnmount() {
    window.removeEventListener('orientationchange', this.handleDisplayOrientation);
  }
  
  render() {
    // const cartListFromStorage = localStorage.getItem('cart')
    // ? JSON.parse(localStorage.getItem('cart'))
    // : []
    const cartFromStorage = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')) : {cartItems:[]}

    return (
      <>
        {
        this.state.isCourseDetailsDisplayed && this.state.isMobileLandscape 
        ?
        null
        :
        <Navigation 
          handleAlert={this.handleAlert}
          storeUser={this.storeUser}
          loggedUser={this.state.loggedUser}
          itemsCount = {this.state.cart.itemsCount}
          fetchUser={this.fetchUser} //needed in the handleMenuClick route to update the courses list in the loggedUser - TEST
          showMaintenanceAlert={this.state.showMaintenanceAlert}
          toggleMaintenanceAlert={this.toggleMaintenanceAlert}
        />
        }

        <div className={this.state.isMobileLandscape ? '' : 'routesContainer'} >

        <MaintenanceAlert 
          showMaintenanceAlert={this.state.showMaintenanceAlert}
          toggleMaintenanceAlert={this.toggleMaintenanceAlert}        
          parentComponent={`App`}
        />
        
        {/* <div style={{paddingTop:'3.5em', paddingBottom:'0', marginBottom:'0', minHeight:'100vh'}} > */}
          <Routes
            handleAlert={this.handleAlert}
            storeUser={this.storeUser}
            loggedUser={this.state.loggedUser}
            addToCart={this.addToCart}
            removeItem={this.removeItem}
            removeAllItems={this.removeAllItems}
            cart={this.state.cart}
            cartFromStorage={cartFromStorage}
            fetchUser={this.fetchUser} //needed in the /success route to update the courses list in the loggedUser
            currentVideoUrl={this.state.currentVideoUrl}
            currentLecture={this.state.currentLecture}
            setCurrentVideoUrl={this.setCurrentVideoUrl}
            setCurrentLecture={this.setCurrentLecture}
            isMobileLandscape={this.state.isMobileLandscape}
            isMobile={this.state.isMobile}
            setIsCourseDetailsDisplayed={this.setIsCourseDetailsDisplayed}
            applyDiscount={this.applyDiscount}
          />          
        </div>

        {
        this.state.isCourseDetailsDisplayed && this.state.isMobileLandscape 
        ?
        null
        :
        <Footer getDisplayCharacteristics={this.getDisplayCharacteristics} 
          footerWidth={this.state.isCourseDetailsDisplayed && !this.state.isMobile ? 8 : 12}/>
        }

        <Alert
          handleAlert={this.handleAlert}
          show={this.state.toast.showAlert}
          text={this.state.toast.alertText}
          displayTime={this.state.toast.displayTime}
          color={this.state.toast.color}
        />

        <div>
          <CookieGDRP show={this.state.showGDRP} onHide={this.hideGDRP} />
        </div>


        {/* <CookieConsentComponent show={this.state.showGDRP} showCookiesSettings={this.showCookiesSettings}/>
        
        <CookieSettingsModal show={this.state.showCookiesSettings} onHide={this.hideCookiesSettings} /> */}
         
        {/* <CookieGDRP show={this.state.showGDRP} onHide={this.hideGDRP} /> */}

       
      </>
    );
  }
}

export default App;
