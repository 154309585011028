import axios from 'axios'

class AuthService {

    constructor() {
        this.app = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL,
            // baseURL: 'https://matecumatei-server.herokuapp.com/api/',
            // baseURL: 'http://localhost:5000/api/',
            withCredentials: true
        })
    }

    login = (email, pwd) => this.app.post('/login', { email, pwd } )
    // login = (email, pwd, reCaptchaToken, reCaptchaType) => this.app.post('/login', { email, pwd, reCaptchaToken, reCaptchaType } )
    signup = (email, pwd, pwd2, name, surname, username, legalAcknowledgment, reCaptchaToken, reCaptchaType) => this.app.post('/signup', { email, pwd, pwd2, name, surname, username, legalAcknowledgment, reCaptchaToken, reCaptchaType } )
    logout = () => this.app.get('/logout')
    isLoggedIn = () => this.app.post('/isLoggedIn' )
    
    generateToken = (email) => this.app.post('/generate-token', {email}) //this could be refactored as a get request
}

export default AuthService