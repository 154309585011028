import axios from 'axios' 
 
 class CouponsService{

    constructor(){
        this.app = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL + '/coupons',
            // baseURL: 'https://matecumatei-server.herokuapp.com/api/courses',
            // baseURL: 'http://localhost:5000/api/courses',
            withCredentials: true
        })
    }

    saveCoupon = (coupon_info) => this.app.post('/new', coupon_info)
    getDiscount = (couponName, courseId) => this.app.get(`/discount/${couponName}/${courseId}`)
    getAllCoupons = () => this.app.get('/')
 }

 export default CouponsService