const loadRecaptchaScript = (siteKey, callback2) => {
    if (window.grecaptcha) {
        // execute the callback function
        callback2();
    } else {
        // load the script and execute the callback2 function after it will have loaded
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`;
        script.async = true;
        script.onload = callback2;
        document.head.appendChild(script);
    }
};

const executeRecaptcha = async (siteKey, action, setStateCallback) => {
    let retries = 0;
    const maxRetries = 10; // Maximum number of retries
    const retryInterval = 1000; // Retry interval in milliseconds

    const tryExecuteRecaptcha = () => {
        if (window.grecaptcha) {
            window.grecaptcha.ready(async () => {
                try {
                    const token = await window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_INVISIBLE_V3_SITE_KEY, { action });
                    // const token = await window.grecaptcha.execute((siteKey ? siteKey : process.env.REACT_APP_GOOGLE_RECAPTCHA_INVISIBLE_V3_SITE_KEY), { action });
                    setStateCallback(true, token); // Pass both submitEnabled flag and token to the setStateCallback
                } catch (error) {
                    // const token = await window.grecaptcha.execute(process.env.REACT_APP_GOOGLE_RECAPTCHA_INVISIBLE_V3_SITE_KEY, { action });
                    // setStateCallback(true, token);
                    console.error('Error executing reCAPTCHA:', error);
                    setStateCallback(false, null);
                }
            });
        } else {
            if (retries < maxRetries) {
                retries++;
                setTimeout(tryExecuteRecaptcha, retryInterval);
            } else {
                // Maximum retries reached, ReCaptcha script couldn't be loaded
                console.error('Failed to load ReCaptcha script.');
                setStateCallback(false, null); // Pass submitEnabled flag as false and token as null to the setStateCallback
            }
        }
    };

    loadRecaptchaScript(siteKey, tryExecuteRecaptcha);
};


export default executeRecaptcha;
