import React from 'react'

import classes from './SectionPage.module.css'
import DropDownArrow from './down-arrow-svgrepo-com.svg'
import FallUpArrow from './up-arrow-svgrepo-com.svg'
import LightDropDownArrow from './light-down-arrow-svgrepo-com.svg'
import LightFallUpArrow from './light-up-arrow-svgrepo-com.svg'


const DropDownListArrow = props => {

    const {showLectures, toggleShowLectures, light} = props
 

    return(
        <div className={classes.block}>            
            <div className={classes.smallPicContainer} onClick={toggleShowLectures}>
                {
                    light
                    ?
                        <img className={classes.smallPic} src={showLectures ? LightFallUpArrow : LightDropDownArrow} alt="DropDownArrow"/>
                    :
                        <img className={classes.smallPic} src={showLectures ? FallUpArrow : DropDownArrow} alt="DropDownArrow"/>
                }
            </div>
        </div>
    )
}

export default DropDownListArrow