// In order to display an alert message:
// 1. set REACT_APP_MAINTENANCE_SHOW_ALERT=true
// 2. choose the title and message from the template maintenanceMessages file
// 3. adjust the startDate and endDate in the template maintenanceMessages file if needed

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

import maintenanceMessages from '../../shared/TechnicalMessages/maintenanceMessages';

function MaintenanceAlert({showMaintenanceAlert, toggleMaintenanceAlert, title, message, parentComponent}) {

  return (
            
    ( process.env.REACT_APP_MAINTENANCE_SHOW_ALERT.toLowerCase() !== 'false' && process.env.REACT_APP_MAINTENANCE_SHOW_ALERT !== false )
    // process.env.REACT_APP_MAINTENANCE_SHOW_ALERT
    ?
    (
        parentComponent === 'App'
    ? 
    <>
      <Alert show={showMaintenanceAlert} variant="success" className='mb-0'>
        <Alert.Heading>{maintenanceMessages.generalMaintenance.title}</Alert.Heading>
        <p>
          {maintenanceMessages.generalMaintenance.message}
        </p>
        <hr />
        <div className="d-flex justify-content-end">
          <Button onClick={toggleMaintenanceAlert} variant="outline-success">
            Închide anunțul.
          </Button>
        </div>
      </Alert>
    </>
    :
    <>
    {!showMaintenanceAlert && <Button onClick={toggleMaintenanceAlert}  variant="light">Arată anunțul!</Button>}
    </>
    )
    :
    <></>
  );
}

export default MaintenanceAlert;