// PaymentFormComponent.js

import React, { useState, useEffect }  from 'react';
import { Container, Button, Row, Col } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import { CardNumberElement, CardExpiryElement, CardCvcElement, useElements } from '@stripe/react-stripe-js';
import { setLocalCustomerEmail } from '../../../utils/localStorageUtils';
import StripeService from '../../../services/stripe.services';
import CartService from '../../../services/cart.services';

const CustomCheckoutPaymentForm = ( {cart, cartFromStorage, handleAlert, loggedUser, stripePromise, removeAllItems} ) => {
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const [cartItems, setCartItems] = useState(null);
    // const [cards, setCards] = useState(null);
    // const [payment, setPaymentCard] = useState('');
    // const [saveCard, setSavedCard] = useState(false);
    // const [paymentIntentId, setPaymentIntentId] = useState(null);
    // const stripe = useStripe();
    const elements = useElements();

    const stripeService = new StripeService()
    const cartService = new CartService(); 
    const history = useHistory()

    useEffect(() => {
        const items = cart.cartItems?.map(item => ({price: ( item.discountedPrice || item.discountedPrice===0 ) ? item.discountedPrice : item.price, quantity: 1, name: item.name, id: item._id}))
        setCartItems(items)

        const receipt_email = localStorage.getItem('customer_email')

        // console.log(items)

        stripeService
            .customStripeCheckout( receipt_email, items, 'Payment intent for MateCuMatei' )
            .then( res => {
                const {clientSecret} = res.data
                setClientSecret( clientSecret )
            })
            .catch( err => {
                console.log(err)
            })
    
      }, [cart]); // TTIA: it should depend on processing as well?

    const cardHandleChange = event => {
      const { error } = event;
      setError(error ? error.message: '');
    }
    

    // const handleCheckout = () => {
    //     handleCustomStripeCheckout()
    //     // if( !this.state.customer_email ){   
    //     //     this.setState({
    //     //         isShownSignUpModal: !this.state.customer_email
    //     //     })
    //     // }else{
    //     //     this.handleDefaultStripeCheckout()
    //     // }

    // }

    //Consult the bottom of the page for the previous handleCustomStripeCheckout version
    const handleCustomStripeCheckout = () => {
        const cartTotal = cartItems.reduce((total, item) => total + item.price, 0); // Calculate the total from cart items
        cartService.getCartTotal(cart) // Use AuthService to get the cart total
            .then(response => {
                const cartTotalFromServer = response.data.cartTotal;
                if (cartTotal === cartTotalFromServer) { // Compare the cart total with the total from the service
                    const cardNumberElement = elements.getElement(CardNumberElement)
                    setProcessing('true')

                    stripePromise
                        .then(stripe => {
                            stripe.confirmCardPayment(clientSecret, {
                                payment_method: {
                                    card: cardNumberElement
                                }
                            })
                                .then(res => {
                                    if (res.error) {
                                        handleAlert(['Confirmarea plății a eșuat în confirmCardPayment.'], 5000, 'danger');
                                        setError(`Payment failed: ${error.message}`);
                                    } else {
                                        history.push('/success')
                                        removeAllItems()
                                        setLocalCustomerEmail('')
                                        handleAlert([`Successful payment.`], 5000, 'info')
                                    }
                                })
                                .catch(err => {
                                    handleAlert(['Confirmarea plății a eșuat în confirmCardPayment.'], 5000, 'danger');
                                    setError(`Payment failed: ${error?.message}`);
                                })

                        })
                        .catch(err => {
                            handleAlert([`Plata a eșuat.`], 5000, 'danger')
                            setError(`Payment failed: ${err.message}`)
                        })
                } else {
                    handleAlert(['Am detectat o eroare pentru cupoanele folosite. Procesarea comenzii a eșuat. Te rugăm să încerci din nou.'], 5000, 'danger');
                }
            })
            .catch(err => {
                console.error(err);
                handleAlert(['Am întâmpinat dificultăți în aplicarea cupoanele folosite. Procesarea comenzii a eșuat. Te rugăm să încerci din nou.'], 5000, 'danger');
            });
    }


    const cardStyle = {
      style: {
        base: {
          color: "#000",
          fontFamily: 'Roboto, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#606060",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      }
    };
  return (

    <Container>
        <Row style={{ marginTop: '3em' }}>
            <Col md={{ span: 4, offset: 4 }}>
                <h4>Enter Payment Details</h4>

                <div className='stripe-card'>
                    <label className='form-label'>Număr card</label>
                    <CardNumberElement 
                        className='form-control'
                        options={cardStyle}
                        onChange={cardHandleChange}
                    />
                </div>
                <div className='stripe-card'>
                    <label className='form-label'>Data de expirare</label>
                    <CardExpiryElement 
                        className='form-control'
                        options={cardStyle}
                        onChange={cardHandleChange}
                    />
                </div>
                <div className='stripe-card'>
                    <label className='form-label'>CVC</label>
                    <CardCvcElement 
                        className='form-control'
                        options={cardStyle}
                        onChange={cardHandleChange}
                    />
                </div>      

                <Button disabled={!!processing}  style={{ marginTop: '20px', width: '100%' }} variant={ processing ? "dark" : "secondary" } 
                    onClick={() => handleCustomStripeCheckout()} >
                    { processing ? 'PROCESSING' : 'PAY' }
                </Button>
                
                {/* <div className='submit-btn'>
                    <button disabled={processing} className='button is-black nomad-btn submit' onClick={() => handleCheckout()} >
                        { processing ? 'PROCESSING' : 'PAY' }
                    </button>
                </div> */}


                {
                error && (<p className='error-message'>{error}</p>)
                }

            </Col>
        </Row>
    </Container >
  );
};

export default CustomCheckoutPaymentForm;







    // The previous handleCustomStripeCheckout version, kept for emergencies only
    // DELETE after 2024.09.01
    // const handleCustomStripeCheckout = () => {
    //     // e.preventDefault()       

    //     const cardNumberElement = elements.getElement(CardNumberElement)
    //     setProcessing('true')
    //     // console.log(`************@clientSecret@**************: ${clientSecret}` )
    //     stripePromise
    //         .then(stripe => {

    //             stripe.confirmCardPayment(clientSecret,{
    //                 payment_method: {
    //                     card: cardNumberElement
    //                     // card: elements.getElement(CardNumberElement)
    //                 }
    //             })
    //             .then( res => {
    //                 if(res.error){                        
    //                     handleAlert(['Confirmarea plății a eșuat în confirmCardPayment.'], 5000, 'danger');
    //                     setError(`Payment failed: ${error.message}`);
    //                 }else{
    //                     history.push('/success')
    //                     removeAllItems()
    //                     setLocalCustomerEmail('')
    //                     // history.push('/success')
    //                     handleAlert([`Successful payment.`], 5000, 'info')
    //                     // removeAllItems()
    //                 }
    //             })
    //             .catch( err => {
    //                 handleAlert(['Confirmarea plății a eșuat în confirmCardPayment.'], 5000, 'danger');
    //                 setError(`Payment failed: ${error?.message}`);
    //             })

    //         })
    //         .catch( err => {
    //             handleAlert([`Plata a eșuat.`], 5000, 'danger')
    //             setError(`Payment failed: ${err.message}`)
    //         })
    // }
  
