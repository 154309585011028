import {Container} from 'react-bootstrap'
// import PostsList from "./PostsList"
import QuestionsAndAnswersList from './QuestionsAndAnswersList'

const QuestionsAndAnswersPage = ({loggedUser, handleAlert, currentVideoUrl, currentLecture, fetchUser}) => {

    // console.log(loggedUser.role)

    return(
        <Container>
            {/* <PostsList loggedUser={loggedUser} handleAlert={handleAlert}/> */}
            <QuestionsAndAnswersList  
                loggedUser={loggedUser}
                currentVideoUrl={currentVideoUrl}
                currentLecture={currentLecture}
                handleAlert={handleAlert} 
                fetchUser={fetchUser}>
            </QuestionsAndAnswersList>
        </Container>
    )
}

export default QuestionsAndAnswersPage