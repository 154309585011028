import React from 'react';
import { Form } from 'react-bootstrap';

const CountySelect = ({ value, onChange }) => {
  const counties = [
    "Alba", "Arad", "Argeș", "Bacău", "Bihor", "Bistrița-Năsăud", "Botoșani",
    "Brașov", "Brăila", "Caraș-Severin", "Călărași", "Cluj", "Constanța", 
    "Covasna", "Dâmbovița", "Dolj", "Galați", "Giurgiu", "Gorj", "Harghita",
    "Hunedoara", "Ialomița", "Iași", "Ilfov", "Maramureș", "Mehedinți", 
    "Mureș", "Neamț", "Olt", "Prahova", "Satu Mare", "Sălaj", "Sibiu", 
    "Suceava", "Teleorman", "Timiș", "Tulcea", "Vaslui", "Vâlcea", "Vrancea"
  ];

  return (
    <Form.Control 
      as="select"
      value={value}
      onChange={onChange}
      name="county"
      custom="true"
    >
      <option value="">-- Selectează județul --</option>
      <option value="National">Național</option>
      {counties.map((county, index) => (
        <option key={index} value={county}>{county}</option>
      ))}
    </Form.Control>
  );
};

export default CountySelect;
