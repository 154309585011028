import { Component } from "react";
import QuestionsAndAnswersService from "../../../services/questionsAndAnswersService.services";
import Spinner from "../../shared/Spinner";

import { Row } from 'react-bootstrap'
import QuestionCard from "./QuestionCard";
import NewQuestionForm from "./NewQuestionForm";

class QuestionsAndAnswersList extends Component{

    constructor(){
        super()
        this.state = {
            questions: [],
        }
        this.questionsAndAnswersService = new QuestionsAndAnswersService()
    }

    loadQuestions = () => {
        let {currentLecture} = this.props
        // console.log(`Heyyyyyyyyyyyyy ${currentLecture}`)
        currentLecture = currentLecture ? currentLecture : 'notAvailable'
        // const currentLecture = this.props.currentLecture ? this.props.currentLecture : ''
        // console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%', currentLecture)
        
        this.questionsAndAnswersService
            // .getQuestionsByLecture('6415f75a7a5dd7915aba074c')
            .getQuestionsByLecture(currentLecture)
            // .then( res => this.setState({questions: res.data}))
            .then( res => {
                // console.log(res.data)
                this.setState({questions: res.data})
            })
            .catch( err => {   
                // console.log(err)
                if(err.response){
                    this.props.handleAlert(err.response.data.message, 10000, 'warning', true)
                }   else{
                    this.props.handleAlert(['3The server is down'], 10000, 'danger', true)
                }  
                })
    }

    componentDidMount = () => {
        this.loadQuestions()
    }

    componentDidUpdate = (prevProps, prevState) => prevProps.currentLecture !== this.props.currentLecture && this.loadQuestions()


    render(){

        const questionsList = !this.state.questions
            ?
            <Spinner size={200}/>
            :
            <>
            
            <hr></hr>
            <h4>Întrebări:</h4>
            <Row>
                {this.state.questions?.map( elm => 
                    <QuestionCard  loggedUser={this.props.loggedUser} handleAlert={this.props.handleAlert} fetchUser={this.props.fetchUser}
                    refreshQuestionsList={this.loadQuestions}
                        key = {elm._id} question ={{...elm}} />)}
                        
                    
                <NewQuestionForm lectureId={this.props.currentLecture} maxNumberOfChars={500} maxNumberOfCharsInTitle={50} loggedUser={this.props.loggedUser} 
                    handleAlert={this.props.handleAlert} refreshQuestionsList={this.loadQuestions}/>
            </Row>
            
            </>

        return(
            <>
                {questionsList}
            </>
            
            
        )
    }
}

export default QuestionsAndAnswersList