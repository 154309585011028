import './CoursePage.css';

import { Col, Row, Button} from "react-bootstrap";
import {Link} from 'react-router-dom'
// import classes from './CoursePage.css'


const CourseCard = ({course, loggedUser, addToCart, removeItem}) => {

    const { name, image, about, _id, skillLevel, lecturesNumber, udemyCourseName, price}=course
    // TTIA display price on the Course Card
    // const { name, image, price, discountedPrice, coupons, about, _id, skillLevel, lecturesNumber, udemyCourseName}=course
    const shortDescription = about.slice(0, 70)

    // check if the current course is in the shopping list / TEMP
    const isCartVisible =  process.env.REACT_APP_IS_CART_VISIBLE==="true"
        ||
        loggedUser?.role === 'admin'

    const isInCart = isCartVisible && JSON.parse(localStorage.getItem('cart'))?.cartItems?.find( el => el._id === _id)
    // const isInCart = process.env.REACT_APP_IS_CART_VISIBLE && 
    //                 JSON.parse(localStorage.getItem('cart'))?.cartItems..find( el => el._id === _id)
    const isInrolledToThisCourse = loggedUser?.courses.includes(_id)

    return(
        <Col md={6} xl={4}>
            <article className="courseCard">
                <Link to={`/courses/details/${_id}`} className='courseCardLink'>
                    <img src={image} alt={name}/>
                    <h4>{name}</h4>
                    <p>Skill level: {skillLevel} | Lectures: {lecturesNumber}</p>
                    <p> Price: {price} Ron</p>
                    {/* <p>Lectures: {lecturesNumber}</p> */}
                    {/* <p>Total video duration: {totalTimeInSeconds} ore</p> */}
                    <p>{shortDescription}...</p>
                    {/* <Button className="btnBlock mb-2" variant="info">Detalii</Button> */}
                </Link>

            {
                isInrolledToThisCourse
                ?     
                <Link to={`/courses/details/${_id}`}>  
                    <Button className="btnBlock mb-2" variant="success">Mergi la curs</Button>
                </Link> 
                :   
                <Row className="justify-content-between">
                    <Col  xs={12} md={isCartVisible ? 6 : 12}>
                    {/* <Col md={loggedUser ? 7 : 12}> */}
                        <Link to={`/courses/details/${_id}`}>  
                            <Button className="btnBlock mb-2" variant="info">Detalii</Button>
                        </Link> 
                    </Col> 
                    {
                    // process.env.REACT_APP_IS_CART_VISIBLE
                    isCartVisible
                    &&
                    /* ADD TO CART / REMOVE FROM CART / TEMP*/
                    <Col xs={12} md={6}>
                        {
                            isInCart
                            ?
                            <Button className='btnBlock mb-2 removeButton' onClick={() => removeItem(_id)}>Elimină din coș</Button>
                            :
                            <Button className="btnBlock mb-2" variant="secondary" onClick={() => addToCart(course)}>Adaugă în coș</Button>
                            // <Button className="btnBlock mb-2" variant="secondary" onClick={() => addToCart(course)}>Price: {price} Ron</Button>
                        }    
                    </Col>  
                    }            
                </Row>         
            }

            <Row>
                <Col xs={12} md={6}>
                    {/* STANDARD GO TO UDEMY BUTTON */}
                    <a
                        href={`https://www.udemy.com/course/${udemyCourseName}/?couponCode=${process.env.REACT_APP_UDEMY_COUPON}`}
                        className="btn btnBlock mb-2"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ backgroundColor: '#a435f0', color: 'white', borderColor: '#a435f0' }}
                        >
                        Oferta Udemy
                    </a>
                
                </Col>
                <Col xs={12} md={6}>
                    {/* STANDARD GO TO UDEMY BUTTON */}
                    <a
                        href={`https://academia.matecumatei.ro/`}
                        className="btn btnBlock mb-2"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ backgroundColor: process.env.REACT_APP_YELLOW_MCM, color: 'white', borderColor: '#a435f0' }}
                        >
                        Academia MCM
                    </a>
                
                </Col>                
            </Row>

{/* 1 DECEMBRIE BUTTON */}
{/* <a
    href={`https://www.udemy.com/course/${udemyCourseName}/?couponCode=${process.env.REACT_APP_UDEMY_COUPON}`}
    className="btn btnBlock mb-2 custom-button"
    target="_blank"
    rel="noopener noreferrer"
    style={{ padding: '0' }}
>
    <div className="color-section" style={{ backgroundColor: '#002780' }}></div>
    <div className="color-section" style={{ backgroundColor: '#ffcc29' }}></div>
    <div className="color-section" style={{ backgroundColor: '#d7142c' }}>
        <span className="button-text">Oferta Udemy</span>
    </div>
</a> */}



                {
                // TTIA the access to the edit button should be granted to the author as well
                loggedUser && loggedUser.role === 'admin'
                ?
                <Link to={`/courses/edit/${_id}/false`}>
                    <Button className="btnBlock" variant="warning">Edit</Button>
                </Link>
                :
                null
                }
            </article>
        </Col>
    )

}

export default CourseCard