import React, { Component } from "react";
import { Tab, Tabs } from 'react-bootstrap'; // Make sure to import any required components

// import Hero from "../../layout/Hero/Hero";
// import Quote from "../../shared/Quote/Quote";
// import UserCard from "../UserCard/UserCard";

import './ExamsPage.css'
import ExamsListByTypeSubject from "./ExamsListByTypeSubject";
import ExamNewFormInput from "./ExamNewFormInput";

import UsersService from "../../../services/users.services";

import {subjectsByMajor as subjects} from './subjects'
import {translateDbValues} from './../../../utils/utilitaryFunctions.js'

class ExamsPage extends Component {
    constructor(){
        super()
        this.state = {
            userSpecialization: undefined,
            selectedSubjects: undefined
        }
        this.usersService = new UsersService()
    }

    loadState = () => {

        const {loggedUserSpecialization} = this.props 
        const {typeOfExam} = this.props.match.params
        let selectedSubjects = []
        const userSpecializationFromLocalStorage = localStorage?.userSpecialization ? JSON.parse(localStorage.userSpecialization) : undefined
        const userSpecialization = userSpecializationFromLocalStorage || loggedUserSpecialization || {
            stream: 'teoretica',
            profile: 'real',
            major: 'matematica_informatica',
            testing: 'loadState'
        }

        !localStorage?.userSpecialization && this.setUserSpecializationInLocalStorage(userSpecialization)

        if (typeOfExam === 'bacalaureat' && userSpecialization.stream && userSpecialization.profile && userSpecialization.major) {
            selectedSubjects = subjects[userSpecialization.stream][userSpecialization.profile][userSpecialization.major];
        }
        // else if( typeOfExam === 'evaluare-nationala'){
        //     selectedSubjects = ['romana', 'matematica']
        // }

        this.setState({userSpecialization, selectedSubjects})
        
    }

    componentDidMount(){
        this.loadState()
    }

    // componentDidUpdate = (prevProps, prevState) => (prevProps.match.params.typeOfExam !== this.props.match.params.typeOfExam) && this.loadState()
    componentDidUpdate = (prevProps, prevState) => {
        if( prevProps.match.params.typeOfExam !== this.props.match.params.typeOfExam ){
            this.loadState()
            // return
        }else if( prevState?.userSpecialization && this.state?.userSpecialization?.major 
                && prevState.userSpecialization.major !== this.state.userSpecialization.major ){
            this.loadState();
            // this.setState({ loading: true }, () => {
            //     this.loadState();
            //     this.setState({ loading: false });
            // });
        }
    }

    updateStateFromLocal = () => {        
        this.setState({
            userSpecialization: JSON.parse(localStorage.userSpecialization)
        })
    }

    setSelectedStream = (stream) => {
        this.setState({
            userSpecialization: 
                {
                stream: stream,
                profile: '',
                major: ''
                }
            })
        this.updateUserSpecializationInLocalStorage('stream', stream)
        this.updateUserSpecializationInLocalStorage('profile', '')
        this.updateUserSpecializationInLocalStorage('major', '')
    }

    setSelectedProfile = (profile) => {
        // this.setState({
        //     userSpecialization: 
        //         {
        //             ...this.state.userSpecialization,
        //             profile: profile,
        //             major: ''
        //         }
        //     })
        this.updateUserSpecializationInLocalStorage('profile', profile)
        this.updateUserSpecializationInLocalStorage('major', '')
        this.updateStateFromLocal()
    }

    setSelectedMajor = (major) => {
        this.setState({
            userSpecialization: 
                {
                    ...this.state.userSpecialization,
                    major: major
                }
            })
        this.updateUserSpecializationInLocalStorage('major', major)
    }

    updateUserSpecializationInLocalStorage = (property, propertyValue) => {
        const userSpecializationFromLocalStorage = this.getUserSpecializationFromLocalStorage()
        // const userSpecializationFromLocalStorage = JSON.parse( localStorage.getItem('userSpecialization') )
        userSpecializationFromLocalStorage[property] = propertyValue
        localStorage.setItem( 'userSpecialization', JSON.stringify( userSpecializationFromLocalStorage ) )
    }

    getUserSpecializationFromLocalStorage = () => localStorage?.userSpecialization ? JSON.parse(localStorage.userSpecialization) : {}

    setUserSpecializationInLocalStorage = ( userSpecialization ) => {
        localStorage.setItem( 'userSpecialization', JSON.stringify( userSpecialization ) )
    }
      
    storeUsersSpecialization = () => {        
        const {loggedUserId} = this.props 
        const userSpecialization = this.getUserSpecializationFromLocalStorage()
        this.usersService
            .setUsersSpecialization(loggedUserId, userSpecialization.stream, userSpecialization.profile, userSpecialization.major)
            .then(updatedUser => this.props.handleAlert([`Preferința a fost actualizata cu succes.`,
                `Filieră: ${translateDbValues(updatedUser.data.specialization.stream)}`,
                `Profil: ${translateDbValues(updatedUser.data.specialization.profile)}`,
                `Specializare: ${translateDbValues(updatedUser.data.specialization.major)}`], 10000, 'success') )
            .catch( err => this.props.handleAlert(err.response.data.message, 10000, 'warning', true))
        this.props.fetchUser()
    }

    getUsersSpecialization = () => {
        const initialUserSpecialization = this.props.loggedUserSpecialization || {
            stream: 'teoretica',
            profile: 'real',
            major: 'matematica_informatica',
            testing: 'getUsersSpecialization'
        }
        this.setUserSpecializationInLocalStorage(initialUserSpecialization)
        this.setState({
            userSpecialization: initialUserSpecialization
            })

    }

    render(){
        // const {typeOfExam} = this.props.match.params
        const {loggedUserId, loggedUserSpecialization} = this.props


        return(
            <>
            {
                this.state.userSpecialization && this.props.match.params.typeOfExam === 'bacalaureat'
                ?    
                <>
                <div className="row align-items-center mb-2">
                    <div className="col-md-10 mb-2">
                        <ExamNewFormInput setSelectedStream={this.setSelectedStream} selectedStream={this.state.userSpecialization.stream}
                            setSelectedProfile={this.setSelectedProfile} selectedProfile={this.state.userSpecialization.profile} 
                            setSelectedMajor={this.setSelectedMajor}  selectedMajor={this.state.userSpecialization.major}/>
                    </div>
                    {
                    loggedUserId && JSON.stringify( loggedUserSpecialization ) !== localStorage.userSpecialization ? 
                    <>
                        {
                            this.state.userSpecialization.stream && this.state.userSpecialization.profile && this.state.userSpecialization.major &&
                            <div className="col-md-1 mb-2">
                                <button className="btn btn-light" style={{ border: '1px solid black' }} onClick={this.storeUsersSpecialization}>
                                    Salvează preferința                     
                                </button> 
                            </div>                            
                        }
                        {
                            loggedUserSpecialization &&
                            <div className="col-md-1 mb-2">
                            <button className="btn btn-light" style={{ border: '1px solid black' }} onClick={this.getUsersSpecialization}>
                                Preferința mea                     
                            </button> 
                        </div>   
                        }
                 
                    </>
                    :
                    null
                    }
                </div>

                {this.state.userSpecialization?.major && (
                    <Tabs
                        defaultActiveKey={ this.state.selectedSubjects[1]}
                        id="Bac-tab"
                        className="mb-3"
                    >
                        {
                            this.state.selectedSubjects.map( subject => {

                                // Due to the excessive length of the translated names for "Biologie" and "Anatomie", 
                                // we had to implement a distinct tab title formation process for these subjects.
                                let tabTitle
                                switch( subject ){
                                    case 'anatomie':
                                        tabTitle = 'Anatomie'
                                        break

                                    case 'biologie':
                                        tabTitle = 'Biologie'
                                        break
                                    default:
                                        tabTitle = translateDbValues(subject)
                                }

                                return(
                                    <Tab key={subject} eventKey={ subject } title={  ' ' + tabTitle  }>
                                        <ExamsListByTypeSubject typeOfExam = 'bacalaureat' subject={ subject } loggedUserRole={this.props.loggedUserRole}/>
                                    </Tab>                                    
                                )
                            }
                                )
                        }
                    </Tabs>
                )}

                </>
                :                
                // <ExamsList typeOfExam = 'evaluarenationala'/>
                <Tabs
                    defaultActiveKey={'matematica'}
                    id="Bac-tab"
                    className="mb-3"
                >
                <Tab eventKey={ 'romana' } title={  translateDbValues('romana')  }>
                    <ExamsListByTypeSubject typeOfExam = 'EN08' subject='romana'/>
                </Tab>  
                <Tab eventKey={ 'matematica' } title={  translateDbValues('matematica')  }>
                    <ExamsListByTypeSubject typeOfExam = 'EN08' subject='matematica'/>
                </Tab> 
                <Tab eventKey={ 'romana_predare_maghiara' } title={  translateDbValues('romana_predare_maghiara')  }>
                    <ExamsListByTypeSubject typeOfExam = 'EN08' subject='romana_predare_maghiara'/>
                </Tab> 
                </Tabs>


            }
            </>
        )
    }


}

export default ExamsPage;
