import React, {Component} from 'react';
// import { useState } from 'react'
// import { PlusCircleIcon, MinusCircleIcon, TrashIcon } from '../../icons';
import { Button, Card, Col, Row, Form } from 'react-bootstrap';
import RemoveItem from './trash-svgrepo-com-red.svg'
import CouponsService from '../../../services/coupons.services';
import "./CartItemCard.css"
// import classes from './CartPage.module.css';

class CartItemCard extends Component{
  constructor(){
    super()
    this.state = {
      couponName: null
    }
    this.couponsService = new CouponsService()
  }

  handleInputChange = e => {
    const { name, value } = e.target
    this.setState({
      [name]: value
    })  
  }

  resetCoupon = () => {
    this.setState( {couponName: null} )
    // console.log('state reset', this.state.couponName)
  }

  handleFormSubmit = e => { 
    e.preventDefault()
    // console.log( 'coupon: ', this.state.couponName)
    // console.log( 'course: ', this.props._id)
    const couponName = this.state.couponName
    this.couponsService
      .getDiscount(this.state.couponName, this.props._id)
      .then( discount => {
        this.props.applyDiscount(this.props._id, discount.data, couponName)
      })
      .catch( err => console.log('N-A MERS!', err))
    this.resetCoupon()
  }

  // componentDidMount = () => {
  //   this.resetCoupon()
  // }
  // componentDidUpdate = (prevProps, prevState) => prevState.couponName !== this.state.couponName && this.loadLectureCard()

  render(){
    const { title, imageUrl, price, discountedPrice, _id,} = this.props;
    return (
      <Card className='cart-item'>
        <Row className='align-items-center'>
  
          <Col xs={12} md={3} xl={3} className='text-center text-md-left'>
            <div className='item-image'>
              <Card.Img src={imageUrl} alt='product' className='w-100 h-100'/>
            </div>
          </Col>
          <Col xs={8} md={6} xl={3} className='text-center text-md-left'>
            <div className='name-price'>
              <h4>{title}</h4>
              {/* <p>{price} RON</p> */}
            </div>
          </Col>
          <Col xs={{order: 3, span: 4}} md={{order: 3, span: 3}} xl={{order: 5, span: 2}} className='text-center'>
          {/* <Col xs={4} md={3} xl={2} className='text-center'> */}
                <Button variant='light' className='trash-can-icon' onClick={() => this.props.removeItem(_id)}>
                  <img src={RemoveItem} className='w-100 h-100' alt='trash can pic'/>
                </Button>
          </Col>
          <Col xs={{order: 4, span: 12}} md={{order: 4, span: 4}} xl={{order: 3, span: 2}} className='text-center'>
          {/* <Col xs={12} md={4} xl={2} className='text-center'> */}
            <Form onSubmit={this.handleFormSubmit}>
              <Form.Group controlId="couponName">
                  <Form.Label>Cupon: </Form.Label>
                  <Form.Control type="text" value={this.state.couponName ? this.state.couponName : ''} onChange={this.handleInputChange} name="couponName" />
              </Form.Group>
              <Button style={{ marginTop: '20px', width: '100%'}} variant="dark" type="submit" >Aplică reducerea</Button>
            </Form>
          </Col>
          <Col xs={{order: 5, span: 12}} md={{order: 5, span: 4}} xl={{order: 4, span: 2}} className='text-center'>
          {/* <Col xs={12} md={4} xl={2} className='text-center'> */}
            <div className='price'>
              <p style={ (typeof discountedPrice === 'number') ? { textDecoration: 'line-through', color: 'red' } : null}>{`Preț: ${price} RON`}</p>
              {
                (typeof discountedPrice === 'number') && 
                <p>{`Preț redus: ${discountedPrice} RON`}</p>
              }
              {/* <p>{`Discounted price: ${price}`}</p> */}
            </div>
          </Col>
        </Row>
      </Card>
    );
  }
}

export default CartItemCard;
