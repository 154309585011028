// GOAL & USAGE: the profiles/majors/specializations bar which functions as a search engine for the user's specialization

// import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { translateDbValues } from './../../../utils/utilitaryFunctions.js'
 
const ExamNewFormInput = ({selectedStream, setSelectedStream,  
    selectedProfile, setSelectedProfile,
    selectedMajor, setSelectedMajor}) => {
  const streams = ['teoretica', 'tehnologica', 'vocationala']; // Replaced A with Stream

  const profiles = {
    teoretica: ['real', 'umanist'], // Replaced B with Profile
    tehnologica: ['servicii', 'resurse_naturale_si_protectia_mediului', 'tehnic'],
    vocationala: ['artistic', 'pedagogic','educatie_fizica_si_sport', 'militar', 'teologic']
  };

  const majors = {
    teoretica: {
        real: ['matematica_informatica', 'stiinte_ale_naturii'], // Replaced C with Major
        umanist: ['filologie', 'stiinte_sociale']
    },
    tehnologica: {
        servicii: ['tehnician_in_turism',  'tehnician_in_administratie',  'tehnician_in_activitati_economice',  
            'tehnician_in_activitati_de_posta',  'tehnician_in_achizitii_contractari',  'tehnician_in_hotelarie',  
            'tehnician_in_activitati_comert',  'tehnician_in_gastronomie',  'organizator_banqueting',  'coafor_stilist'],
        resurse_naturale_si_protectia_mediului: [ 'tehnician_ecolog_si_protecia_calitii_mediului',  'tehnician_hidro_meteorolog',  
            'tehnician_chimist_de_laborator',  'tehnician_analize_produse_alimentare',  'tehnician_veterinar_pentru_animale_de_companie',  
            'tehnician_in_prelucrarea_lemnului',  'tehnician_in_agricultura',  'tehnician_agro_montan',  'tehnician_veterinar',  
            'tehnician_in_silvicultura_si_exploatari_forestiere',  'tehnician_in_industria_alimentara',  'tehnician_in_agroturism',  
            'tehnician_agronom',  'tehnician_horticultor',  'tehnician_zootehnist',  'tehnician_in_agricultura_ecologica',  
            'tehnician_in_prelucrarea_produselor_de_origine_animala',  'tehnician_in_morarit_panificatie_si_produse_fainoase',  
            'tehnician_in_industria_alimentara_fermentativa_si_in_prelucrarea_legumelor_fructelor',  
            'tehnician_in_industria_alimentara_extractiva',  'tehnician_pentru_animale_companie',  
            'tehnician_proiectant_produse_finite_din_lemn',  'tehnician_designer_mobila_si_amenajari_interioare'],
        tehnic: ['tehnician_de_telecomunicatii',  'tehnician_electronist',  'tehnician_electrotehnist',  
            'tehnician_operator_tehnica_de_calcul',  'tehnician_in_automatizari',  'tehnician_in_instalatii_electrice',  
            'tehnician_electromecanic',  'tehnician_operator_telematica',  'tehnician_operatori_roboti_industriali',  
            'tehnician_mecatronist',  'tehnician_proiectant_cad',  'tehnician_mecanic_intretinere_si_reparatii',  
            'tehnician_prelucrari_mecanice',  'tehnician_transporturi',  'tehnician_prelucrari_la_cald',  'tehnician_metrolog',  
            'tehnician_aviatie',  'tehnician_instalatii_de_bord_avion',  'tehnician_desenator_pentru_constructii_si_instalatii',  
            'tehnician_in_industria_materialelor_de_constructii',  'tehnician_in_industria_sticlei_si_ceramicii',  
            'tehnician_instalator_pentru_constructii',  'tehnician_productie_film_si_televiziune',  
            'tehnician_in_constructii_si_lucrari_publice',  'tehnician_audio_video',  'tehnician_operator_procesare_text_imagine',  
            'tehnician_poligraf',  'tehnician_productie_poligrafica',  'tehnician_in_chimie_industriala',  'tehnician_designer_vestimentar',  
            'tehnician_in_industria_textila',  'tehnician_in_industria_pielariei',  'tehnician_electrician_electronist_auto',  
            'tehnician_energetician',  'tehnician_prelucrari_pe_masini_cu_comanda_numerica',  'tehnician_constructii_navale']
    },
    vocationala: {
        artistic: ['arhitectura_arte_ambientale_si_design',  'arte_plastice_si_decorative',  'coregrafie',  'muzica',  'arta_actorului'],
        pedagogic: ['invatator_educatoare',  'bibliotecar_documentarist',  'instructor_animator',  'instructor_pentru_activitati_extrascolare'],
        educatie_fizica_si_sport: ['liceu_cu_program_sportiv',  'pedagog_scolar'],
        militar: ['matematica_informatica'],
        teologic: ['teologie_ortodoxa', 'patrimoniu_cultural', 'teologie_romano_catolica', 'teologie_greco_catolica', 
            'teologie_baptista', 'teologie_penticostala', 'teologie_adventista', 'teologie_unitariana', 'teologie_reformata', 
            'teologie_musulmana'],
    }
  };

  return (
    <>

    <div className="row">
      <div className="col-md-4">
      <Form.Select id="stream" aria-label='stream'
            value={selectedStream}
            onChange={(e) => setSelectedStream(e.target.value)}
            // onChange={handleStreamChange}
            // className="mb-2"
            >
            <option value="">-- Alege filiera --</option>
            {streams.map((option) => (
                <option key={option} value={option}>
                Filieră {translateDbValues(option)}
                </option>
            ))}
      </Form.Select>

      </div>
      
      <div className="col-md-4">      {/* Dropdown for Profile */}
      {selectedStream && (
        <Form.Select id="profile" aria-label='profile'
                value={selectedProfile}
                onChange={(e) => setSelectedProfile(e.target.value)}
                // onChange={handleProfileChange}
                // className="mb-2"
            >
                <option value="">-- Alege profilul --</option>
                {profiles[selectedStream].map((option) => (
                <option key={option} value={option}>
                    Profil {translateDbValues(option)}
                </option>
                ))}
        </Form.Select>
      )}
        
      </div>
      
      <div className="col-md-4">
      {/* Dropdown for Major */}
      {selectedStream && selectedProfile && (
        <Form.Select id="major"  aria-label='major'
                value={selectedMajor}
                onChange={(e) => setSelectedMajor(e.target.value)}
                // className="mb-2"
            >
                <option value="">-- Alege specializarea --</option>
                {majors[selectedStream][selectedProfile].map((option) => (
                <option key={option} value={option}>
                    {translateDbValues(option)}
                </option>
                ))}
        </Form.Select>
      )}
        
      </div>




      {/* Display selected values */}
    </div>
    </>
  );
};

export default ExamNewFormInput;
