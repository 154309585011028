import { Component } from 'react'
import { Container, Form, Button, Row, Col, ListGroup } from 'react-bootstrap'
// import isValidEmail from '../../../utils/isValidEmail'
// import {sanitizeString} from '../../../utils/utilitaryFunctions'
// import { Link } from 'react-router-dom'

import AuthService from '../../../services/auth.services'
import EmailService from '../../../services/email.services'
import BlogService from '../../../services/blog.services'

class NewPostForm extends Component {

    constructor() {
        super()
        this.state = {
            title: null,
            body: [],
            temporaryParagraph: null,
            image: null,
            temporaryTag: null,
            tags: [],
            author: null,
            links: [],
            temporaryLinkDescription: null,
            temporaryLinkUrl: null,
        }
        this.authService = new AuthService()
        this.emailService = new EmailService()
        this.blogService = new BlogService()
    }


    loadState = () => {

        // const {postId, maxNumberOfChars} = this.props
        // const rows = Math.floor( maxNumberOfChars / 50)

        this.setState({author: this.props.loggedUser._id})

    }

    componentDidMount = () => {
        this.loadState()
    }

    resetState = () =>{
        this.setState({
            title: null,
            body: [],
            temporaryParagraph: null,
            image: null,
            temporaryTag: null,
            tags: [],
            author: null,
            links: [],
            temporaryLinkDescription: null,
            temporaryLinkUrl: null,
        })
    }

    // resetState = () => {
    //     this.setState({
    //     })
    // }

    handleInputChange = e => {
        const { name, value } = e.target
            this.setState({ [name]: value })
    }
    

    addTag = e => {
        e.preventDefault()
        if( this.state.temporaryTag.trim() !== ''){
            const tempTags = [...this.state.tags, this.state.temporaryTag]
            this.setState({ tags: tempTags, temporaryTag: '' })   
        }
    }

    addLink = e => {
        e.preventDefault()
        if( this.state.temporaryLinkDescription.trim() !== '' && this.state.temporaryLinkUrl.trim() !== ''){
            const newLink = {
                description: this.state.temporaryLinkDescription,
                url: this.state.temporaryLinkUrl,
            }
            const tempLinks = [...this.state.links, newLink]
            this.setState({ links: tempLinks, temporaryLinkDescription: '', temporaryLinkUrl: '' })   
        }
    }

    addParagraph = e => {
        e.preventDefault()
        if( this.state.temporaryParagraph.trim() !== ''){
            const tempBody = [...this.state.body, this.state.temporaryParagraph]
            this.setState({ body: tempBody, temporaryParagraph: '' })   
        }
    }


    //TTIA token needed ( see the below example )
    handleFormSubmit = e => {
        e.preventDefault()
        // console.log(courseInput)

        this.blogService
            .newPost(this.state)
            .then(() => {
                // this.props.closeModal()
                // this.props.refreshCoasters()
                this.resetState()
                this.props.history.push('/blog')
            })
            .catch(err => {
                this.props.handleAlert(err.response?.data.message ? 
                    err.response?.data.message : [err.response?.statusText], 10000, 'warning', true)
            })
    }


    // handleFormSubmit = e => {

    //     e.preventDefault()

        // const { message} = this.state
        
        // if(! ( this.props.loggedUser ) ){
        //     this.props.handleAlert([`Trebuie să te autentifici pentru a iniția o conversație.`], 10000, 'warning', true)
        //     return
        // }else if(! ( message ) ){
        //     this.props.handleAlert([`Mesajul nu conține niciun caracter.`], 10000, 'warning', true)
        //     return
        // }else if ( message !== sanitizeString(message) ) {
        //     this.props.handleAlert(['Eliminați caractererele speciale din mesaj.', 
        //         'Exemple de caractere speciale: <, >, :, ", /, \, |, ?, *.'], 10000, 'warning', true);
        //     return;
        // }

        // de verificat token-ul
        // this.authService
        //     .generateToken(this.props.loggedUser.email)
        //     .then(res => {
        //         const {token} = res.data
        //         this.blogService
        //             .newComment( this.state.postId, this.state.message, this.props.loggedUser._id, token )
        //             .then( res => {
        //                 this.resetState()
        //                 this.props.refreshCommentsList()
        //             })
        //             .catch(err => {                               
        //                 console.log(err)
        //                 this.props.handleAlert(err.response?.data.message ? 
        //                         err.response?.data.message : 
        //                         ['Mesajul nu a fost trimis din cauza unei erori de rețea.'], 10000, 'warning', true)
        //                 // TTIA: redirect here.
        //             })
        //         })
        //     .catch( err => {
        //         this.props.handleAlert(err.response?.data.message ? 
        //             err.response?.data.message : [err.response?.statusText], 10000, 'warning', true)
        //     })  

    // }



    render() {
        return (
            <Container>

                <Form onSubmit={this.handleFormSubmit}>

                    <Form.Group controlId="title">
                        <Form.Label>Title</Form.Label>
                        <Form.Control type="text" value={this.state.title} onChange={this.handleInputChange} name="title" />
                    </Form.Group>

                    <Form.Group controlId="image">
                        <Form.Label>Image</Form.Label>
                        <Form.Control type="text" value={this.state.image} onChange={this.handleInputChange} name="image" />
                    </Form.Group>

                    <Row style={{ alignItems: 'bottom' }}>
                        <Col xs={6} md={4}>
                            <Form.Group controlId="temporaryTag">
                                <Form.Label>Tags</Form.Label>
                                <Form.Control type="text" value={this.state.temporaryTag} onChange={this.handleInputChange} name="temporaryTag" />
                            </Form.Group>
                        </Col>
                        <Col xs={6} md={2}>
                            <Button style={{ marginTop: '2em', width: '100%' }} variant="dark" onClick={this.addTag} >
                            {/* <Button style={{ marginTop: '2em', width: '100%' }} variant="dark" onClick={addTag} disabled = {!courseInput.addTagEnabled}> */}
                                Add tag
                            </Button>
                        </Col>
                        <Col xs={12} md={6} style={{ marginTop: '1.8em'}}>
                            <ListGroup horizontal>
                                {this.state.tags.map( tag => <ListGroup.Item>{tag}</ListGroup.Item>)}
                            </ListGroup>
                        </Col>
                    </Row>

<Row style={{ alignItems: 'bottom' }}>
    <Col xs={6} md={4}>
        <Form.Group controlId="temporaryLinkDescription">
            <Form.Label>Link description</Form.Label>
            <Form.Control type="text" value={this.state.temporaryLinkDescription} onChange={this.handleInputChange} name="temporaryLinkDescription" />
        </Form.Group>
        <Form.Group controlId="temporaryLinkUrl">
            <Form.Label>Link url</Form.Label>
            <Form.Control type="text" value={this.state.temporaryLinkUrl} onChange={this.handleInputChange} name="temporaryLinkUrl" />
        </Form.Group>
    </Col>
    <Col xs={6} md={2}>
        <Button style={{ marginTop: '2em', width: '100%' }} variant="dark" onClick={this.addLink} >
        {/* <Button style={{ marginTop: '2em', width: '100%' }} variant="dark" onClick={addTag} disabled = {!courseInput.addTagEnabled}> */}
            Add link
        </Button>
    </Col>
    <Col xs={12} md={6} style={{ marginTop: '1.8em'}}>
        <ListGroup horizontal>
            {this.state.links.map( tag => <ListGroup.Item>{tag.description} | {tag.url}</ListGroup.Item>)}
        </ListGroup>
    </Col>
</Row>

                    <Row style={{ alignItems: 'bottom' }}>
                        <Col xs={6} md={4}>
                            <Form.Group controlId="temporaryParagraph">
                                <Form.Label>Paragrafe</Form.Label>
                                <Form.Control type="text" value={this.state.temporaryParagraph} onChange={this.handleInputChange} name="temporaryParagraph" />
                            </Form.Group>
                        </Col>
                        <Col xs={6} md={2}>
                            <Button style={{ marginTop: '2em', width: '100%' }} variant="dark" onClick={this.addParagraph} >
                            {/* <Button style={{ marginTop: '2em', width: '100%' }} variant="dark" onClick={addTag} disabled = {!courseInput.addTagEnabled}> */}
                                Add paragraph
                            </Button>
                        </Col>
                        <Col xs={12} md={12} style={{ marginTop: '1.8em'}}>
                            <ListGroup >
                                {this.state.body.map( tag => <ListGroup.Item>{tag}</ListGroup.Item>)}
                            </ListGroup>
                        </Col>
                    </Row>


                <Button style={{ marginTop: '20px', width: '100%' }} variant="dark" type="submit">Creează o postare nouă.</Button>

                </Form>

            </Container>

        )
    }
}


export default NewPostForm