import React, { useEffect } from 'react';

const RedirectToHTTPS = () => {
  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === 'production' && window.location.protocol !== 'https:') {
      window.location.href = 'https://' + window.location.hostname + window.location.pathname;
    }
  }, []);

  return null; // This component doesn't render anything
};

export default RedirectToHTTPS;
