import React from 'react';
// import { Button } from 'react-bootstrap'

const RecoverPasswordEmailTemplate = ({ email, token, expireDate }) => {
    // for failed attempts check the file 'WelcomeEmailTemplate_Comments.txt'
    const tableStyle = { maxWidth: '600px', margin: '0 auto', textAlign: 'center', fontFamily: 'Arial, sans-serif' }
    const greetingStyle = { color: '#333', fontSize: '1.5em', marginTop: '20px' }
    const paragraphStyle = { color: '#555', fontSize: '1em', marginTop: '1em', marginBottom: '0', paddingTop: '0.3em' }
    const logoStyle = { maxWidth: '100%', height: 'auto', width: '3.5em', marginRight: '10px' }
    const signatureStyle = { color: '#333', fontSize: '1.3em', marginBottom: '0', marginTop: '0' }
    const resetButtonStyle = { 
      marginTop: '0', 
      width: '30%', 
      display: 'inline-block', 
      textDecoration: 'none',
      color: '#fff',
      backgroundColor: '#343a40',
      border: '1px solid #343a40',
      padding: '.375rem .75rem',
      fontSize: '1rem',
      lineHeight: '1.5',
      borderRadius: '.25rem',
      transition: 'color .15s, background-color .15s, border-color .15s, box-shadow .15s',
      textAlign: 'center',
      verticalAlign: 'middle',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      userSelect: 'none'
    };


  return (
    <table width="100%" border="0" cellSpacing="0" cellPadding="0" style={tableStyle}>


      <tr>
        <td style={greetingStyle}>Salut,</td>
      </tr>
      <tr>
        <td style={paragraphStyle}>Am înregistrat o solicitare pentru resetarea parolei contului atașat adresei {email}.</td>
      </tr>
      <tr>
        <td style={paragraphStyle}>Ca să alegi o nouă parolă pentru contul tău MateCuMatei, apasă butonul de mai jos</td>
      </tr>
      <tr>
        <td style={paragraphStyle}>
          <a href={`${process.env.REACT_APP_DOMAIN}/reset-password/${email}/${token}`} style={resetButtonStyle}>Schimbă parola</a>
          {/* <a href={`http://localhost:3000/reset-password/${email}/${token}`} style={resetButtonStyle}>Schimbă parola</a> */}
        </td>
      </tr>
      {/* <tr>
        <td style={paragraphStyle}>Linkul este valabil până la data {expireDate.date}, ora {expireDate.time}.</td>
      </tr> */}
      <tr>
        <td style={paragraphStyle}>În cazul în care nu ai solicitat această resetare de parolă, te rugăm să ignori acest email.</td>
      </tr>
      <tr>
        <td style={paragraphStyle}>Toate cele bune!</td>
      </tr>
      
      <tr>
      {/* nested table needed here: */}
        <td style={{ textAlign: 'center' }}>  
          <table style={{ display: 'inline-block' }}>
            <tr>
              <td>
                <img src="https://res.cloudinary.com/dvz9tjidq/image/upload/v1680680365/MateCuMatei/crmflhfjnkjwokwypst2.png" alt="MateCuMatei Logo" 
                    style={logoStyle} />
              </td>
              <td>
                <p style={signatureStyle}>Academia MateCuMatei</p>
              </td>
            </tr>
          </table>
        </td>
      </tr>
    </table>



  

  );
}


export default RecoverPasswordEmailTemplate;

