import './../BlogPage/PostCard.css';

import { FaRegThumbsUp } from "react-icons/fa";
import { Col, Button, Badge, Card} from "react-bootstrap";
import { dateUnixToCustom, getCustomizedErrorMessagesArray } from '../../../utils/utilitaryFunctions';
import { Component } from 'react';
import QuestionsAndAnswersService from '../../../services/questionsAndAnswersService.services';
import Spinner from '../../shared/Spinner';


class AnswerCard extends Component{

    constructor(){
        super()
        this.state = {
            answer: null,
        }
        this.questionsAndAnswersService = new QuestionsAndAnswersService()
    }

    loadAnswerDetails = () => {
        const answer_id = this.props.answer._id
        this.questionsAndAnswersService
            .getAnswer(answer_id)
            .then( res => this.setState({answer: res.data}))
            .catch( err => {  
                if(err.response?.data){
                    this.props.handleAlert(err.response.data.message, 10000, 'warning', true)
                // }else if(err.response){
                //     this.props.handleAlert([err.response.data], 10000, 'warning', true)
                }else{
                    this.props.handleAlert(['The server is down'], 10000, 'danger', true)
                }  
                })
    }

    componentDidMount = () => {
        this.loadAnswerDetails()
    }

    likeAnswer = () => {
        const answer_id = this.state.answer._id
        const {loggedUser} = this.props
        this.questionsAndAnswersService
            .likeAnswer(answer_id, loggedUser)
            .then( res => {
                if( res.data.thumbsUp !== this.state.answer.thumbsUp ){
                    this.loadAnswerDetails()
                    this.props.fetchUser()
                }
            })
            .catch( err => {   
                if(err?.response){
                    console.log( err.response.data)
                    // this.props.handleAlert([err.response.data], 10000, 'warning', true)
                    this.props.handleAlert(err.response.data.message, 10000, 'warning', true)
                }   else{
                    this.props.handleAlert(['The server is down'], 10000, 'danger', true)
                }  
                })
    }

    // refreshCommentCard = () => {
    //     const incrementedTrigger = ++this.state.trigger
    //     this.setState({ trigger: incrementedTrigger }, () => {
    //         // The callback function is executed after the state is updated
    //         this.loadCommentDetails();
    //     });
    // }

    deleteAnswer = () => {

        this.questionsAndAnswersService
            .deleteAnswer(this.state.answer._id)
            .then(post => {
                this.props.refreshQuestionsList()
                this.props.refreshAnswersList()                
            })
            .catch(err => this.props.handleAlert(getCustomizedErrorMessagesArray(err), 10000, 'warning', true))

    }

    render(){


        // IMPORTANT: the semicolons are mandatory here:
        let message, createdAt, thumbsUp, author, date, time;
        (this.state.answer !== null) && ( { message, createdAt, thumbsUp, author } = this.state.answer );
        (this.state.answer !== null) && ( {date, time} = dateUnixToCustom(createdAt));


        return(
            !this.state.answer
            ?
            <Spinner size={200}/>
            :
            <Col xs={12}>
                <Card
                    bg='light'
                    text='dark'
                    style={{ width: '100%' }}
                    className="mb-2"
                    >
                    <Card.Header className="d-flex justify-content-between">
                        {`${date}, ${time}, by ${author.username}`}

                        <div style={{ display: 'flex'}}>
                            <Button variant="success" style={{marginLeft:'1em'}} onClick={this.likeAnswer}>
                                {/* <span></span>   */}
                                <FaRegThumbsUp style={{  margin: '0 0.5em' }} />
                                <Badge bg="secondary">{thumbsUp}</Badge>
                            </Button>

                                
                            {
                                ( this.props.loggedUser?.role === 'admin'  || this.props.loggedUser?._id === this.state.answer.author._id )
                                &&                    
                                <span>
                                    <Button className="btnBlock" style={{ margin: '0 1em' }} variant="danger"  onClick={this.deleteAnswer}>Delete</Button>
                                </span>
                            }

                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title></Card.Title>
                        <Card.Text>{message}</Card.Text>
                    </Card.Body>
                </Card>

            </Col>
        )
    }

}

export default AnswerCard