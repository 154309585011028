import { Component } from "react";
import UsersService from "../../../services/users.services";
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import ResetedPasswordEmailTemplate from "../EmailTemplates/ResetedPasswordEmailTemplate";
import EmailService from "../../../services/email.services";
import jsxToHtml from '../../../utils/jsxToHtml'
// import { Link } from 'react-router-dom'

class ResetPassword extends Component{
    constructor(){
        super()
        this.state = {
            user: undefined,
            token: undefined
        }
        this.userService = new UsersService()
        this.emailService = new EmailService()
    }

    handleInputChange = e => {
        const { name, value } = e.target
        this.setState({user: {...this.state.user, [name]: value}})        
    }

    handleFormSubmit = e => {
        e.preventDefault()
        this.userService
            .resetPassword(this.state.user, this.state.token)
            .then( () => {
                
                const resetedEmailTemplate = <ResetedPasswordEmailTemplate email = {this.state.user.email} token = {this.state.token} />
                const resetedEmailHtmlTemplate = jsxToHtml( resetedEmailTemplate )
                const emailResetedPreSubject = 'MCM_R: '
                const emailResetedSubject = 'Parola a fost schimbată'

                this.emailService
                    .sendEmail(this.state.user.email, this.state.token, emailResetedSubject, resetedEmailHtmlTemplate, emailResetedPreSubject)
                    .then( () => {
                        this.props.history.push(`/login`);
                        this.props.handleAlert(['Parola a fost schimbată cu succes.','Acum te poți autentifica cu noua parolă.'], 10000, 'info', true)
                    })
                    .catch(err => {
                        this.props.handleAlert(err.response?.data.message ? 
                            err.response?.data.message : ['A network error prevented the Reseted email from being sent.'], 10000, 'warning', true)
                    })

            })
            .catch( err => this.props.handleAlert(err.response.data.message, 10000, 'warning', true))

    }

    componentDidMount(){
        const { email, token } = this.props.match.params
        this.userService
            .getUserByEmail(email)
            .then(response => {
                if(response.data == null){
                    this.props.history.push(`/signup`);
                    this.props.handleAlert([`Nu există un cont pentru adresa ${email}.`, 'Ne-am bucura dacă te alături comunității MateCuMatei.'], 20000, 'warning', true)
                }else{
                    this.setState({
                        user: response.data,
                        token
                    })                    
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    render(){
        const { user } = this.state;

        if (!user) {
          return <div>Loading...</div>;
        }

        return(
            <Container style={{marginBottom: '15em'}}>

                <Row>

                    <Col md={{ span: 4, offset: 4 }}>

                        <h1>Schimbă parola</h1>

                        <hr></hr>

                        <Form onSubmit={this.handleFormSubmit}>

                                <Form.Group controlId="pwdNew1">
                                    <Form.Label>Noua parolă{/*New Password*/}</Form.Label>
                                    <Form.Control type="password" value={this.state.user.pwdNew1 || ''} onChange={this.handleInputChange} name="pwdNew1" />
                                </Form.Group>

                                <Form.Group controlId="pwdNew2">
                                    <Form.Label>Reintrodu noua parolă pentru verificare{/*New Password*/}</Form.Label>
                                    <Form.Control type="password" value={this.state.user.pwdNew2 || ''} onChange={this.handleInputChange} name="pwdNew2" />
                                </Form.Group>
            
                
                            <Button style={{ marginTop: '20px', width: '100%' }} variant="dark" type="submit">Schimbă parola{/*Update My Info*/}</Button>

                        </Form>

                    </Col>
                </Row>

            </Container >
        )
    }


}

export default ResetPassword