// import { useState } from 'react'
import CurriculumSectionCard from "./CurriculumSectionCard";
import { Component } from "react";

class Curriculum extends Component{

    constructor(){
        super()
        this.state={
            currentSection: '1',
            currentLecture: '1',
            refreshSectionsList: true,
        }
    }

    refreshSectionsList = () => {
        this.setState({
            refreshSectionsList: !this.refreshSectionsList
        })
    }

    setCurrentLecture = (sectionNumber, lectureNumber) => {
        this?.setState({currentSection: sectionNumber, currentLecture: lectureNumber})
    }

    setCurrentSection = (sectionNumber) => {
        this?.setState({currentSection: sectionNumber})
    }

    // to be refactored so after scrolling down, the lecture would be aligned on click - the current problem is generated by the this.state.currentLecture not changing
    scrollIntoViewCurrentLecture = () => {        
        const currentLectureID = `clecture${this.state.currentLecture}`
        const element = currentLectureID ? document.getElementById(currentLectureID) : null;
        if (element){
            element?.scrollIntoView(); 
        }
    }

    // TO BE ADDED WHEN THE SECTION'S ARE REVEALED
    scrollIntoViewCurrentSection = () => {
        const currentSectionID = `csection${this.state.currentSection}`
        const element = currentSectionID ? document.getElementById(currentSectionID) : null;
        if (element){
            element?.scrollIntoView(); 
        }
    }

    componentDidUpdate(prevProps, prevState){
        if( prevState.currentLecture !== this.state.currentLecture){
            this.scrollIntoViewCurrentLecture()
        }
        if( prevState.currentSection !== this.state.currentSection && prevState.currentLecture === this.state.currentLecture){
            this.scrollIntoViewCurrentSection()
        }
    }


    render(){
        const {courseId, sections, loggedUser, renderList, currentVideo, setCurrentVideo} = this.props
        // am renuntat la trei variabile definite dar nefolosite
        // const {courseId, sections, loggedUser, renderList, currentLecture, currentSection, setCurrentLecture, currentVideo, setCurrentVideo} = this.props
        // const sectionsListOverflowStyle = loggedUser? {height:'80vh', overflowY:'scroll'} : {}
        return(
            // <div style={sectionsListOverflowStyle}>
            <div style={{height:'40vh', overflowY:'scroll'}}>
            {sections.map(sectionId => <CurriculumSectionCard key={sectionId} courseId={courseId} sectionId = {sectionId}  loggedUser={loggedUser} 
                renderList={renderList} 
                currentLecture={this.currentLecture} currentSection={this.currentSection}
                setCurrentLecture={this.setCurrentLecture} setCurrentSection={this.setCurrentSection}
                currentVideo={currentVideo} setCurrentVideo={setCurrentVideo}
                refreshSectionsList={this.refreshSectionsList}/>)}
            {/* {sections.map(sectionId => <SectionCard key={sectionId} courseId={courseId} sectionId = {sectionId}  loggedUser={loggedUser} renderList={renderList}/>)} */}
            </div>
        )
    }
}



export default Curriculum


