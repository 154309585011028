import { Component } from "react";
import DropDownListArrow from "../SectionPage/DropDownListArrow";



class FrequentlyAskedQuestion extends Component{
    constructor(){
        super()
        this.state = {
            showAnswer: false,
            displayAnswerStatus: 'none',
            textColor: process.env.REACT_APP_DARK_GREY
        }
    }

    toggleShowAnswer(){
        this.setState( prevState => ({
            showAnswer: !prevState.showAnswer,
            displayAnswerStatus: prevState.showAnswer ? 'none' : 'inline',
            textColor: !prevState.showAnswer ? process.env.REACT_APP_YELLOW_MCM : process.env.REACT_APP_DARK_GREY
        }))
    }

    loadQuestionPage(){
        this.setState({showAnswer: false, displayAnswerStatus: 'none', textColor: process.env.REACT_APP_DARK_GREY})  
    }

    componentDidMount(){      
        this.loadQuestionPage()
    }

    render(){

        const {section} = this.props

        return(
            <div>
                <div className="questionContainer" style={{display: 'flex'}} onClick={() => this.toggleShowAnswer()}>
                    <DropDownListArrow  showLectures={this.state.showAnswer}  />
                    <h5 style={{paddingLeft: '1em', color: this.state.textColor}}>{section.question}</h5>
                </div>
                <ul style={{display: this.state.displayAnswerStatus}}>
                    {section.answers.map((answer, idx) => (
                        <li key={idx} style = { {paddingLeft: "1em"}}>{answer}</li>
                    ))}
                </ul>
            </div>
        )
    }
}

export default FrequentlyAskedQuestion