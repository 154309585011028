import { Component } from "react";
import CoursesService from "../../../services/courses.services";
import Spinner from './../../shared/Spinner'

import { Row } from 'react-bootstrap'
import CourseCard from "./CourseCard";

class CoursesList extends Component{

    constructor(){
        super()
        this.state = {
            courses: undefined
        }
        this.coursesService = new CoursesService()
    }

    loadCourses = () => {
        this.coursesService
            .getAllCourses()
            .then( res => this.setState({courses: res.data}))
            .catch( err => {   
                if(err.response){
                    this.props.handleAlert([err.response.data], 10000, 'warning', true)
                }   else{
                    this.props.handleAlert(['The server is down'], 10000, 'danger', true)
                }  
                })
    }

    componentDidMount = () => {
        this.loadCourses()
    }

    render(){

        const coursesList = !this.state.courses
            ?
            // <h1>waiting2...</h1>
            <Spinner size={200}/>
            :
            <Row>
                {this.state.courses?.map( elm => 
                    ( elm.isComplete || this.props?.loggedUser?.role === 'admin' ) 
                    &&
                    <CourseCard addToCart={this.props.addToCart} removeItem={this.props.removeItem} loggedUser={this.props.loggedUser} 
                        key = {elm._id} course={{...elm}}/>)}
            </Row>

        return(
            <>
                {coursesList}
            </>
            
            
        )
    }
}

export default CoursesList