import React from "react";
import './Quote.css'

const Quote = ({quoteFontWeight, quoteFontStyle, quote, author, quoteFontSize, authorFontSize}) => {
    return(
        <div className="quote-container">

            <p style={{fontSize: quoteFontSize, fontStyle: quoteFontStyle, fontWeight: quoteFontWeight}} className="quote-text">{quote}</p>
            <p style={{fontSize: authorFontSize}} className="author-text">{author}</p>
            <div className="separator-line" style={{backgroundColor: process.env.REACT_APP_YELLOW_MCM}}></div>
        </div>
    )
}

export default Quote