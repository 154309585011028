import React from "react";

import Hero from "../../layout/Hero/Hero";
import Quote from "../../shared/Quote/Quote";
import DisplayJsonToOrderedList from "../../shared/DisplayJsonToOrderedList/DisplayJsonToOrderedList";
import UserCard from "../UserCard/UserCard";

import innovativeCourses from "./InnovativeCourses.json"
import origins from "./Origins.json"

import './AboutUs.css'

const AboutUs = () => {
    return(
        <>
            <div className="about-us-body"></div>

            <div className="opaque">
                <Hero title = 'Despre Noi'/>
                <Quote quoteFontStyle = 'italic' quoteFontSize = '1.4em' quote = '"Matematica este o lume a ideilor. Apare când un obstacol real … îți dă de gândit."' 
                    authorFontSize = '' author = 'Silviu, Fondator'/>
                <Quote quoteFontWeight = '700' quoteFontSize = '1.4em' quote = 'Povestea MateCuMatei' 
                    author = 'Cine suntem.'/>
                <div className="about-us-presentation-container">
                    <DisplayJsonToOrderedList sections={origins.sections} sectionTitleSize='1.2em' className='about-us-presentation-sheet'/>
                    <DisplayJsonToOrderedList sections={innovativeCourses.sections} sectionTitleSize='1.2em' className='about-us-presentation-sheet'/>            
                </div>
            </div>

            <div className="window-overlay"></div>

            <div className="opaque">
                <Quote quoteFontWeight = '700' quoteFontSize = '1.4em' quote = 'Echipa' 
                    author = 'Pasiune pentru detalii.'/>
                <div className="about-us-team-container">
                    <UserCard className='about-us-team-member'/>
                </div>     
            </div>   
        </>
 

    )
}

export default AboutUs