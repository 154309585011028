
//************************************************* Take01 ************************************************************
// Initial functional component:

import React from 'react'
import { Container, Row} from 'react-bootstrap';

// import classes from './LectureModal.module.css';

const LectureVideo = props => {

    const {videoUrl} = props

    let containerStyle = {padding: '0'}
    let rowStyle = {padding: '0', margin:'0'}
 

    return(
        <Container fluid style={containerStyle}>
            <Row className="ratio ratio-16x9" style={rowStyle}>
                <iframe src={videoUrl} className='col'
                    allow="autoplay; fullscreen; picture-in-picture" title="01 Structura cursului 6 FEB" >
                </iframe>
            </Row>
        </Container>

    )
}

export default LectureVideo





//************************************************* Take05b ************************************************************
// the requestFullscreen() method + window orientation listener

// import React, { useRef, useEffect } from 'react';
// import { Container, Row } from 'react-bootstrap';

// const LectureVideo = props => {
//     const { videoUrl, isMobileLandscape } = props;
//     const iframeRef = useRef(null);

//     useEffect(() => {
//         const iframe = iframeRef.current;

//         const handleOrientationChange = () => {
//             if (isMobileLandscape && iframe) {
//                 iframe.requestFullscreen();
//             }
//         }

//         window.addEventListener('orientationchange', handleOrientationChange);

//         return () => {
//             window.removeEventListener('orientationchange', handleOrientationChange);
//         };
//     }, [isMobileLandscape]);

//     let containerStyle = {padding: '0'};
//     let rowStyle = {padding: '0', margin:'0'};

//     return(
//         <Container fluid style={containerStyle}>
//             <Row className="ratio ratio-16x9" style={rowStyle}>
//                 <iframe
//                     ref={iframeRef}
//                     src={videoUrl}
//                     className='col'
//                     allow="autoplay; fullscreen; picture-in-picture"
//                     title="01 Structura cursului 6 FEB"
//                 />
//             </Row>
//         </Container>
//     );
// }

// export default LectureVideo;



//************************************************* Take05b ************************************************************
// the requestFullscreen() method
// import React, { useRef, useEffect } from 'react';
// import { Container, Row } from 'react-bootstrap';

// const LectureVideo = props => {
//     const { videoUrl, isMobileLandscape } = props;
//     const iframeRef = useRef(null);

//     useEffect(() => {
//         if (isMobileLandscape && iframeRef.current) {
//             const iframe = iframeRef.current;
//             iframe.requestFullscreen(); // Request full-screen mode
//         }
//     }, [isMobileLandscape]);

//     let containerStyle = {padding: '0'};
//     let rowStyle = {padding: '0', margin:'0'};

//     return(
//         <Container fluid style={containerStyle}>
//             <Row className="ratio ratio-16x9" style={rowStyle}>
//                 <iframe
//                     ref={iframeRef}
//                     src={videoUrl}
//                     className='col'
//                     allow="autoplay; fullscreen; picture-in-picture"
//                     title="01 Structura cursului 6 FEB"
//                 />
//             </Row>
//         </Container>
//     );
// }

// export default LectureVideo;








//************************************************* Take04 ************************************************************
// using the Vimeo api
// should add <script src="https://player.vimeo.com/api/player.js"></script> to index.html, after Title

// import React, { useRef, useEffect } from 'react';
// import { Container, Row } from 'react-bootstrap';

// const LectureVideo = props => {
//     const { videoUrl } = props;
//     const playerRef = useRef(null);

//     useEffect(() => {
//         const player = new Vimeo.Player(playerRef.current);

//         player.ready().then(() => {
//             playerRef.current.addEventListener('click', () => {
//                 player.getPaused().then(paused => {
//                     if (paused) {
//                         player.play();
//                     } else {
//                         player.pause();
//                     }
//                 });
//             });
//         });
//     }, [videoUrl]);

//     let containerStyle = { padding: '0' };
//     let rowStyle = { padding: '0', margin: '0' };

//     return (
//         <Container fluid style={containerStyle}>
//             <Row className="ratio ratio-16x9" style={rowStyle}>
//                 <iframe ref={playerRef} src={videoUrl} className="col" title="01 Structura cursului 6 FEB"></iframe>
//             </Row>
//         </Container>
//     );
// };

// export default LectureVideo;




//************************************************* Take03 ************************************************************
// using a class component

// import React, {Component} from 'react'
// import { Container, Row} from 'react-bootstrap';

// // import classes from './LectureModal.module.css';

// class LectureVideo extends Component {

//     componentDidMount() {
//         const iframe = document.querySelector('iframe');
//         iframe.contentDocument.addEventListener('click', () => {
//             const video = document.querySelector('video');
//             if (video.paused) {
//                 video.play();
//             } else {
//                 video.pause();
//             }
//         });
//     }

//     render(){

//     const {videoUrl} = this.props
        
//     let containerStyle = {padding: '0'}
//     let rowStyle = {padding: '0', margin:'0'}


//     return(
//         <Container fluid style={containerStyle}>
//             <Row className="ratio ratio-16x9" style={rowStyle}>
//                 <iframe src={videoUrl} className='col'
//                     allow="autoplay; fullscreen; picture-in-picture" title="01 Structura cursului 6 FEB" >
//                 </iframe>
//             </Row>
//         </Container>

//     )
//     }

 

// }

// export default LectureVideo



//************************************************* Take02 ************************************************************
// The useEffect version

// import React, {useEffect} from 'react'
// import { Container, Row} from 'react-bootstrap';

// // import classes from './LectureModal.module.css';

// const LectureVideo = props => {

//     const {videoUrl} = props

//     useEffect(() => {
//         // Get the iframe element
//         const iframe = document.querySelector('iframe');

//         // Add event listener to the iframe content
//         iframe.contentDocument.addEventListener('click', () => {
//             const video = document.querySelector('video');

//             // Toggle play/pause
//             if (video.paused) {
//                 video.play();
//             } else {
//                 video.pause();
//             }
//         });
//     }, []);

//     let containerStyle = {padding: '0'}
//     let rowStyle = {padding: '0', margin:'0'}
 

//     return(
//         <Container fluid style={containerStyle}>
//             <Row className="ratio ratio-16x9" style={rowStyle}>
//                 <iframe src={videoUrl} className='col'
//                     allow="autoplay; fullscreen; picture-in-picture" title="01 Structura cursului 6 FEB" >
//                 </iframe>
//             </Row>
//         </Container>

//     )
// }

// export default LectureVideo




//************************************************* Take04 ************************************************************
// The useEffect + forcing fullscreen mode version --> not working
// import React, { useRef, useEffect } from 'react'
// import { Container, Row} from 'react-bootstrap';

// // import classes from './LectureModal.module.css';

// const LectureVideo = props => {

//     const {videoUrl, isMobileLandscape } = props
//     const iframeRef = useRef(null)

//     useEffect(() => {
//         const iframe = iframeRef.current;

//         if (isMobileLandscape && iframe) {

//             const requestFullScreen =
//                 iframe.requestFullscreen ||
//                 iframe.mozRequestFullScreen ||
//                 iframe.webkitRequestFullscreen ||
//                 iframe.msRequestFullscreen;

//             if (requestFullScreen) {
//                 requestFullScreen.call(iframe);
//             }

//             // Check if fullscreen is supported by the browser
//             if (iframe.requestFullscreen) {
//                 iframe.requestFullscreen();
//             } else if (iframe.mozRequestFullScreen) { // Firefox
//                 iframe.mozRequestFullScreen();
//             } else 
//             if (iframe.webkitRequestFullscreen) { // Chrome, Safari and Opera
//                 iframe.webkitRequestFullscreen();
//             } 
//             else if (iframe.msRequestFullscreen) { // IE/Edge
//                 iframe.msRequestFullscreen();
//             }
//         }
//     }, [isMobileLandscape]);

//     let containerStyle = {padding: '0'}
//     let rowStyle = {padding: '0', margin:'0'}
 

//     return(
//         <Container fluid style={containerStyle}>
//             <Row className="ratio ratio-16x9" style={rowStyle}>
//                 <iframe
//                     ref={iframeRef} 
//                     src={videoUrl} 
//                     className='col'
//                     allow="autoplay; fullscreen; picture-in-picture" 
//                     title="01 Structura cursului 6 FEB" >
//                 </iframe>
//             </Row>
//         </Container>
//     )
// }

// export default LectureVideo




