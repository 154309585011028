// import React from "react"
// import privacyPolicyContent from './PrivacyPolicy.json';
import './Legal.css'
import Hero from '../../layout/Hero/Hero';
import DisplayJsonToOrderedList from '../../shared/DisplayJsonToOrderedList/DisplayJsonToOrderedList';

const PrivacyPolicy = ({sections, sectionTitleSize, title}) => {


    return(
        <div className="privacy-policy-container">
            <Hero title = {title}/>
            <DisplayJsonToOrderedList sectionTitleSize = {sectionTitleSize} sections = {sections} />
        </div>
    )
}

export default PrivacyPolicy