import './../BlogPage/PostCard.css';

import { FaRegThumbsUp, FaReplyd } from "react-icons/fa";
import { Col, Button, Badge, Card} from "react-bootstrap";
import { dateUnixToCustom, getCustomizedErrorMessagesArray } from './../../../utils/utilitaryFunctions';
import { Component } from 'react';
import QuestionsAndAnswersService from '../../../services/questionsAndAnswersService.services';
import Spinner from '../../shared/Spinner';
import AnswersList from './AnswersList';
import DropDownListArrow from '../SectionPage/DropDownListArrow';


class QuestionCard extends Component{

    constructor(){
        super()
        this.state = {
            question: null,
            showAnswers: false,
            // trigger: null
        }
        this.questionsAndAnswersService = new QuestionsAndAnswersService()
    }

    loadQuestionDetails = () => {
        const question_id = this.props.question._id
        this.questionsAndAnswersService
            .getQuestion(question_id)
            .then( res => this.setState({question: res.data}))
            .catch( err => {  
                if(err.response?.data){
                    this.props.handleAlert(err.response.data.message, 10000, 'warning', true)
                // }else if(err.response){
                //     this.props.handleAlert([err.response.data], 10000, 'warning', true)
                }else{
                    this.props.handleAlert(['1The server is down'], 10000, 'danger', true)
                }  
                })
    }

    componentDidMount = () => {
        this.loadQuestionDetails()
    }

    likeQuestion = () => {
        const question_id = this.state.question._id
        const {loggedUser} = this.props
        this.questionsAndAnswersService
            .likeQuestion(question_id, loggedUser)
            .then( res => {
                if( res.data.thumbsUp !== this.state.question.thumbsUp ){
                    // this.refreshQuestionCard()
                    this.loadQuestionDetails()
                    this.props.fetchUser()
                }
            })
            .catch( err => {   
                console.log(err)
                if(err?.response){
                    console.log( err.response.data)
                    // this.props.handleAlert([err.response.data], 10000, 'warning', true)
                    this.props.handleAlert(err.response.data.message, 10000, 'warning', true)
                }   else{
                    this.props.handleAlert(['2The server is down'], 10000, 'danger', true)
                }  
                })
    }

    toggleShowAnswers = () => {
        // ADDITIONAL_INFO 
        // doar cand desfasor lista cu lectures pt o noua sectiune, capul de sectiune se aliniaza, facand lectures vizibile
        // cand ascund lista de lectures nu centrez
        this.setState({showAnswers: !this.state.showAnswers})
    }

    deleteComment = () => {

        this.questionsAndAnswersService
            .deleteQuestion(this.state.question._id)
            .then(() => {
                console.log(`Hayyyyyayyyayyayyayayayya`)
                this.props.refreshQuestionsList()
                return
            })
            .catch(err => this.props.handleAlert(getCustomizedErrorMessagesArray(err), 10000, 'warning', true))

    }

    // here componentDidMount is particulary needed to reset the this.state.question.answers whose length must be updated in the Badge
    componentDidUpdate = (prevProps, prevState) => prevProps.question.answers !== this.props.question.answers && this.loadQuestionDetails()

    render(){


        // IMPORTANT: the semicolons are mandatory here:
        let _id, message, createdAt, thumbsUp, author, date, time, title;
        (this.state.question !== null) && ( { _id, message, createdAt, thumbsUp, author, title } = this.state?.question );
        (this.state.question !== null) && ( {date, time} = dateUnixToCustom(createdAt));
        // in order to avoid this situation, this code can be used:
            // let _id, message, createdAt, thumbsUp, author, date, time;

            // if (this.state.comment !== null) {
            //     const { _id: commentId, message: commentMessage, createdAt, thumbsUp: commentThumbsUp, author: commentAuthor } = this.state.comment;
            //     const dateTime = dateUnixToCustom(createdAt);
            //     _id = commentId
            //     message = commentMessage;
            //     thumbsUp = commentThumbsUp
            //     author = commentAuthor;
            //     date = dateTime.date;
            //     time = dateTime.time;
            // }


        return(
            !this.state.question
            ?
            <Spinner size={200}/>
            :
            <Col xs={12} style={{ margin:'0.5em 0', padding: this.state.showAnswers? '1em 0' : '0', backgroundColor: this.state.showAnswers ? 'rgba(255, 182, 6, 0.2)' : 'transparent' }} >
                <Card
                    // bg={this.state.showAnswers ? 'info' : 'light'}
                    bg='light'
                    text='dark'
                    style={{ width: '100%' }}
                    className="mb-2"
                    >
                    <Card.Header className="d-flex justify-content-between">
                        
                        {`${date}, ${time}, ${title ? title : 'Întrebare'} de ${author.username}`}
                        <div style={{ display: 'flex'}}>
                            <span>
                                <Button variant='success' style={{ margin: '0 1em' }} onClick={this.likeQuestion}>
                                    {/* <span></span>   */}
                                    <FaRegThumbsUp style={{  margin: '0 0.5em' }} />
                                    <Badge bg="light" text="success">{thumbsUp}</Badge>
                                </Button>
                            </span> 
                            
                            <span  className="btn btn-info" style={{ display: 'flex'}}>
                                <FaReplyd style={{  margin: '0 0.5em', color:'white', height:'auto' }}  />
                                <span style={{marginRight:'1em', color:'white'}}>{this.state.question.answers?.length}</span>
                                <DropDownListArrow toggleShowLectures={this.toggleShowAnswers} showLectures={this.state.showAnswers} light={true} />
                            </span>

                            
                            {
                                ( this.props.loggedUser?.role === 'admin' || this.props.loggedUser?._id === this.state.question.author._id )
                                &&                    
                                <span>
                                    <Button className="btnBlock"  style={{ margin: '0 1em' }} variant="danger"  onClick={this.deleteComment}>Delete</Button>
                                </span>
                            }

                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title></Card.Title>
                        <Card.Text>{message}</Card.Text>
                    </Card.Body>
                </Card>
                {
                    this.state.showAnswers
                    &&
                    <AnswersList questionId={_id}  loggedUser={this.props.loggedUser} 
                        handleAlert={this.props.handleAlert} fetchUser={this.props.fetchUser} 
                        refreshQuestionsList = {this.props.refreshQuestionsList}
                    />

                }
            </Col>
        )
    }

}

export default QuestionCard