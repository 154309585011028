import './PostCard.css';

import { FaRegThumbsUp, FaReplyd } from "react-icons/fa";
import { Col, Button, Badge, Card} from "react-bootstrap";
import { dateUnixToCustom, getCustomizedErrorMessagesArray } from './../../../utils/utilitaryFunctions';
import { Component } from 'react';
import BlogService from "../../../services/blog.services";
import Spinner from '../../shared/Spinner';
import RepliesList from './RepliesList';
import DropDownListArrow from '../SectionPage/DropDownListArrow';


class CommentCard extends Component{

    constructor(){
        super()
        this.state = {
            comment: null,
            showReplies: false,
            // trigger: null
        }
        this.blogService = new BlogService()
    }

    loadCommentDetails = () => {
        const comment_id = this.props.comment._id
        this.blogService
            .getComment(comment_id, true)
            .then( res => this.setState({comment: res.data}))
            .catch( err => {  
                if(err.response?.data){
                    this.props.handleAlert(err.response.data.message, 10000, 'warning', true)
                // }else if(err.response){
                //     this.props.handleAlert([err.response.data], 10000, 'warning', true)
                }else{
                    this.props.handleAlert(['The server is down'], 10000, 'danger', true)
                }  
                })
    }

    componentDidMount = () => {
        this.loadCommentDetails()
    }

    likeComment = () => {
        const comment_id = this.state.comment._id
        const {loggedUser} = this.props
        this.blogService
            .likeComment(comment_id, loggedUser)
            .then( res => {
                if( res.data.thumbsUp !== this.state.comment.thumbsUp ){
                    // this.refreshCommentCard()
                    this.loadCommentDetails()
                    this.props.fetchUser()
                }
            })
            .catch( err => {   
                if(err?.response){
                    console.log( err.response.data)
                    // this.props.handleAlert([err.response.data], 10000, 'warning', true)
                    this.props.handleAlert(err.response.data.message, 10000, 'warning', true)
                }   else{
                    this.props.handleAlert(['The server is down'], 10000, 'danger', true)
                }  
                })
    }

    // refreshCommentCard = () => {
    //     const incrementedTrigger = ++this.state.trigger
    //     this.setState({ trigger: incrementedTrigger }, () => {
    //         // The callback function is executed after the state is updated
    //         this.loadCommentDetails();
    //     });
    // }

    toggleShowReplies = () => {
        // ADDITIONAL_INFO 
        // doar cand desfasor lista cu lectures pt o noua sectiune, capul de sectiune se aliniaza, facand lectures vizibile
        // cand ascund lista de lectures nu centrez
        this.setState({showReplies: !this.state.showReplies})
    }

    deleteComment = () => {

        this.blogService
            .deleteComment(this.state.comment._id)
            .then(post => {
                this.props.refreshCommentsList()
                this.loadCommentDetails()
            })
            .catch(err => this.props.handleAlert(getCustomizedErrorMessagesArray(err), 10000, 'warning', true))

    }

    // here componentDidMount is particulary needed to reset the this.state.comment.replies whose length must be updated in the Badge
    componentDidUpdate = (prevProps, prevState) => prevProps.comment.replies !== this.props.comment.replies && this.loadCommentDetails()

    render(){


        // IMPORTANT: the semicolons are mandatory here:
        let _id, message, createdAt, thumbsUp, author, date, time;
        (this.state.comment !== null) && ( { _id, message, createdAt, thumbsUp, author } = this.state.comment );
        (this.state.comment !== null) && ( {date, time} = dateUnixToCustom(createdAt));
        // in order to avoid this situation, this code can be used:
            // let _id, message, createdAt, thumbsUp, author, date, time;

            // if (this.state.comment !== null) {
            //     const { _id: commentId, message: commentMessage, createdAt, thumbsUp: commentThumbsUp, author: commentAuthor } = this.state.comment;
            //     const dateTime = dateUnixToCustom(createdAt);
            //     _id = commentId
            //     message = commentMessage;
            //     thumbsUp = commentThumbsUp
            //     author = commentAuthor;
            //     date = dateTime.date;
            //     time = dateTime.time;
            // }


        return(
            !this.state.comment
            ?
            <Spinner size={200}/>
            :
            <Col xs={12} style={{ margin:'0.5em 0', padding: this.state.showReplies? '1em 0' : '0', backgroundColor: this.state.showReplies ? 'rgba(255, 182, 6, 0.2)' : 'transparent' }} >
                <Card
                    // bg={this.state.showReplies ? 'info' : 'light'}
                    bg='light'
                    text='dark'
                    style={{ width: '100%' }}
                    className="mb-2"
                    >
                    <Card.Header className="d-flex justify-content-between">
                        {`${date}, ${time}, by ${author.username}`}
                        <div style={{ display: 'flex'}}>
                            <span>
                                <Button variant='success' style={{ margin: '0 1em' }} onClick={this.likeComment}>
                                    {/* <span></span>   */}
                                    <FaRegThumbsUp style={{  margin: '0 0.5em' }} />
                                    <Badge bg="light" text="success">{thumbsUp}</Badge>
                                </Button>
                            </span> 
                            
                            <span  className="btn btn-info" style={{ display: 'flex'}}>
                                <FaReplyd style={{  margin: '0 0.5em', color:'white', height:'auto' }}  />
                                <span style={{marginRight:'1em', color:'white'}}>{this.state.comment.replies.length}</span>
                                <DropDownListArrow toggleShowLectures={this.toggleShowReplies} showLectures={this.state.showReplies} light={true} />
                            </span>

                            
                            {
                                ( this.props.loggedUser?.role === 'admin' || this.props.loggedUser?._id === this.state.comment.author._id )
                                &&                    
                                <span>
                                    <Button className="btnBlock" variant="danger"  onClick={this.deleteComment} style={{ margin: '0 1em' }}>Delete</Button>
                                </span>
                            }

                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title></Card.Title>
                        <Card.Text>{message}</Card.Text>
                    </Card.Body>
                </Card>
                {
                    this.state.showReplies
                    &&
                    <RepliesList commentId={_id}  loggedUser={this.props.loggedUser} 
                        handleAlert={this.props.handleAlert} fetchUser={this.props.fetchUser} 
                        refreshCommentsList = {this.props.refreshCommentsList}
                    />

                }
            </Col>
        )
    }

}

export default CommentCard