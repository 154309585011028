import React from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import UsersService from './../../../services/users.services'
import CartService from '../../../services/cart.services';

const CustomCheckoutButton = ( { cart, customer_email, checkCustomerEmail, cartItems, cartTotal, handleAlert, removeAllItems, setLocalCustomerEmail } ) => {
    
    const history = useHistory()
    const usersService = new UsersService()
    const cartService = new CartService(); 

    const handleCheckout = () => {
        if( !customer_email ){   
            checkCustomerEmail()
        }else{
            handleCustomStripeCheckout()
        }
    }

    const handleCustomStripeCheckout = () => {
        if( cartTotal !== 0 ){
            history.push('/custom-checkout')
        } else{
            cartService.getCartTotal(cart) // Use AuthService to get the cart total
                .then(response => {
                    const cartTotalFromServer = response.data.cartTotal;
                    if ( 0 === cartTotalFromServer) { // Compare the cart total with the total from the service
                        freeEnroll()
                    } else {
                        handleAlert(['Am detectat o eroare pentru cupoanele folosite. Procesarea comenzii a eșuat. Te rugăm să încerci din nou.'], 5000, 'danger');
                    }
                })
                .catch(err => {
                    console.error(err);
                    handleAlert(['Am întâmpinat dificultăți în aplicarea cupoanele folosite. Procesarea comenzii a eșuat. Te rugăm să încerci din nou.'], 5000, 'danger');
                });
            // history.push('/success')
        }

    }

    const freeEnroll = () => {
        const courses_ids = cartItems.map(course => course._id);
        const courseIdCouponPairs = cartItems.map(course => ({ courseId: course._id, couponName: course.couponName }));
        usersService
            .freeEnroll(customer_email, courses_ids, courseIdCouponPairs)
            .then( res => {
                history.push('/success')
                removeAllItems()
                setLocalCustomerEmail('')
                handleAlert([`Înscrierea a fost făcută cu succes.`], 5000, 'info')
            })
            .catch(err => {
                handleAlert(err.response.data.message, 5000, 'danger');
                // handleAlert(['Înscrierea a eșuat.'], 5000, 'danger');
                // setError(`Payment failed: ${error?.message}`);
            })
    }

    return(
        <Button 
            variant="dark" 
            onClick={() => handleCheckout()}>
            {/* CUSTOM  */}
            CHECKOUT
        </Button>
    )

}

export default CustomCheckoutButton