import { React, Component } from "react"

import ExamsListByYear from "./ExamsListByYear";
import ExamsService from "../../../services/exams.services";
import Spinner from "../../shared/Spinner";
import { Accordion} from 'react-bootstrap';
import { translateDbValues } from './../../../utils/utilitaryFunctions'

// ExamsListByTypeSubject lists all exams for given typeOfExam, major, subject 

class ExamsListByTypeSubject extends Component {
    constructor(){
        super()
        this.state = {
            yearsList: null,
            typeOfExam: null, 
            subject: null, 
        }
        this.examsService = new ExamsService()
    }

    loadYearsList(){
        const {typeOfExam, subject} = this.props    

        this.examsService
            .getYearsByTypeSubject( typeOfExam, subject )
            // .getYearsByTypeSubjectMajor( typeOfExam, subject )
            .then( years => {
                const yearsList = years.data.sort((a, b) => b - a)
                this.setState({yearsList, typeOfExam, subject})
            })
            .catch( err => {
                console.log(err)
            })
    }

    componentDidMount(){
        this.loadYearsList()
    }

    // componentDidUpdate(prevProps, prevState) {
    //     if (prevProps.typeOfExam !== this.props.typeOfExam || prevSprevPropstate.subject !== this.props.subject) {
    //         // Reload data or perform any actions needed when typeOfExam or subject changes
    //         this.loadYearsList();
    //     }
    // }


    render(){
        const {typeOfExam, subject} = this.props

        return(        
            
            this.state.yearsList === null
            ?
            <Spinner size={200}/>
            :
            <>
            <h4>{translateDbValues(subject)}</h4>
            {/* <h4>{translateDbValues(subject)}{major ? ` - ${translateDbValues(major)}` : null}</h4> */}
            <Accordion defaultActiveKey="0">
                {this.state.yearsList.map( (year, index) => <ExamsListByYear key={index} typeOfExam={typeOfExam} subject={subject}  year={year} eventKey={index}  loggedUserRole={this.props.loggedUserRole}/>)} 
            </Accordion>           
            </>
    )
    }
}

export default ExamsListByTypeSubject;
