import './PostCard.css';

import { FaRegThumbsUp } from "react-icons/fa";
import { Row, Col, Button, Badge} from "react-bootstrap";
import {Link} from 'react-router-dom'
import { dateUnixToCustom, getCustomizedErrorMessagesArray } from './../../../utils/utilitaryFunctions';

import BlogService from './../../../services/blog.services'


const PostCard = ({post, loggedUser, refreshPostsList, handleAlert}) => {

    const { _id, title, body, image, createdAt, thumbsUp } = post
    // const { _id, title, body, image, author, comment, thumbsUp, apreciatedBy, createdAt } = post
    const shortBody = body[0] ? body[0].slice(0, 30) : ''
    // console.log(createdAt)
    const {date, time} = dateUnixToCustom(createdAt)

    const blogService = new BlogService()

    const deletePost = () => {
        // this.props.refreshPostsList()

        blogService
            .deletePost(_id)
            .then(post => refreshPostsList())
            .catch(err => handleAlert(getCustomizedErrorMessagesArray(err), 10000, 'warning', true))

    }



    return(
        <Col md={6} xl={4}>
            <article className="courseCard">
                <Link to={`/blog/details/${_id}/${true}`} className='courseCardLink'>
                    <img src={image} alt={title}/>
                    <h4 style = {{height: '1em'}}>{title.length > 22 ? `${title.substring(0, 22)}... `: title}</h4>
                    <p>
                        <span>{date}</span> | <span>{time}</span> | 
                                <span style={{marginLeft:'0.5em'}}> 
                                    <Badge bg="secondary">
                                        <FaRegThumbsUp style={{  margin: '0 0.5em' }} />
                                        {thumbsUp}
                                    </Badge>
                                </span>
                    </p>
                    <p>{shortBody}...</p>
                 
                </Link>   
                <Row>
                <Col>
                <Link to={`/blog/details/${_id}/${true}`}>
                    <Button className="btnBlock" variant="warning">Detalii</Button>
                </Link>
                </Col> 
                {
                    loggedUser?.role === 'admin'
                    &&     
                    <Col>                
                    {/* <Link to={`/blog/delete/${_id}`}> */}
                        <Button className="btnBlock" variant="danger"  onClick={deletePost}>Delete</Button>
                    {/* </Link> */}
                    </Col> 
                }
                </Row>
            </article>
        </Col>
    )

}

export default PostCard