import { Component } from "react";
import CouponsService from "../../../services/coupons.services";
import Spinner from './../../shared/Spinner'

import { Row } from 'react-bootstrap'
import CouponCard from "./CouponCard";

class CouponsList extends Component{

    constructor(){
        super()
        this.state = {
            coupons: undefined
        }
        this.couponsService = new CouponsService()
    }

    loadCoupons = () => {
        this.couponsService
            .getAllCoupons()
            .then( res => this.setState({coupons: res.data}))
            .catch( err => {   
                if(err.response?.data?.message){
                    console.log(err.response.data.message)
                    this.props.handleAlert(err.response.data.message, 10000, 'warning', true)
                }   else{
                    this.props.handleAlert(['The server is down'], 10000, 'danger', true)
                }  
                })
    }

    componentDidMount = () => {
        this.loadCoupons()
    }

    render(){

        const couponsList = !this.state.coupons
            ?
            // <h1>waiting2...</h1>
            <Spinner size={200}/>
            :
            <Row>
                {this.state.coupons?.map( elm => 
                    <CouponCard loggedUser={this.props.loggedUser} key = {elm._id} coupon={{...elm}}/>
                )}
            </Row>

        return(
            <>
                {couponsList}
            </>
            
            
        )
    }
}

export default CouponsList