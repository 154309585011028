import { Component } from "react";
import BlogService from "../../../services/blog.services";
import Spinner from './../../shared/Spinner'

import { Row } from 'react-bootstrap'
import PostCard from "./PostCard";

class PostsList extends Component{

    constructor(){
        super()
        this.state = {
            posts: undefined
        }
        this.blogService = new BlogService()
    }

    loadPosts = () => {
        this.blogService
            .getAllPosts()
            .then( res => {
                // console.log('########################################## loadPosts')
                this.setState({posts: res.data})})
            .catch( err => {   
                if(err.response){
                    this.props.handleAlert([err.response.data], 10000, 'warning', true)
                }   else{
                    this.props.handleAlert(['The server is down'], 10000, 'danger', true)
                }  
                })
    }

    componentDidMount = () => {
        this.loadPosts()
    }

    render(){

        const postsList = !this.state.posts
            ?
            <Spinner size={200}/>
            :
            <Row>
                {this.state.posts?.map( elm => 
                    <PostCard  loggedUser={this.props.loggedUser} 
                        refreshPostsList = {this.loadPosts}
                        handleAlert = {this.props.handleAlert}
                        key = {elm._id} post={{...elm}}
                        />)}
            </Row>

        return(
            <>
                {postsList}
            </>
            
            
        )
    }
}

export default PostsList