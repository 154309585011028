import './PostCard.css';

import { FaRegThumbsUp } from "react-icons/fa";
import { Col, Button, Badge, Card} from "react-bootstrap";
import { dateUnixToCustom } from '../../../utils/utilitaryFunctions';
import { Component } from 'react';
import BlogService from "../../../services/blog.services";
import Spinner from '../../shared/Spinner';
import {getCustomizedErrorMessagesArray} from './../../../utils/utilitaryFunctions'


class ReplyCard extends Component{

    constructor(){
        super()
        this.state = {
            reply: null,
        }
        this.blogService = new BlogService()
    }

    loadReplyDetails = () => {
        const reply_id = this.props.reply._id
        this.blogService
            .getReply(reply_id, true)
            .then( res => this.setState({reply: res.data}))
            .catch( err => {  
                if(err.response?.data){
                    this.props.handleAlert(err.response.data.message, 10000, 'warning', true)
                // }else if(err.response){
                //     this.props.handleAlert([err.response.data], 10000, 'warning', true)
                }else{
                    this.props.handleAlert(['The server is down'], 10000, 'danger', true)
                }  
                })
    }

    componentDidMount = () => {
        this.loadReplyDetails()
    }

    likeReply = () => {
        const reply_id = this.state.reply._id
        const {loggedUser} = this.props
        this.blogService
            .likeReply(reply_id, loggedUser)
            .then( res => {
                if( res.data.thumbsUp !== this.state.reply.thumbsUp ){
                    this.loadReplyDetails()
                    this.props.fetchUser()
                }
            })
            .catch( err => {   
                if(err?.response){
                    console.log( err.response.data)
                    // this.props.handleAlert([err.response.data], 10000, 'warning', true)
                    this.props.handleAlert(err.response.data.message, 10000, 'warning', true)
                }   else{
                    this.props.handleAlert(['The server is down'], 10000, 'danger', true)
                }  
                })
    }

    // refreshCommentCard = () => {
    //     const incrementedTrigger = ++this.state.trigger
    //     this.setState({ trigger: incrementedTrigger }, () => {
    //         // The callback function is executed after the state is updated
    //         this.loadCommentDetails();
    //     });
    // }

    deleteReply = () => {

        this.blogService
            .deleteReply(this.state.reply._id)
            .then(post => {
                this.props.refreshCommentsList()
                this.props.refreshRepliesList()                
            })
            .catch(err => this.props.handleAlert(getCustomizedErrorMessagesArray(err), 10000, 'warning', true))

    }

    render(){


        // IMPORTANT: the semicolons are mandatory here:
        let message, createdAt, thumbsUp, author, date, time;
        (this.state.reply !== null) && ( { message, createdAt, thumbsUp, author } = this.state.reply );
        (this.state.reply !== null) && ( {date, time} = dateUnixToCustom(createdAt));


        return(
            !this.state.reply
            ?
            <Spinner size={200}/>
            :
            <Col xs={12}>
                <Card
                    bg='light'
                    text='dark'
                    style={{ width: '100%' }}
                    className="mb-2"
                    >
                    <Card.Header className="d-flex justify-content-between">
                        {`${date}, ${time}, by ${author.username}`}
                        <div style={{ display: 'flex'}}>
                            <Button variant="success" style={{marginLeft:'1em'}} onClick={this.likeReply}>
                                {/* <span></span>   */}
                                <FaRegThumbsUp style={{  margin: '0 0.5em' }} />
                                <Badge bg="secondary">{thumbsUp}</Badge>
                            </Button>

                                
                            {
                                ( this.props.loggedUser?.role === 'admin' || this.props.loggedUser?._id === this.state.reply.author._id )
                                &&                    
                                <span>
                                    <Button className="btnBlock" variant="danger" style={{ margin: '0 1em' }} onClick={this.deleteReply}>Delete</Button>
                                </span>
                            }
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <Card.Title></Card.Title>
                        <Card.Text>{message}</Card.Text>
                    </Card.Body>
                </Card>

            </Col>
        )
    }

}

export default ReplyCard