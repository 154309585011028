import { Switch, Route } from 'react-router';

import IndexPage from './../pages/IndexPage/IndexPage';
import CoursesPage from './../pages/CoursesPage/CoursesPage';
import CourseDetails from '../pages/CourseDetails/CourseDetails';
import CourseForm from '../pages/CourseForm/CourseForm';
import CourseEditForm from '../pages/CourseForm/CourseEditForm';
import Signup from '../pages/SignUp/SignUp';
import LogIn from '../pages/LogIn/LogIn';
import UserDetails from '../pages/UserDetails/UserDetails';
import UserEditForm from '../pages/UserDetails/UserEditForm';
import NotFound from '../pages/NotFoundPage/NotFoundPage';
import CartPage from '../pages/Cart/CartPage';
import Legal from '../pages/Legal/Legal'
import privacyPolicyContent from '../pages/Legal/PrivacyPolicy.json';
import termsAndConditions from '../pages/Legal/TermsAndConditions.json';
import Faq from '../pages/Faq/Faq';
import AboutUs from '../pages/AboutUs/AboutUs';
import RecoverPassword from '../pages/LogIn/RecoverPassword';
import ResetPassword from '../pages/LogIn/ResetPassword';
import ContactForm from '../pages/ContactForm/ContactForm';
import CheckoutSuccess from '../pages/Checkout/CheckoutSuccess';
import CheckoutCanceled from '../pages/Checkout/CheckoutCanceled';
import CustomCheckout from '../pages/Cart/CustomCheckout';
import ExamsPage from '../pages/Exams/ExamsPage';
import ExamNewForm from '../pages/Exams/ExamNewForm';
import CouponNewForm from '../pages/Coupons/CouponNewForm';
import CouponsPage from '../pages/Coupons/CouponsPage';
import BlogPage from './../pages/BlogPage/BlogPage'
import PostDetails from '../pages/BlogPage/PostDetails';
import NewPostForm from '../pages/BlogPage/NewPostForm';



const Routes = ({ storeUser, loggedUser, handleAlert, addToCart, cart, cartFromStorage, removeItem, removeAllItems, updateLoggedUser, fetchUser, 
    setCurrentVideoUrl, setCurrentLecture, 
    isMobileLandscape, isMobile, setIsCourseDetailsDisplayed, applyDiscount, currentVideoUrl, currentLecture }) => {

    return(
        <>
            <Switch>

                <Route path = '/' exact render = { () => <IndexPage loggedUser={loggedUser}/> } />

                <Route path = '/courses' exact render = { (props) => <CoursesPage addToCart={addToCart} removeItem={removeItem} 
                    loggedUser={loggedUser} handleAlert={handleAlert}/> } />
                <Route path = '/courses/details/:course_id' render = { props => <CourseDetails {...props} loggedUser={loggedUser}
                     setCurrentVideoUrl={setCurrentVideoUrl} isMobileLandscape={isMobileLandscape} isMobile={isMobile} 
                     setIsCourseDetailsDisplayed={setIsCourseDetailsDisplayed}
                     currentVideoUrl={currentVideoUrl} currentLecture={currentLecture}
                     setCurrentLecture={setCurrentLecture} handleAlert={handleAlert}
                     fetchUser={fetchUser}/> } />
                <Route path = '/courses/new' render = { (props) => <CourseForm {...props} loggedUser={loggedUser}/> } />
                <Route path = '/courses/edit/:course_id/:populated' render = { (props) => <CourseEditForm {...props} loggedUser={loggedUser}/> } />

                <Route path = '/signUp' render = { (props) => <Signup {...props} handleAlert={handleAlert} /> } />
                <Route path = '/logIn' render = { (props) => <LogIn {...props} storeUser={storeUser} handleAlert={handleAlert}/> } />
                <Route path = '/recover-password' render = { (props) => <RecoverPassword {...props} handleAlert={handleAlert}/>} />
                {/* <Route path = '/recover-password' render = { (props) => <RecoverPassword {...props} handleAlert={handleAlert} expireDate={expireDate}/>} /> */}
                <Route path = '/reset-password/:email/:token' render = { (props) => <ResetPassword {...props} handleAlert={handleAlert}/>} />

                <Route path = '/contact/:subject/:email?/:token?/:preSubject?' render = { (props) => <ContactForm {...props} handleAlert={handleAlert}/> } />

                <Route path = '/users/:userId/edit'  render = { (props) => 
                    loggedUser && loggedUser._id === props.match.params.userId 
                    ? 
                    <UserEditForm {...props} handleAlert={handleAlert}/> 
                    : 
                    <CoursesPage loggedUser={loggedUser} handleAlert={handleAlert}/> } 
                />
                <Route path = '/users/:userId'  render = { (props) => 
                    loggedUser && loggedUser._id === props.match.params.userId 
                    ?  
                    <UserDetails {...props} handleAlert={handleAlert}/> 
                    : 
                    <CoursesPage loggedUser={loggedUser} handleAlert={handleAlert}/> } 
                />

                <Route path = '/cart' render = { (props) => 
                    <CartPage storeUser={storeUser} loggedUser={loggedUser} 
                    cart = {cart} cartFromStorage={cartFromStorage} removeItem={removeItem} removeAllItems={removeAllItems}
                    handleAlert = {handleAlert} applyDiscount={applyDiscount}/> } />
                <Route path = '/custom-checkout' render = { () => <CustomCheckout cart = {cart} cartFromStorage={cartFromStorage} handleAlert = {handleAlert} 
                    loggedUser={loggedUser}
                    removeAllItems={removeAllItems} /> } />
                <Route path = '/success' render = { () => <CheckoutSuccess fetchUser={fetchUser}/> } />
                <Route path = '/canceled' render = { () => <CheckoutCanceled/> } />

                <Route path = '/privacy-policy' render = { () => <Legal sectionTitleSize='1.3em' sections={privacyPolicyContent.sections} title = 'Politica de confidențialitate'/>}/>
                <Route path = '/terms-of-use' render = { () => <Legal sectionTitleSize='1.3em' sections={termsAndConditions.sections} title = 'Termeni și condiții'/>}/>
                <Route path = '/faqs' render = { () => <Faq title = 'Întrebări frecvente' subtitle = 'Găsește aici răspunsul de care ai nevoie'/>}/>

                <Route path = '/about-us' render = { () => <AboutUs/>}></Route>
                
                <Route path = '/exams/new' render = { () => loggedUser ? <ExamNewForm/> : <NotFound/>}></Route>
                <Route path = '/exams/:typeOfExam' exact render = { (props) => <ExamsPage {...props} 
                    loggedUserRole={loggedUser?.role}
                    loggedUserId={loggedUser?._id} 
                    loggedUserSpecialization={loggedUser?.specialization}
                    handleAlert={handleAlert} fetchUser={fetchUser}/>}></Route>

                <Route path = '/coupons/new' render = { (props) => 
                    loggedUser && ( loggedUser.role === 'admin' || loggedUser.role === 'author' ) 
                    ? <CouponNewForm {...props} loggedUser={loggedUser} handleAlert = {handleAlert}/> 
                    : <NotFound/>}>
                </Route>
                <Route path = '/coupons' exact render = { (props) => <CouponsPage loggedUser={loggedUser} handleAlert={handleAlert}/> } />

                <Route path = '/blog' exact render = { (props) => <BlogPage loggedUser={loggedUser} handleAlert={handleAlert}/> } />

<Route path = '/blog/new' render = { (props) => 
    loggedUser && ( loggedUser.role === 'admin' || loggedUser.role === 'author' ) 
    ? <NewPostForm {...props} loggedUser={loggedUser} handleAlert = {handleAlert}/> 
    : <NotFound/>}>
</Route>

                <Route path = '/blog/details/:post_id/:populated'  render = { (props) => <PostDetails {...props}  handleAlert={handleAlert}
                    loggedUser={loggedUser}  fetchUser={fetchUser}/> } />
                
                <Route path = '*' render = { () => <NotFound/> } />
            
            </Switch>
        </>        
    )
}

export default Routes