// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CurriculumLectureModal_backdrop__6Tmlx {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  z-index: 10;\n  background: rgba(0, 0, 0, 0.75);\n}\n\n.CurriculumLectureModal_modal__gv8kp {\n  position: fixed;\n  top: 30vh;\n  left: 10%;\n  width: 80%;\n  z-index: 100;\n  overflow: hidden;\n}\n\n.CurriculumLectureModal_header__3g3_z {\n  background: #4f005f;\n  padding: 1rem;\n}\n\n.CurriculumLectureModal_header__3g3_z h2 {\n  margin: 0;\n  color: white;\n}\n\n.CurriculumLectureModal_content__2u3jZ {\n  padding: 1rem;\n}\n\n.CurriculumLectureModal_actions__2pUyu {\n  padding: 1rem;\n  display: flex;\n  justify-content: flex-end;\n}\n\n@media (min-width: 768px) {\n  .CurriculumLectureModal_modal__gv8kp {\n    left: calc(50% - 20rem);\n    width: 40rem;\n  }\n}\n", "",{"version":3,"sources":["webpack://src/components/pages/CourseDetails/CurriculumLectureModal.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,aAAa;EACb,WAAW;EACX,+BAA+B;AACjC;;AAEA;EACE,eAAe;EACf,SAAS;EACT,SAAS;EACT,UAAU;EACV,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,SAAS;EACT,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE;IACE,uBAAuB;IACvB,YAAY;EACd;AACF","sourcesContent":[".backdrop {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100vh;\n  z-index: 10;\n  background: rgba(0, 0, 0, 0.75);\n}\n\n.modal {\n  position: fixed;\n  top: 30vh;\n  left: 10%;\n  width: 80%;\n  z-index: 100;\n  overflow: hidden;\n}\n\n.header {\n  background: #4f005f;\n  padding: 1rem;\n}\n\n.header h2 {\n  margin: 0;\n  color: white;\n}\n\n.content {\n  padding: 1rem;\n}\n\n.actions {\n  padding: 1rem;\n  display: flex;\n  justify-content: flex-end;\n}\n\n@media (min-width: 768px) {\n  .modal {\n    left: calc(50% - 20rem);\n    width: 40rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": "CurriculumLectureModal_backdrop__6Tmlx",
	"modal": "CurriculumLectureModal_modal__gv8kp",
	"header": "CurriculumLectureModal_header__3g3_z",
	"content": "CurriculumLectureModal_content__2u3jZ",
	"actions": "CurriculumLectureModal_actions__2pUyu"
};
export default ___CSS_LOADER_EXPORT___;
