import axios from "axios";

class EmailService {
    
    constructor(){
        this.app = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL + '/contact',
            withCredentials: true
        })
    }

    sendContactEmail = ( email, token, subject, message, preSubject) => this.app.post('/contactEmail', {email, token, subject, message, preSubject})
    sendEmail = ( email, token, subject, message, preSubject) => this.app.post('/sendEmail', {email, token, subject, message, preSubject})

    //TTIA: REPLACE THE FOLLOWING SERVICES WITH sendEmail 
    sendWelcomeEmail = (name, email, token, message) => this.app.post('/welcomeEmail', {name, email, token, message})

    sendRecoverPasswordEmail = (email, token, message, preSubject) => this.app.post('/recoverPassword', {email, token, message, preSubject})

}

export default EmailService