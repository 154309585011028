import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import RedirectToHTTPS from './components/RedirectToHTTPS';
import {BrowserRouter as Router} from 'react-router-dom'

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <RedirectToHTTPS/>
      <App/>
    </React.StrictMode>
  </Router>
,
  document.getElementById('root')
);

