import React from "react";

import { Card } from "react-bootstrap";
// import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter, FaYoutube, FaTiktok } from "react-icons/fa";
import { FaFacebook, FaInstagram, FaLinkedin, FaYoutube, FaTiktok } from "react-icons/fa";
import { FaXTwitter} from "react-icons/fa6";
import './UserCard.css'

const UserCard = () => {

    return(
        <Card style={{ width: '18rem' }} className='user-card'>
            <div className="overlay-container">
                <Card.Img variant="top" src="https://res.cloudinary.com/dvz9tjidq/image/upload/v1694939277/silviu-dilimot-profile-pic_nw4dry.jpg" 
                    className="user-image"/>
                <div className="user-image overlay">
                    <a href="https://www.facebook.com/MateCuMatei/" target="_blank" rel="noopener noreferrer">
                    <FaFacebook />
                    </a>
                    <a href="https://www.instagram.com/matecumatei/" target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                    </a>
                    <a href="https://www.linkedin.com/in/silviu-dilimot/" target="_blank" rel="noopener noreferrer">
                    <FaLinkedin />
                    </a>
                    <a href="https://twitter.com/MateiCu" target="_blank" rel="noopener noreferrer">
                    <FaXTwitter />
                    </a>
                    <a href="https://www.youtube.com/matecumatei/playlists" target="_blank" rel="noopener noreferrer">
                    <FaYoutube />
                    </a>
                    <a href="https://www.tiktok.com/@matecumatei" target="_blank" rel="noopener noreferrer">
                    <FaTiktok />
                    </a>
                </div>
            </div>
            

            <Card.Body>
                <Card.Title>Silviu</Card.Title>
                <Card.Text>FONDATOR, WEB DEVELOPER, INSTRUCTOR</Card.Text>
            </Card.Body>
        
        </Card>
    )

}

export default UserCard