import React from 'react';
import {Container, Button} from 'react-bootstrap'
// import {Container} from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
// import { useHistory, Link } from 'react-router-dom'
import './IndexPage.css'
import './../CoursesPage/CoursePage.css';
// import ghostImage from './../../shared/Halloween/ghost01.svg';
// import pumpkinImage from '../../shared/Halloween/pumpkin01.svg';
// import Halloween from './../../shared/Halloween/Halloween'
// import AnimatedSvgImage from '../../shared/Halloween/AnimatedSvgImage';
// import santaSleigh from './../../shared/Halloween/santa/f-santa-sleigh-gifts-presents-bag-svgrepo-com.svg'
// import reindeer from './../../shared/Halloween/santa/f-reindeer-6-svgrepo-com.svg'

// import fireworks from './../../shared/Halloween/newYear/button-fireworks-svgrepo-com.svg'
// import hearts from './../../shared/Halloween/newYear/button-hearts-fireworks-rocket-svgrepo-com.svg'
// import QuestionsList from './QuestionsList';
import FreeCourses from './FreeCourses';

const IndexPage = ({loggedUser}) => {

    const history = useHistory();

    const handleAllCoursesButton = () => {
        history.push('/courses');
    };

    return (

        // <Container className="bgImg" fluid>   
        <Container className="bgImg d-flex align-items-center justify-content-center " style={{ color: 'black', opacity:'1'}}>

            {/* <Halloween/>      */}

            {/* <img id="ghost" src={ghostImage} alt="Ghost" className="floating-ghost"  width="100" height="100" style={{opacity:'0'}}/>
            <img id="pumpkin" src={pumpkinImage} alt="Pumpkin" className="floating-pumpkin"  width="100" height="100" style={{opacity:'0'}}/> */}
            
            <div className="d-flex flex-column justify-content-start align-items-center  mt-5">
                <h1 className="text-center mb-4">Bine ai venit pe MateCuMatei{loggedUser ? `, ${loggedUser.username?.trim().split(' ')[0]}!` : '!'}</h1>
                {
                    !loggedUser
                    ?
                    // <h3 className="text-center mb-4">Autentifică-te pentru a accesa conținutul cursurilor.</h3>
                    <FreeCourses/>
                    :
                    null
                }
                {/* <h6 className="text-center mb-4">Momentan recomandăm Mozilla Firefox.</h6> */}

                {/* <QuestionsList/> */}

{/* BUTON TRICOLOR */}
    {/* <a
        href={`https://matecumatei.com/courses`}
        className="btn btnBlock mb-2 custom-button"
        // target="_blank"
        rel="noopener noreferrer"
        style={{ padding: '0', width: '10em' }}
    >
        <div className="color-section" style={{ backgroundColor: '#002780' }}></div>
        <div className="color-section" style={{ backgroundColor: '#ffcc29' }}></div>
        <div className="color-section" style={{ backgroundColor: '#d7142c' }}>
            <span className="button-text">Vezi cursuri</span>
        </div>
    </a> */}


{/* 25th of December - Christmas */}
                {/* <Button className="btn bg-secondary mb-4"  onClick={handleAllCoursesButton} style={{zIndex:1}}>Vezi cursuri</Button> */}
                {/* <Button className="btn bg-danger mb-4"  onClick={handleAllCoursesButton} style={{zIndex:1}}>
                    <AnimatedSvgImage image={reindeer} cssAndAnimation={'christmas-logo'} opacityParam={'1'} />
                    <span style={{paddingRight: '0.5em'}}>Vezi cursuri</span>
                    <AnimatedSvgImage image={santaSleigh} cssAndAnimation={'christmas-logo'} opacityParam={'1'} />
                </Button>
                <h3 className="text-center mb-4">MateCuMatei - Idei, nu formule.</h3> */}


                {/* Overlapping image with transparency */}
                {/* <div className="overlay-image"></div>    */}


{/* 31st of December - The New Year's Eve */}
                {/* <Button className="btn bg-danger mb-4"  onClick={handleAllCoursesButton} style={{zIndex:1}}>
                    <AnimatedSvgImage image={fireworks} cssAndAnimation={'christmas-logo'} opacityParam={'1'} />
                    <span style={{paddingRight: '0.5em'}}>Vezi toate cursurile</span>
                    <AnimatedSvgImage image={hearts} cssAndAnimation={'christmas-logo'} opacityParam={'1'} />
                </Button> */}


                <Button className="btn bg-dark mb-4 mt-4"  onClick={handleAllCoursesButton} style={{zIndex:1}}>
                    <span style={{paddingRight: '0.5em'}}>Vezi cursuri</span>
                </Button>

                <h3 className="text-center mb-4">MateCuMatei - Idei, nu formule.</h3>


            </div>
        </Container>
        
    )
}

export default IndexPage