import axios from 'axios'

class StripeService {

    constructor() {
        this.app = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL + '/stripe',
            // baseURL: 'https://matecumatei-server.herokuapp.com/api/',
            // baseURL: 'http://localhost:5000/api/',
            withCredentials: true
        })
    }

    defaultStripeCheckout = ( options ) => this.app.post('/checkout', { options })
        // options = { customer_email, line_items }
    customStripeCheckout = ( receipt_email, cart_items, description ) => this.app.post('/custom-checkout', {...{receipt_email, cart_items, description}})
    // customStripeCheckout = ( options ) => this.app.post('/custom-checkout', { options })
        // options = { receipt_email, cart_items, description }
    // customStripeCheckout = ( options ) => this.app.post('/custom-checkout', { options })
}

export default StripeService