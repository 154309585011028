const removeRecaptchaScript = () => {
    // Find the reCAPTCHA script element in the DOM
    const recaptchaScript = document.querySelector('script[src*="recaptcha/api.js"]');
    
    // If the script element exists, remove it from the DOM
    if (recaptchaScript) {
        recaptchaScript.parentNode.removeChild(recaptchaScript);
    }
};

const removeRecaptchaElements = () => {
    // Remove reCAPTCHA script
    removeRecaptchaScript()

    // Remove reCAPTCHA badge or any other reCAPTCHA-related elements
    removeRecaptchaBadge()
};

const removeRecaptchaBadge = () => {
    // Find the reCAPTCHA badge element in the DOM
    const recaptchaBadge = document.querySelector('.grecaptcha-badge');
    
    // If the badge element exists, remove it from the DOM
    if (recaptchaBadge) {
        recaptchaBadge.parentNode.removeChild(recaptchaBadge);
    }
};

export { removeRecaptchaScript, removeRecaptchaElements, removeRecaptchaBadge };
// export default removeRecaptchaScript