import { Component } from "react";
import BlogService from "../../../services/blog.services";
import Spinner from '../../shared/Spinner'

import { Row } from 'react-bootstrap'
import ReplyCard from "./ReplyCard";
import NewReplyForm from "./NewReplyForm";

class RepliesList extends Component{

    constructor(){
        super()
        this.state = {
            replies: undefined,
        }
        this.blogService = new BlogService()
    }

    loadReplies = () => {
        // const {post_id} = this.props.match.query
        const {commentId} = this.props
        this.blogService
            .getAllReplies(commentId)
            .then( res => this.setState({replies: res.data}))
            .catch( err => {   
                if(err.response){
                    this.props.handleAlert([err.response.data], 10000, 'warning', true)
                }   else{
                    this.props.handleAlert(['The server is down'], 10000, 'danger', true)
                }  
                })
    }

    componentDidMount = () => {
        this.loadReplies()
    }

    refreshReplies = () => {
        this.loadReplies()
    }


    render(){

        const repliesList = 
            !this.state.replies
            ?
            <Spinner size={200}/>
            :
                this.state.replies.length 
                ?
                <div style={{ margin: '0 2em' }}>
                
                    {/* <h5>Replici:</h5> */}
                    <Row>
                        {this.state.replies?.map( elm => 
                            <ReplyCard  loggedUser={this.props.loggedUser} handleAlert={this.props.handleAlert} fetchUser={this.props.fetchUser}                           
                                refreshCommentsList = {this.props.refreshCommentsList}
                                refreshRepliesList={this.loadReplies}
                                key = {elm._id} reply={{...elm}}/>)}
                    </Row>                
                </div>
                :
                null

        return(
            <>
                {repliesList}
                <NewReplyForm postId={this.props.commentId} maxNumberOfChars={200} loggedUser={this.props.loggedUser}                            
                    refreshCommentsList = {this.props.refreshCommentsList}
                    refreshRepliesList={this.loadReplies}
                    handleAlert={this.props.handleAlert} 
                    />
            </>
            
            
        )
    }
}

export default RepliesList