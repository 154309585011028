// import React from "react";
import { React, Component } from "react";
import { ListGroup, Accordion} from 'react-bootstrap'; // Make sure to import any required components

// import Hero from "../../layout/Hero/Hero";
// import Quote from "../../shared/Quote/Quote";
// import UserCard from "../UserCard/UserCard";

import ExamTestUnit from "./ExamTestUnit";
import ExamsService from "../../../services/exams.services";
import Spinner from "../../shared/Spinner";

class ExamsListByYear extends Component {
    constructor(){
        super()
        this.state = {
            exams: undefined,
            subject: null,
        }
        this.examsService = new ExamsService()
    }

    loadExams(){
        const {typeOfExam, subject, year} = this.props    
        // console.log('wqertyrewqer   ', typeOfExam, year)

        this.examsService
            .getAllExamsByYearTypeSubject(year, typeOfExam, subject )
            .then( exams => {
                // console.log(exams)
                this.setState({exams: exams.data, subject: subject})
            })
            .catch( err => {
                console.log(err)
            })
    }

    componentDidMount(){
        this.loadExams()
    }

    componentDidUpdate(prevProps, prevState) {
        if ( prevProps.subject !== this.props.subject) {
            // Reload data or perform any actions needed when typeOfExam or subject changes
            this.loadExams();
        }
    }


    render(){
        const {year, eventKey} = this.props

        return(        
            
            !this.state.exams
            ?
            <Spinner size={200}/>
            :
            <>

                {/* <Hero title="Vino si tu in randul elevilor de succes!">
                    <a href="https://academia.matecumatei.ro/" target="_blank" rel="noopener noreferrer">
                        <strong className="h1_red">Academia MateCuMatei</strong> si-a deschis portile &#128512;
                    </a>
                </Hero> */}


                {/* <Accordion defaultActiveKey="0"> */}
                    <Accordion.Item eventKey={eventKey}>
                        {/* <Accordion.Header>2222</Accordion.Header> */}
                        <Accordion.Header>{year}</Accordion.Header>
                        <Accordion.Body>
                            <ListGroup variant="flush">
                                {
                                this.state.exams.map( (exam, key) => <ExamTestUnit key={key} exam={{...exam}}  loggedUserRole={this.props.loggedUserRole}/>  )
                                }
                                {/* <ExamTestUnit exam={{
                                    name: 'mateinfo Test16',
                                    exercices: 'https://matecumatei.ro/wp-content/uploads/2020/TesteAntrenament/MI-16.pdf',
                                    markingScheme: 'https://matecumatei.ro/wp-content/uploads/2020/TesteAntrenamentBareme/2020-MI-barem-16.pdf',
                                    videoSolution: 'https://www.youtube.com/watch?v=iiea87dJWfw&list=PLDmAuNsz7DFQJlOGRUJd8ypKqi-C_qjEy&index=178'
                                }}/> */}
                            </ListGroup>
                        </Accordion.Body>
                    </Accordion.Item>
                {/* </Accordion> */}
            </>
        )
                            }
}

export default ExamsListByYear;
