// import React from "react"
import manageAccount from './ManageAccount.json';
import paymentAndRefund from './PaymentAndRefund.json';
import './Faq.css'

import Hero from '../../layout/Hero/Hero';
import FrequentlyAskedQuestion from './FrequentlyAskedQuestion';

const Faq = ({title, subtitle}) => {

    return(

        <div className="faq-page-container">
            <Hero title = {title} subtitle = {subtitle}/>
            {/* <Hero title = 'Întrebări frecvente' subtitle = 'Găsește aici răspunsul de care ai nevoie'/> */}
            {/* <div className="faq-title-container">
                <h1>Întrebări frecvente</h1>
                <h4>Găsește aici răspunsul de care ai nevoie</h4>
            </div> */}

            <section className='faq-section-container'>

                <div className="manage-account-container">
                    <div className="title-manage-account">
                        <h1>{manageAccount.title}</h1>
                    </div>
                    <div className="content-container">
                        {manageAccount.sections.map((section, index) => (
                            <FrequentlyAskedQuestion key={index} section={section}/>
                        ))}
                    </div>
                </div>
            
                <div className="payment-and-refund-container">
                    <div className="title-payment-and-refund">
                        <h1>{paymentAndRefund.title}</h1>
                    </div>
                    <div className="content-container">
                        {paymentAndRefund.sections.map((section, index) => (
                            <FrequentlyAskedQuestion key={index} section={section}/>
                        ))}
                    </div>
                </div>

            </section>
        
        
        </div>

    )
}

export default Faq