import { Component } from 'react'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
// import { Link } from 'react-router-dom'
import './LogIn.css'

// import AuthService from '../../../services/auth.services'
import EmailService from '../../../services/email.services'
import AuthService from '../../../services/auth.services'
import jsxToHtml from '../../../utils/jsxToHtml'
import RecoverPasswordEmailTemplate from '../EmailTemplates/RecoverPasswordEmailTemplate'

class RecoverPassword extends Component {

    constructor() {
        super()
        this.state = {
            email: ''
        }
        this.emailService = new EmailService()
        this.authService = new AuthService()
    }


    handleInputChange = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }


    handleFormSubmit = e => {

        e.preventDefault()

        const { email} = this.state
        // const RecoverPasswordEmailTemplate_Html = jsxToHtml( <RecoverPasswordEmailTemplate email={email} toke = {token}/> )

        this.authService
            .generateToken(email)
            .then(res => {
                const {token} = res.data
                const emailRecoverPreSubject = 'MCM_S: '
                const RecoverPasswordEmailTemplate_Html = jsxToHtml( <RecoverPasswordEmailTemplate email={email} token = {token}/> )

                // console.log( `***************************************
                // email: ${email}, ***************************************message: ${RecoverPasswordEmailTemplate_Html}***************************************`)

                // const {token, expireDate} = res.data
                // const RecoverPasswordEmailTemplate_Html = jsxToHtml( <RecoverPasswordEmailTemplate email={email} token = {token} expireDate = {expireDate}/> )

                this.emailService
                    // .sendRecoverPasswordEmail(email, token, RecoverPasswordEmailTemplate_Html, 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNpbHZpdWRpbGltb3RAZ21haWwuY29tIiwiaWF0IjoxNjk1MzYwMjY3LCJleHAiOjE2OTUzNjAzODd9.skk5xeXSYy_XRQEV3pHQyTkRgaWXnEYJ6k8bWaUcN0o')
                    .sendRecoverPasswordEmail(email, token, RecoverPasswordEmailTemplate_Html, emailRecoverPreSubject)
                    .then(() => {
                        this.props.history.push('/login')         // Redirect with RRD props
                        this.props.handleAlert([`Un link de resetare a parolei a fost trimis la adresa ${email}`], 10000, 'warning', true)
                    })
                    .catch(err => {
                        this.props.handleAlert(err.response?.data.message ? 
                            err.response?.data.message : [err.response?.statusText], 10000, 'warning', true) // TEST: err.response?.statusText is not always defined
                        // this.props.handleAlert([err.response?.statusText], 10000, 'warning', true) // TEST: err.response?.statusText is not always defined
                    })

            })
            .catch(err => {
                this.props.handleAlert([err.response?.statusText], 10000, 'warning', true)
            })
            
    }



    render() {
        return (

            <Container>

                <Row>

                    <Col md={{ span: 4, offset: 4 }}>

                        <h1>Am uitat parola</h1>

                        <hr></hr>

                        <Form onSubmit={this.handleFormSubmit}>

                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text" value={this.state.email} onChange={this.handleInputChange} name="email" />
                            </Form.Group>

                            <Button style={{ marginTop: '20px', width: '100%' }} variant="dark" type="submit">Resetează parola</Button>

                        </Form>

                    </Col>
                </Row>

                {/* <RecoverPasswordEmailTemplate email={this.state.email}/> */}

            </Container >

        )
    }
}


export default RecoverPassword