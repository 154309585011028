const startDate = undefined
const endDate = undefined

const maintenanceMessages = {
    generalMaintenance: {
      title: `Mesaj tehnic ${  (startDate && endDate) ? `${startDate} - ${endDate}` : ''}  `,
      message: `Realizăm operațiuni de mentenanță care pot genera întreruperi în fluxul normal de activitate pe site.
      Ne pare rău pentru orice inconveniență și apreciem înțelegerea ta. 
      Lucrăm pentru a asigura confortul tău.`,
    },
    cartMaintenance: {
      title: 'Mesaj tehnic',
      message: `Realizăm operațiuni de mentenanță pentru a asigura confortul tău. 
        ${  (startDate && endDate)
        ?
        `În intervalul ${startDate} - ${endDate}, înscrierea la cursuri și coșul de cumpărături nu vor fi disponibile.`
        :
        `Înscrierea la cursuri și coșul de cumpărături nu vor fi disponibile.`
        }         
        Cu toate acestea, toate celelalte funcționalități ale site-ului vor fi accesibile în mod obișnuit. 
        Ne pare rău pentru orice inconveniență și apreciem înțelegerea ta.`,
    },
  };
  
  export default maintenanceMessages;