// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CourseDetails_stickyVideo__DXlKE {\n  z-index: 1;\n  top: 3.5em;\n}\n\n@media (max-width: 768px){\n  .CourseDetails_stickyVideo__DXlKE {\n    position: sticky;\n    /* top: 0; */\n    top: 3.5em;\n    z-index: 1;\n  }\n}\n\n.CourseDetails_horrizontalVideo__1QAEE{\n  padding: 0;\n}\n\n.CourseDetails_fixedSectionsList__18FuA{\n  /* z-index: 1; */\n  position: fixed;\n  /* padding-right: 1em; */\n}\n\n/* @media (orientation: portrait) {\n  .video-container {\n    display: block;\n  }\n  .sections-container {\n    display: block;\n  }\n}\n\n@media (orientation: landscape) {\n  .video-container {\n    display: none;\n  }\n  .sections-container {\n    display: none;\n  }\n} */", "",{"version":3,"sources":["webpack://src/components/pages/CourseDetails/CourseDetails.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,UAAU;AACZ;;AAEA;EACE;IACE,gBAAgB;IAChB,YAAY;IACZ,UAAU;IACV,UAAU;EACZ;AACF;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,wBAAwB;AAC1B;;AAEA;;;;;;;;;;;;;;;;GAgBG","sourcesContent":[".stickyVideo {\n  z-index: 1;\n  top: 3.5em;\n}\n\n@media (max-width: 768px){\n  .stickyVideo {\n    position: sticky;\n    /* top: 0; */\n    top: 3.5em;\n    z-index: 1;\n  }\n}\n\n.horrizontalVideo{\n  padding: 0;\n}\n\n.fixedSectionsList{\n  /* z-index: 1; */\n  position: fixed;\n  /* padding-right: 1em; */\n}\n\n/* @media (orientation: portrait) {\n  .video-container {\n    display: block;\n  }\n  .sections-container {\n    display: block;\n  }\n}\n\n@media (orientation: landscape) {\n  .video-container {\n    display: none;\n  }\n  .sections-container {\n    display: none;\n  }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stickyVideo": "CourseDetails_stickyVideo__DXlKE",
	"horrizontalVideo": "CourseDetails_horrizontalVideo__1QAEE",
	"fixedSectionsList": "CourseDetails_fixedSectionsList__18FuA"
};
export default ___CSS_LOADER_EXPORT___;
