import React, { Component } from 'react'
import {Container, Card, Button} from 'react-bootstrap'
import StripeService from '../../../services/stripe.services'
import SignUp from '../SignUp/SignUp'
import Modal from '../../shared/Modal'
import { Link } from 'react-router-dom'
import CustomCheckoutButton from './CustomCheckoutButton'
// import DefaultCheckoutButton from './DefaultCheckoutButton'
import { setLocalCustomerEmail } from './../../../utils/localStorageUtils.js';
import './CartTotal.css'


class CartTotal extends Component {

    constructor(){
        super()
        this.state = {
            cart: [],
            isShownSignUpModal: false, 
        }
        this.stripeService = new StripeService()
    }

    loadCart = () => {        
        this.setState({
            cart: this.props.cart,
        })
        const customer_email = this.props.loggedUser?.email ? this.props.loggedUser.email : ''
        localStorage.setItem('customer_email', customer_email);
    }

    componentDidMount = () => {
        this.loadCart()
    }
    

    toggleShowSignUpModal = () => {
        this.setState({
            isShownSignUpModal: !this.state.isShownSignUpModal
        })
    }

    checkCustomerEmail = () => {
        if( !localStorage.getItem('customer_email') ){   
            this.setState({
                isShownSignUpModal: !localStorage.getItem('customer_email')
            })
        }
    }

    
    render(){

        const { cartItems, itemCount, total, removeAllItems, handleAlert} = this.props
        // const { loggedUser, storeUser, cartItems, itemCount, total, removeAllItems, handleAlert} = this.props

        // const stripe = useStripe();

        return(
            <Container className='mt-4'>
                <Card>
                    <Card.Body>
                        <Card.Title>Coșul tău conține {itemCount} cursuri.</Card.Title>
                        <Card.Text>Total: {total} RON</Card.Text>
                        <div className="d-flex justify-content-between align-items-center">

{/* THE DefaultCheckoutButton WILL BE REMOVED FROM PRODUCTION */}
                            {/* <DefaultCheckoutButton
                                customer_email = { localStorage.getItem('customer_email') }
                                setLocalCustomerEmail = {setLocalCustomerEmail} 
                                // customer_email = { this.state.customer_email }
                                cartItems = { cartItems }
                                checkCustomerEmail = { this.checkCustomerEmail }
                                removeAllItems = { removeAllItems }/> */}

                        {/* <Button 
                            variant="dark" 
                            onClick={() => this.handleCheckout()}>
                            CHECKOUT
                        </Button> */}

                        <CustomCheckoutButton
                                customer_email = { localStorage.getItem('customer_email') }
                                setLocalCustomerEmail = {setLocalCustomerEmail} 
                                // customer_email = { this.state.customer_email }
                                cart = { this.state.cart}
                                cartItems = { cartItems }
                                cartTotal = { total }
                                checkCustomerEmail = { this.checkCustomerEmail }
                                handleAlert = { handleAlert }
                                removeAllItems = { removeAllItems }
                                />
                        
                        {/* <Link className="nav-link" to={`/custom-checkout`} >
                            <Button 
                                variant="dark" >
                                CUSTOM CHECKOUT
                            </Button>
                        </Link> */}

                        {/* <Button variant="light" onClick={() => clearCart()}> */}
                        <Button variant="danger"  onClick={() => removeAllItems()}>
                            Golește coșul
                        </Button>
                        </div>
                    </Card.Body>
                </Card>
                {this.state.isShownSignUpModal &&
                // {!loggedUser && this.state.isShownSignUpModal &&
                <Modal>
                    <p>Pentru a te înscrie la un curs trebuie să ai un cont MateCuMatei. Dacă ai un cont te poti autentifica 
                        <Link className="CartTotal_inlineLink" to="/login">
                            <b style={{borderBottom: '1px solid'}}> aici.</b>
                        </Link>
                     Dacă nu ai un cont, te poți alătura comunității MateCuMatei completând formularul de mai jos. </p>
                    <SignUp 
                        toggleShowSignUpModal = {this.toggleShowSignUpModal}
                        setLocalCustomerEmail = {setLocalCustomerEmail} 
                        handleAlert={handleAlert} 
                        isModal={this.state.isShownSignUpModal} columns={2}/>
                    {/* <Button variant="danger"  onClick={() => this.toggleShowSignUpModal()}>
                        Abandonează
                    </Button> */}
                </Modal> }
            </Container>
        )
    }
}

export default CartTotal