import React from 'react';

const FreeCourses = () => {



    return (
        <>
            <h3 className="text-center mb-4">Fă-ți cont pentru a accesa gratuit cursurile:</h3>

            <h5>Polinoame pentru toți</h5> 

            <h5>Totul despre Sisteme de ecuații liniare</h5> 

            <h5>Limite de funcții - Metoda ușoară</h5> 

            <h5>Curs de trigonometrie pentru clasa a IX-a</h5>

            <h5>Tabăra de mate - Algebră.08</h5> 

            <h5>Evaluare Națională 2022 – Geometrie in plan</h5> 
        </>
        )
    }
    
export default FreeCourses