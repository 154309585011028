// import React from "react";
import './Hero.css'

const Hero = ({title, subtitle}) => {

    return(
        <div className="hero-title-container">
            { title ? <h1>{title}</h1> : null }
            { subtitle ? <h4>{subtitle}</h4> : null }
        </div>
    )
}

export default Hero