import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { useHistory, Link } from 'react-router-dom'
import './Navigation.css'
// The MateCuMatei logo || holliday logo
// import logo from '../../shared/Halloween/santa/f-christmas-snow-globe-svgrepo-com.svg'
// import logo from '../../shared/Halloween/drapele/drapel_05.png'
// import logo from '../../shared/Halloween/santa/f-christmas-tree-illustrations-8-svgrepo-com.svg'
// import logo from '../../shared/Halloween/newYear/logo-firework-01-svgrepo-com.svg' //--- New Year's
// import logo from './../Logo/MateCuMatei_svg_logo_white.svg'
// import AnimatedSvgImage from '../../shared/Halloween/AnimatedSvgImage.jsx'
import '../../shared/Halloween/Halloween.css'
import CartIcon from '../../pages/Cart/CartIcon'
import MaintenanceAlert from '../../pages/Alerts/MaintenanceAlert.jsx'
import { removeLocalCustomerEmail } from './../../../utils/localStorageUtils.js';

import AuthService from '../../../services/auth.services'

const Navigation = ({ storeUser, loggedUser, handleAlert, itemsCount, fetchUser, showMaintenanceAlert, toggleMaintenanceAlert}) => {

    const authService = new AuthService()
    const history = useHistory()
    // console.log(history)

    const handleSignUp = () => {
        history.push('/signup');
    };

    const handleLogIn = () => {
        history.push('/login');
    };

    const handleMenuClick = () => {
        fetchUser()
        const navbarToggler = document.querySelector('.navbar-toggler');
        const navbarCollapse = document.querySelector('.navbar-collapse');

        if (navbarToggler && navbarCollapse) {
            // navbarToggler.classList.toggle('collapsed');
            // navbarCollapse.classList.toggle('show');
            navbarToggler.classList.add('collapsed');
            navbarCollapse.classList.remove('show');
        }
    };

    const logout = () => {
        authService
            .logout()
            .then((res) => {
                storeUser(undefined)
                removeLocalCustomerEmail()
                history.push('/courses')
                localStorage.removeItem('userSpecialization')
                // storeUser(undefined)
                handleAlert(res.data.message, 5000, 'info')
                // storeUser(undefined)
            })
            .catch(err => {
                handleAlert(err.response.data.message, 3000, 'danger')
                // storeUser(undefined)
                // history.push('/courses')
            })
            handleMenuClick()
    }


    return (
        // ADDITIONAL_INFO
        // as putea adauga zIndex:10 --> ca sa nu mai apara video peste Navbar
        <Navbar bg="dark" variant="dark" expand="md" className='navbar-position-fixed'>
        {/* <Navbar bg="dark" variant="dark" expand="md" style={{ marginBottom: '0px', position: 'fixed', top: 0, width:'100%', zIndex:10 }}> */}
            <Link className="nav-link" to="/">
                {/* <AnimatedSvgImage image={logo} cssAndAnimation={'matecumatei-navigation-logo'} opacityParam={'1'}/> */}
                {/* <AnimatedSvgImage image={logo} cssAndAnimation={'christmas-logo'} opacityParam={'1'}/> */}
                <Navbar.Brand style={{color: 'rgba(255, 255, 255, 0.55)'}} onClick={handleMenuClick}>MateCuMatei</Navbar.Brand >
            </Link>        

        <MaintenanceAlert 
          showMaintenanceAlert={showMaintenanceAlert}
          toggleMaintenanceAlert={toggleMaintenanceAlert}
          parentComponent={`Navigation`}
        />

            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse className="justify-content-end">
                <Nav className="mr-auto">
                    {/* <Link className="nav-link" to="/">Home</Link> */}
                    <Link className="nav-link" to="/courses" onClick={handleMenuClick}>Cursuri{/*Courses*/}</Link>

                    {/* <NavDropdown title="Examene Nationale" id="exams-dropdown" align="end" className="d-none d-md-block"> */}
                    <NavDropdown title="Examene Nationale" id="exams-dropdown" align="end">
                        <NavDropdown.Item onClick={() => {history.push('/exams/bacalaureat')}}>Bac</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={() => {history.push('/exams/evaluare-nationala')}}>Evaluare Națională</NavDropdown.Item>
                    </NavDropdown>

                    <Link className="nav-link" to="/blog" onClick={handleMenuClick}>Blog{/*Courses*/}</Link>

                    {loggedUser && ( loggedUser.role === 'admin' || loggedUser.role === 'author' )
                    ?
                    <NavDropdown title="Instructors" id="instructors-dropdown" align="end">
                        <NavDropdown.Item onClick={() => {history.push('/courses/new')}}>Curs nou</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={() => {history.push('/coupons/')}}>Cupoane</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={() => {history.push('/coupons/new')}}>Cupon nou</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={() => {history.push('/blog/new')}}>Postare nouă</NavDropdown.Item>
                    </NavDropdown>
                    :
                    null                    
                    }

                    {/* {loggedUser && ( loggedUser.role === 'admin' || loggedUser.role === 'author' )
                    ?
                        <Link className="nav-link" to="/courses/new" onClick={handleMenuClick}>Curs Nou</Link>
                    :
                    null                    
                    } */}
                
                    {!loggedUser
                        ?
                        <>
                        <NavDropdown title="Contul meu" id="basic-nav-dropdown" align="end" className="d-none d-md-block">
                            <NavDropdown.Item onClick={handleLogIn}>Log In</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item onClick={handleSignUp}>Cont nou</NavDropdown.Item>
                        </NavDropdown>
                        <Link className="nav-link d-md-none" to="/login" onClick={handleMenuClick}>Log In{/*Accesează cont*/}</Link>
                        <Link className="nav-link d-md-none" to="/signup" onClick={handleMenuClick}>Cont nou{/*Creează cont*/}</Link>
                        </>
                        :
                        <>
                            <span className="nav-link" onClick = { () => logout()}>LogOut</span>
                            <Link className="nav-link" to={`/users/${loggedUser._id}`}  onClick={handleMenuClick}>Profilul Meu</Link>
                            <span className="nav-link" >Salutare{loggedUser ? ', '+loggedUser.username?.trim().split(' ')[0] : ''}!</span>
                        </>
                    }
                    {/* CART / TEMP*/}
                    {
                    (
                        // ( process.env.REACT_APP_IS_CART_VISIBLE !== false && process.env.REACT_APP_IS_CART_VISIBLE !== 'false' )
                        process.env.REACT_APP_IS_CART_VISIBLE==="true"
                        ||
                        loggedUser?.role === 'admin'
                    )  
                    &&
                    <Link className="nav-link" to="/cart"><CartIcon itemsCount={itemsCount}/></Link>
                    }
                </Nav>
            </Navbar.Collapse>
        </Navbar >
    )
}

export default Navigation