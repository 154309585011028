import axios from 'axios' 
 
 class QuestionsAndAnswersService{

    constructor(){
        this.app = axios.create({
            baseURL: process.env.REACT_APP_BASE_URL + '/questions',
            // baseURL: 'https://matecumatei-server.herokuapp.com/api/courses',
            // baseURL: 'http://localhost:5000/api/courses',
            withCredentials: true
        })
    }

    getAllQuestions = () => this.app.get('/')
    getQuestionsByLecture = (currentLecture) => this.app.get(`/questionsByLecture/${currentLecture}`)
    // getQuestionsByLecture = (currentLecture) => this.app.get(`/questionsByLecture?currentLecture=${currentLecture}`)
    getQuestion = (question_id) => this.app.get(`/details/${question_id}`)
    newQuestion = (lectureId, title, message, loggedUserId, token) => this.app.post('/new', {lectureId, title, message, loggedUserId, token})
    // newQuestion = (question_info) => this.app.post('/new', question_info)
    likeQuestion = (question_id, loggedUser) => this.app.post('/like', {question_id, loggedUser})
    deleteQuestion = (question_id) => this.app.delete(`/delete/${question_id}`)

    getAllAnswers = (question_id) => this.app.get(`/answers?questionId=${question_id}`)
    newAnswer = (questionId, message, loggedUserId, token) => this.app.post('/answers/new', {questionId, message, loggedUserId, token})
    likeAnswer = (answer_id, loggedUser) => this.app.post('/answers/like', {answer_id, loggedUser})
    getAnswer = (answer_id) => this.app.get(`/answers/details/${answer_id}`)
    deleteAnswer = (answer_id) => this.app.delete(`/answers/delete/${answer_id}`)

 }

 export default QuestionsAndAnswersService