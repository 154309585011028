import { Component } from 'react'
import { Container, Form, Button, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './LogIn.css'
import { setLocalCustomerEmail } from './../../../utils/localStorageUtils.js';
import { getCustomizedErrorMessagesArray } from './../../../utils/utilitaryFunctions'

import AuthService from '../../../services/auth.services'
import executeRecaptcha from '../../../utils/executeRecaptchaScript.js';
import {removeRecaptchaScript, removeRecaptchaBadge} from '../../../utils/removeRecaptchaScriptAndBadge.js';


class LogIn extends Component {

    constructor() {
        super()
        this.state = {
            email: '',
            pwd: '',
            // submitEnabled: false,
            submitEnabled: true,
            recaptchaToken: null,
        }
        this.authService = new AuthService()
    }

    // async componentDidMount() {
    //     const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_INVISIBLE_V3_SITE_KEY;
    //     const action = 'login';
    //         await executeRecaptcha(siteKey, action, (enabled, token) => {
    //             // console.log('Token:', token); // Ensure token is received here
    //             this.setState({
    //                 submitEnabled: enabled,
    //                 recaptchaToken: token // Store the token in the state
    //             });
    //         });
    // }
    


    handleInputChange = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }


    handleFormSubmit = e => {

        e.preventDefault()

        const { email, pwd, recaptchaToken } = this.state

        this.authService
            .login(email, pwd, recaptchaToken, 'v3')
            .then(loggedUserfromServer => {
                this.props.storeUser(loggedUserfromServer.data)
                setLocalCustomerEmail( loggedUserfromServer.data.email )
                this.props.history.push('/courses')         // Redirect with RRD props
            })
            .catch(err => {
                console.log(err.response?.data)
                console.log(JSON.stringify(err))
                this.props.handleAlert( getCustomizedErrorMessagesArray(err), 10000, 'warning', true)
            })
    }

    componentWillUnmount() {
        // Remove reCAPTCHA script and Badge when component is unmounted
        removeRecaptchaScript();
        removeRecaptchaBadge()
    }


    render() {
        return (

            <Container>

                <Row>

                    <Col md={{ span: 4, offset: 4 }}>

                        <h1>Autentificare</h1>
                        {/* <h1>Log In</h1> */}

                        <hr></hr>

                        <Form onSubmit={this.handleFormSubmit}>

                            <Form.Group controlId="email">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text" value={this.state.email} onChange={this.handleInputChange} name="email" />
                            </Form.Group>

                            <Form.Group controlId="pwd">
                                <Form.Label>Parolă{/*Password*/}</Form.Label>
                                <Form.Control type="password" value={this.state.pwd} onChange={this.handleInputChange} name="pwd" />
                            </Form.Group>

                            <Button disabled={!this.state.submitEnabled} style={{ marginTop: '20px', width: '100%' }} variant="dark" type="submit">Intră în cont</Button>
                            {/* <Button style={{ marginTop: '20px', width: '100%' }} variant="dark" type="submit">Log In</Button> */}

                        </Form>

                        {/* <hr></hr> */}

                        <Link to="/recover-password" className='LogIn_forgotPasswordLink'>sau <b style={{borderBottom: '1px solid'}}>Am uitat parola</b></Link>

                    </Col>
                </Row>

            </Container >

        )
    }
}


export default LogIn